import {Icon3DStyle} from "@luciad/ria/view/style/Icon3DStyle";
import {Feature} from "@luciad/ria/model/feature/Feature";

export interface ThreeDObjectTypes {
    name: string,
    imagePreview: string,
    threeDModel: string,
    scale?: number
}

export const ThreeDObjectTypes: ThreeDObjectTypes[] = [
    {name: "Tractor", imagePreview: "tractor.png", threeDModel: "tractor.glb", scale: 5},
    {name: "Persona", imagePreview: "character_barbarian.png", threeDModel: "character_barbarian.glb"},
    {name: "Pared", imagePreview: "wall.png", threeDModel: "wall.gltf.glb"},
]

export function getImagePreview( name: string ): string {
    return "/resources_ria/3dobjects/preview/" + ThreeDObjectTypes.find( typeProps => typeProps.name === name )?.imagePreview!;
}

export function get3DIconStyle( feature: Feature, selected?: boolean ): Icon3DStyle {
    const {name, heading} = feature.properties;
    const file = ThreeDObjectTypes.find( typeProps => typeProps.name === name )?.threeDModel!;
    const scale = ThreeDObjectTypes.find( typeProps => typeProps.name === name )?.scale ?? 1;
    return {
        meshUrl: "/resources_ria/3dobjects/model/" + file,
        rotation: {
            x: 90,
            y: 0,
            z: 0
        },
        orientation: {heading: heading},
        scale: {x: scale, y: scale, z: scale},
        color: selected ? "rgba(255,165,0,0.25)" : "rgba(255,255,255,1.0)"
    }
}


