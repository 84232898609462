import {Map} from '@luciad/ria/view/Map';
import React, {ComponentProps} from 'react';
import {
    Drawer,
    DrawerActions,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerTitle,
    Stack,
    useTheme,
} from '@digitalreality/ui';
import {useRerenderOnLayerTreeEvent} from '../common/hooks/useRerenderOnLayerTreeEvent';
import {LayerTreeNodeControl} from './LayerTreeNodeControl';
import {LayersIcon} from '@digitalreality/ui-icons';
import {isHidden} from "./LayerOptions";

interface Props extends ComponentProps<typeof Drawer> {
  map: Map;
}

/**
 * Component that shows all layer(group)s on the map and allows users
 * to toggle the layer's visibility and to move them.
 */
export const LayerTreeDrawer = ({
  map,
  open,
  onClose,
  ...rest
}: Props) => {
  const theme = useTheme();

  useRerenderOnLayerTreeEvent(map.layerTree, [
    'NodeAdded',
    'NodeMoved',
    'NodeRemoved',
  ]);

  return (
    <>
      <Drawer variant="persistent" open={open} {...rest}>
        <DrawerContent pl={theme.sizes.navbar} width={380}>
          <DrawerHeader>
            <DrawerTitle icon={<LayersIcon />}>Capas</DrawerTitle>
            <DrawerActions>
              <DrawerCloseButton
                onClick={(e) => onClose?.(e, 'backdropClick')}
              />
            </DrawerActions>
          </DrawerHeader>
          <DrawerBody marginLeft="8px" marginTop="8px">
            <Stack flex="1" width="100%">
              {map.layerTree.children.reverse()
                  .filter( child => !(isHidden( child )) )
                  .map((child) => (
                <LayerTreeNodeControl key={child.id} map={map} node={child} />
              ))}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
