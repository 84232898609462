import {
    Drawer,
    DrawerActions,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    Inline,
    Stack,
    useTheme
} from "@digitalreality/ui";
import {TextField} from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React, {KeyboardEvent, ReactElement, useState} from "react";
import "./CreateItemsDrawer.css";

/**
 * I -> Being the item displayed in the drawer.
 * R -> Being the raw the of the item provided by the user.
 */
interface CreateDeleteItemsDrawerProps<I, R> {
    title: string,
    headerIcon?: ReactElement,
    open: boolean,
    onClose: () => void,
    handleDrop: ( e: React.DragEvent<HTMLDivElement> ) => void,
    error: Error | undefined,
    errorDialogSupplier: ( e: Error ) => ReactElement,
    items: I[] | undefined,
    itemToCreate: R | undefined,
    formToCreateSupplier: ( item: R ) => ReactElement,
    processingItem: boolean,
    creatingItem: boolean,
    filter: ( filterText: string, item: I ) => boolean,
    itemCardSupplier: ( item: I ) => ReactElement,
    itemToDelete?: I,
    deleteForm?: ( item: I ) => ReactElement,
    footerContent?: ReactElement
}

export function CreateItemsDrawer<I, R>( {
                                             title,
                                             headerIcon,
                                             open,
                                             onClose,
                                             handleDrop,
                                             error,
                                             errorDialogSupplier,
                                             items,
                                             itemToCreate,
                                             formToCreateSupplier,
                                             processingItem,
                                             creatingItem,
                                             filter,
                                             itemCardSupplier,
                                             itemToDelete,
                                             deleteForm,
                                             footerContent
                                         }: CreateDeleteItemsDrawerProps<I, R> ) {
    const theme = useTheme();

    const [dragging, setDragging] = useState<boolean>( false );
    const [filterText, setFilterText] = useState<string | undefined>( "" );

    const handleDrag = ( e: React.DragEvent<HTMLDivElement> ) => {
        setDragging( true )
        e.preventDefault()
    }

    const cancelDrag = ( e: React.DragEvent<HTMLDivElement> ) => {
        setDragging( false )
        e.preventDefault()
    }

    const handleItemDrop = ( e: React.DragEvent<HTMLDivElement> ) => {
        setDragging( false )
        e.preventDefault()
        e.stopPropagation()

        handleDrop( e );
    }

    function consumeEvent( event: KeyboardEvent<HTMLInputElement> ) {
        event.stopPropagation();
    }

    return <Drawer variant="persistent" open={open} onClose={onClose}
                   onDragEnter={handleDrag} onDragOver={handleDrag} onDragLeave={cancelDrag} onDrop={handleItemDrop}>
        {error && errorDialogSupplier( error )}
        {itemToCreate && formToCreateSupplier( itemToCreate )}
        {itemToDelete && deleteForm && deleteForm( itemToDelete )}
        {dragging && !itemToCreate && <div className={"dragging"}/>}
        <DrawerContent pl={theme.sizes.navbar} width={380}>
            <DrawerHeader>
                <Inline>
                    {headerIcon}
                    <div onKeyUp={consumeEvent} onKeyDown={consumeEvent}>
                        <TextField label={title} variant="standard" value={filterText}
                                   onChange={event => setFilterText( event.target.value )}
                                   sx={{bottom: "17px", left: "8px", height: "32px", width: "150px"}}/>
                    </div>
                    {!items && <LoopIcon sx={{marginLeft: "38px"}} className={"blink"}/>}
                    {processingItem && <ModelTrainingIcon sx={{marginLeft: "38px"}} className={"blink"}/>}
                    {creatingItem && <CloudUploadIcon sx={{marginLeft: "38px"}} className={"blink"}/>}
                </Inline>
                <DrawerActions>
                    <DrawerCloseButton tooltipLabel={"Cerrar panel"} onClick={onClose}/>
                </DrawerActions>
            </DrawerHeader>
            <DrawerBody marginLeft="8px" marginTop="8px" marginRight="8px" overflow={"hidden"}>
                <Stack gap="8px" width="calc(100% - 20px)">
                    {items && items.filter( item => {
                        if ( !filterText || filterText.length === 0 ) {
                            return true;
                        }
                        return filter( filterText, item );
                    } ).map( item => itemCardSupplier( item ) )}
                </Stack>
            </DrawerBody>
            {footerContent &&
             <DrawerFooter>
                 {footerContent}
             </DrawerFooter>}
        </DrawerContent>
    </Drawer>
}