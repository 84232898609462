import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

export interface AlertDialogProps {
    title: string,
    message: string,
    onClose: () => void
}

export function AlertDialog( {title, message, onClose}: AlertDialogProps ) {

    return (
        <div>
            <Dialog open={true} onClose={onClose} aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>{message}</DialogContent>
                <DialogActions>
                    <Button onClick={onClose} autoFocus>Aceptar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}