import {WebGLMap} from "@luciad/ria/view/WebGLMap";
import {
    Divider,
    Navbar,
    NavbarBottomContent,
    NavbarButton,
    NavbarMiddleContent,
    NavbarTopContent,
    Stack
} from "@digitalreality/ui";
import {
    CrossSectionIcon,
    LayersIcon,
    PlayOutlineIcon,
    RulerAltIcon,
    SettingsIcon,
    SlicingIcon
} from "@digitalreality/ui-icons";
import React, {useEffect, useState} from "react";
import {MeasurementDrawer} from "../measurements/MeasurementDrawer";
import {LayerTreeDrawer} from "../layers/LayerTreeDrawer";
import {MeasurementSupport} from "../measurements/MeasurementSupport";
import {VisibilityBoxSupport} from "../common/slicing/VisibilityBoxSupport";
import {ThreeDObjectsDrawer} from "../3dobjects/ui/ThreeDObjectsDrawer";
import {useControllerContext} from "../navigation/ControllerContext";
import {ObliqueDrawer} from "../oblique/ObliqueDrawer";
import {SimulationDrawer} from "../simulation/SimulationDrawer";
import {AddLocationAltOutlined, WaterOutlined} from "@mui/icons-material";
import {SettingsDrawer} from "../settings/SettingsDrawer";
import {CrossSectionDrawer} from "../crosssection/CrossSectionDrawer";
import {UpwellingCampaignDrawer} from "../underground/upwelling_campaigns/ui/UpwellingCampaignDrawer";
import {AuthenticateDrawer} from "../util/AuthenticateDrawer";

enum Layout {
    NONE,
    LAYERS,
    MEASUREMENTS,
    THREE_D_OBJECTS,
    OBLIQUE,
    SIMULATION,
    UPWELLINGS,
    CROSS_SECTION,
    SLICING,
    SETTINGS,
}

export function Toolbar( props: { map: WebGLMap | undefined } ) {

    const SERVER_ENDPOINT = "https://campo-cartagena-gemelo-digital-luciadfusion.es";

    const [activeSidePanel, setActiveSidePanel] = useState<Layout>( Layout.NONE );
    const [measurementSupport, setMeasurementSupport] = useState<MeasurementSupport | null>( null );
    const [slicingSupport, setSlicingSupport] = useState<VisibilityBoxSupport | null>( null );
    const [, setControllerContext] = useControllerContext();

    useEffect( () => {
        if ( !props.map ) {
            return;
        }

        setMeasurementSupport( new MeasurementSupport( props.map, setControllerContext ) );
        const sliceSupport = new VisibilityBoxSupport( props.map );
        setSlicingSupport( sliceSupport );
        return () => {
            if ( sliceSupport ) {
                sliceSupport.destroy();
            }
        };
    }, [props.map] );

    function changeActiveSidePanel( layout: Layout ) {
        if ( activeSidePanel === layout ) {
            setActiveSidePanel( Layout.NONE );
        }
        else if ( props.map ) {
            setActiveSidePanel( layout );
        }
    }

    const closePanel = () => setActiveSidePanel( Layout.NONE );

    return (
        <>
            <Navbar>
                <NavbarTopContent>
                    <NavbarButton
                        title="Árbol de capas"
                        active={activeSidePanel === Layout.LAYERS}
                        onClick={() => changeActiveSidePanel( Layout.LAYERS )}>
                        <LayersIcon/>
                    </NavbarButton>
                </NavbarTopContent>
                <Divider variant="middle"/>
                <NavbarMiddleContent>
                    <Stack gap={0} sx={{}}>
                        <NavbarButton
                            title="Mediciones"
                            active={activeSidePanel === Layout.MEASUREMENTS}
                            onClick={() => changeActiveSidePanel( Layout.MEASUREMENTS )}>
                            <RulerAltIcon/>
                        </NavbarButton>
                        <NavbarButton
                            title="Objetos 3D"
                            active={activeSidePanel === Layout.THREE_D_OBJECTS}
                            onClick={() => changeActiveSidePanel( Layout.THREE_D_OBJECTS )}>
                            <AddLocationAltOutlined/>
                        </NavbarButton>
                        <NavbarButton
                            title="Imágenes oblicuas"
                            active={activeSidePanel === Layout.OBLIQUE}
                            onClick={() => changeActiveSidePanel( Layout.OBLIQUE )}>
                            <img src={"resources_ria/icons/oblique.png"} alt={"Imágenes oblicuas"} width={32}/>
                        </NavbarButton>
                        <NavbarButton
                            title="Simulaciones"
                            active={activeSidePanel === Layout.SIMULATION}
                            onClick={() => changeActiveSidePanel( Layout.SIMULATION )}>
                            <PlayOutlineIcon/>
                        </NavbarButton>
                        <NavbarButton
                            title="Surgencias de agua"
                            active={activeSidePanel === Layout.UPWELLINGS}
                            onClick={() => changeActiveSidePanel( Layout.UPWELLINGS )}>
                            <WaterOutlined/>
                        </NavbarButton>
                        <NavbarButton
                            title="Corte vertical"
                            active={activeSidePanel === Layout.CROSS_SECTION}
                            onClick={() => changeActiveSidePanel( Layout.CROSS_SECTION )}>
                            <CrossSectionIcon/>
                        </NavbarButton>
                        {/*Disabled for commercial reasons*/}
                        {false && <NavbarButton
                            title="Slicing"
                            active={activeSidePanel === Layout.SLICING}
                            onClick={() => changeActiveSidePanel( Layout.SLICING )}>
                          <SlicingIcon/>
                        </NavbarButton>}
                    </Stack>
                </NavbarMiddleContent>
                <NavbarBottomContent>
                    <NavbarButton
                        title="Ajustes"
                        active={activeSidePanel === Layout.SETTINGS}
                        onClick={() => changeActiveSidePanel( Layout.SETTINGS )}>
                        <SettingsIcon/>
                    </NavbarButton>
                </NavbarBottomContent>
            </Navbar>
            <div style={{zIndex: 100}}>
                {props.map &&
                 <LayerTreeDrawer
                     open={activeSidePanel === Layout.LAYERS}
                     map={props.map}
                     onClose={closePanel}/>
                }
                {props.map && measurementSupport &&
                 <MeasurementDrawer
                     open={activeSidePanel === Layout.MEASUREMENTS}
                     onClose={closePanel}
                     map={props.map}
                     support={measurementSupport}/>
                }
                {props.map &&
                 <ThreeDObjectsDrawer
                     open={activeSidePanel === Layout.THREE_D_OBJECTS}
                     map={props.map}
                     onClose={closePanel}
                 />
                }
                {props.map &&
                 <ObliqueDrawer
                     open={activeSidePanel === Layout.OBLIQUE}
                     map={props.map}
                     onClose={closePanel}
                 />
                }
                {props.map &&
                 <AuthenticateDrawer title={"Simulaciones"}
                                     icon={<PlayOutlineIcon sx={{color: "#B5B5B5"}}/>}
                                     open={activeSidePanel === Layout.SIMULATION}
                                     onClose={closePanel}
                                     isValidPassword={password => {
                                         const formData = new FormData()
                                         formData.append( "password", password );
                                         return fetch( SERVER_ENDPOINT + "/simulation/all", {
                                             method: 'POST',
                                             body: formData,
                                         } ).then( response => response.ok );
                                     }}
                                     sensitiveComponentSupplier={password =>
                                         <SimulationDrawer
                                             open={activeSidePanel === Layout.SIMULATION}
                                             map={props.map!}
                                             onClose={closePanel}
                                             password={password}/>
                                     }
                 />
                }
                {props.map &&
                 <AuthenticateDrawer title={"Surgencias de agua"}
                                     icon={<WaterOutlined sx={{color: "#B5B5B5"}}/>}
                                     open={activeSidePanel === Layout.UPWELLINGS}
                                     onClose={closePanel}
                                     isValidPassword={password => {
                                         const formData = new FormData()
                                         formData.append( "password", password );
                                         return fetch( SERVER_ENDPOINT + "/upwelling/campaigns", {
                                             method: 'POST',
                                             body: formData,
                                         } ).then( response => response.ok );
                                     }}
                                     sensitiveComponentSupplier={password =>
                                         <UpwellingCampaignDrawer open={activeSidePanel === Layout.UPWELLINGS}
                                                                  map={props.map!}
                                                                  onClose={closePanel}
                                                                  password={password}
                                         />
                                     }
                 />
                }
                {props.map &&
                 <CrossSectionDrawer
                     open={activeSidePanel === Layout.CROSS_SECTION}
                     map={props.map}
                     onClose={closePanel}/>
                }
                {/*Disabled for commercial reasons*/}
                {/*{props.map && slicingSupport &&*/}
                {/* <SlicingDrawer*/}
                {/*     open={activeSidePanel === Layout.SLICING}*/}
                {/*     onClose={closePanel}*/}
                {/*     map={props.map}*/}
                {/*     support={slicingSupport}/>*/}
                {/*}*/}

                {props.map && <SettingsDrawer
                    open={activeSidePanel === Layout.SETTINGS}
                    map={props.map}
                    onClose={closePanel}
                />}
            </div>
        </>
    )
}
