import {Map} from '@luciad/ria/view/Map';
import React, {useEffect, useRef, useState} from 'react';
import {Absolute, Box, CircularProgress, IconButton, Inline, Slider, Stack, Text, Tooltip,} from '@digitalreality/ui';
import {
  BUSY_CHANGE_EVENT,
  CARTESIAN_MAP_CREATED_EVENT,
  CrossSectionView,
} from '../common/crosssection/CrossSectionView';
import {Handle} from '@luciad/ria/util/Evented';
import {MAX_CARTESIAN_MAP_SCALE, MIN_CARTESIAN_MAP_SCALE,} from '../common/crosssection/util/CrossSectionMapUtil';
import {ScaleIndicator} from '../navigation/ScaleIndicator';
import {CrossSectionController, PLANE_PLACED_CHANGED_EVENT,} from '../common/crosssection/CrossSectionController';
import {Measurement, MEASUREMENT_CHANGED_EVENT,} from '../common/ruler3d/measurement/Measurement';
import {LocateIcon, ResetIcon} from '@digitalreality/ui-icons';
import {DeleteOutlined} from "@mui/icons-material";

interface Props {
  mainMap: Map;
}

function formatMapScaleForSlider(mapScale: number) {
  return Math.log2(mapScale / MIN_CARTESIAN_MAP_SCALE);
}

function formatSliderValueToMapScale(value: number) {
  return MIN_CARTESIAN_MAP_SCALE * Math.pow(2, value);
}

/**
 * Component that starts the cross-section interaction when rendered and displays the cross-section in an interactive
 * overlay.
 */
export const CrossSectionOverlay = ({mainMap}: Props) => {
  const [view, setView] = useState<CrossSectionView>();
  const [isBusy, setBusy] = useState<boolean>(false);
  const [mapScale, setMapScale] = useState<number>(MIN_CARTESIAN_MAP_SCALE);
  const [planePlaced, setPlanePlaced] = useState<boolean>(false);
  const [hasMeasurement, setHasMeasurement] = useState<boolean>(false);
  const sliceMapNodeRef = useRef<HTMLDivElement>(null);
  const cartesianMapNodeRef = useRef<HTMLDivElement>(null);
  const cartesianMapRef = useRef<Map>();
  const controllerRef = useRef<CrossSectionController | null>(null);

  useEffect(() => {
    if (!sliceMapNodeRef.current || !cartesianMapNodeRef.current) {
      throw new Error(
        'Unexpected error: Could not create the cross section view.'
      );
    }

    const handles = [] as Handle[];

    const newView = new CrossSectionView(
      mainMap,
      sliceMapNodeRef.current,
      cartesianMapNodeRef.current,
      mainMap.layerTree
    );
    handles.push(newView.on(BUSY_CHANGE_EVENT, setBusy));
    handles.push(
      newView.on(CARTESIAN_MAP_CREATED_EVENT, (map: Map) => {
        cartesianMapRef.current = map;
        handles.push(map.on('MapChange', () => setMapScale(map.mapScale[0])));
        setView(newView);
      })
    );
    handles.push(
      newView.on(MEASUREMENT_CHANGED_EVENT, (measurement: Measurement) =>
        setHasMeasurement(!!measurement)
      )
    );

    return () => {
      newView.destroy();
      for (const handle of handles) {
        handle.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (view) {
      const controller = new CrossSectionController(mainMap, view);
      mainMap.controller = controller;
      controllerRef.current = controller;
      const handle = controller.on(PLANE_PLACED_CHANGED_EVENT, setPlanePlaced);
      return () => {
        if (controller.map !== mainMap) {
          mainMap.controller = null;
        }
        handle.remove();
      };
    } else {
      if (controllerRef.current?.map === mainMap) {
        mainMap.controller = null;
      }
      controllerRef.current = null;
    }
  }, [view]);

  useEffect(() => {
    return () => {
      if (controllerRef.current?.map === mainMap) {
        mainMap.controller = null;
      }
      controllerRef.current = null;
    };
  }, []);

  function replacePlane() {
    controllerRef.current?.replaceSlicePlane();
  }

  function fitOnPlane() {
    if (view?.plane.plane) {
      mainMap.mapNavigator.fit({
        bounds: view.plane.plane.bounds,
        animate: true,
      });
    }
  }

  function resetMeasurement() {
    if (view) {
      view.clearMeasurements();
    }
  }

  function updateMapScale(mapScale: number) {
    cartesianMapRef.current?.mapNavigator.zoom({
      animate: false,
      targetScale: {x: mapScale, y: mapScale},
    });
  }

  return (
    <Absolute
      width="50%"
      height="35%"
      bottom="37px"
      left="50%"
      sx={{
        transform: 'translate(-50%, 0)',
        pointerEvents: 'auto',
      }}
    >
      <Absolute
        bgcolor="grey.800"
        width="100%"
        height="100%"
        sx={{
          opacity: 0.8,
        }}
      />
      <Absolute
        ref={sliceMapNodeRef}
        left="8px"
        top="40px"
        width="calc(100% - 16px)"
        height="calc(100% - 48px)"
        zIndex="5"
      />
      <Absolute
        ref={cartesianMapNodeRef}
        left="8px"
        top="40px"
        width="calc(100% - 16px)"
        height="calc(100% - 48px)"
        zIndex="10"
      />
      <Absolute
        right="16px"
        bottom="16px"
        width="20%"
        height="40px"
        zIndex="15"
      >
        <ScaleIndicator mapScale={mapScale} />
      </Absolute>
      {!planePlaced && (
        <Stack
          bgcolor="grey.800"
          left="8px"
          top="40px"
          width="calc(100% - 16px)"
          height="calc(100% - 48px)"
          justifyContent="center"
          alignItems="center"
          zIndex="20"
          sx={{
            position: 'absolute',
          }}
        >
          <Text sx={{userSelect: 'none'}}>
            Haga click en cualquier parte del mapa para iniciar la creación del corte y
            haga click de nuevo para definir la extensión
          </Text>
        </Stack>
      )}
      {planePlaced && <SliceContour />}
      <Inline
        left="50%"
        top="5px"
        alignItems="center"
        sx={{
          position: 'absolute',
          transform: 'translate(-50%,0)',
        }}
      >
        <Tooltip title="Reemplazar el corte" placement="bottom">
          <Box>
            <IconButton
              size="small"
              onClick={replacePlane}
              disabled={!planePlaced}
            >
              <ResetIcon />
            </IconButton>
          </Box>
        </Tooltip>
        <Tooltip title="Centrar en el corte" placement="bottom">
          <Box>
            <IconButton
              size="small"
              onClick={fitOnPlane}
              disabled={!planePlaced}
            >
              <LocateIcon />
            </IconButton>
          </Box>
        </Tooltip>
        <Tooltip title="Borrar la medición" placement="bottom">
          <Box>
            <IconButton
              size="small"
              onClick={resetMeasurement}
              disabled={!hasMeasurement}
            >
              <DeleteOutlined/>
            </IconButton>
          </Box>
        </Tooltip>
      </Inline>
      <Inline
        right="25px"
        top="10px"
        alignItems="center"
        sx={{
          position: 'absolute',
        }}
      >
        <Text>Zoom</Text>
        <Text
          marginLeft="10px"
          fontFamily="monospace" //to ensure that the line is centered vertically
        >
          -
        </Text>
        <Stack marginLeft="5px" marginRight="5px" width="129px">
          <Slider
            size="small"
            value={formatMapScaleForSlider(mapScale)}
            onChange={(e, value) =>
              updateMapScale(formatSliderValueToMapScale(value as number))
            }
            min={0}
            max={formatMapScaleForSlider(MAX_CARTESIAN_MAP_SCALE)}
            step={0.01}
          />
        </Stack>
        <Text>+</Text>
      </Inline>
      {isBusy && (
        <CircularProgress
          size={32}
          sx={{position: 'absolute', left: 1, top: 1}}
        />
      )}
    </Absolute>
  );
};

const SliceContour = () => {
  return (
    <Absolute
      left="8px"
      top="40px"
      width="calc(100% - 16px)"
      height="calc(100% - 48px)"
      zIndex="15"
      border="2px solid white"
      sx={{pointerEvents: 'none'}}
    />
  );
};
