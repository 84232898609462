import {
    Measurement,
    MEASUREMENTS_MODEL_REFERENCE,
    MeasurementSegment,
    MeasurementType,
} from '../common/ruler3d/measurement/Measurement';
import {FeatureModel} from '@luciad/ria/model/feature/FeatureModel';
import {MemoryStore} from '@luciad/ria/model/store/MemoryStore';
import {MeasurementPainter, PAINT_STYLES} from './MeasurementPainter';
import {FeatureLayer} from '@luciad/ria/view/feature/FeatureLayer';
import {createMeasurement} from '../common/ruler3d/measurement/MeasurementUtil';
import {Map} from '@luciad/ria/view/Map';
import {MEASUREMENT_FINISHED_EVENT, Ruler3DController,} from '../common/ruler3d/Ruler3DController';
import {Feature} from '@luciad/ria/model/feature/Feature';
import {EventedSupport} from '@luciad/ria/util/EventedSupport';
import {Handle} from '@luciad/ria/util/Evented';
import {LookFrom} from '@luciad/ria/view/camera/LookFrom';
import {PerspectiveCamera} from '@luciad/ria/view/camera/PerspectiveCamera';
import {AnimationManager} from '@luciad/ria/view/animation/AnimationManager';
import {create3DCameraAnimation} from '../common/controller/animation/Move3DCameraAnimation';
import {
    deleteMeasurement,
    loadAllMeasurements,
    persistMeasurement,
    updateMeasurement,
} from './MeasurementPersistenceUtil';
import {ThreePointOrthogonalProjector} from '../common/ruler3d/ThreePointOrthogonalProjector';
import {ThreePointRaycastedProjector} from '../common/ruler3d/ThreePointRaycastedProjector';
import {MeasurementProjector} from '../common/ruler3d/ThreePointProjector';
import {ControllerUpdate} from "../navigation/ControllerContext";

export const PENDING_MEASUREMENT_CHANGED_EVENT =
    'PendingMeasurementChangedEvent';
export const MEASUREMENT_WRAPPERS_CHANGED_EVENT =
    'MeasurementWrappersChangedEvent';
export const CURRENT_MEASUREMENT_TYPE_CHANGED_EVENT =
    'CurrentMeasurementTypeChangedEvent';

const MEASUREMENT_LAYER_ID = 'MEASUREMENT_LAYER';
const PLANE_MESH_URL = './resources_ria/fadingGrid.glb';

/**
 * Defines what transformation is used to transform your cursor point to a world measurement point.
 */
export enum MeasurementMode {
    /**
     * Default RIA transformation that transforms your cursor point on the closest surface that is encountered when
     * pointing from the camera to the cursor.
     */
    CLOSEST_SURFACE = 'Superficie más cercana',

    /**
     * Transformation that results from these consecutive transformations:
     *  <ol>
     *    <li>The {@link CLOSEST_SURFACE} transformation</li>
     *    <li>The Orthogonal projection onto the plane defined by the measurement controller</li>
     *  </ol>
     */
    PLANE_PROJECTED = 'En el plano',

    /**
     * Transformation defined by the intersection between the ray going from the camera's eye in the cursor's direction
     * and the plane defined by the measurement controller
     */
    PLANE_CAST = 'Proyectado en el plano',
}

/**
 * Object containing a measurement and additional information used for displaying and interacting with the measurement.
 */
export interface MeasurementWrapper<S extends MeasurementSegment = MeasurementSegment> {
    id: string;
    name: string;
    expanded: boolean;
    measurement: Measurement<S>;
    fitPosition: LookFrom;
}

/**
 * Support class that handles creating and editing measurements
 */
export class MeasurementSupport {
    private readonly _map: Map;
    private readonly _controllerUpdate: ControllerUpdate;
    private readonly _eventedSupport: EventedSupport;
    private readonly _measurementModel: FeatureModel;
    private readonly _measurementLayer: FeatureLayer;

    private _currentMeasurementType: MeasurementType | null = null;
    private _measurementWrappers: MeasurementWrapper[] = [];
    private _pendingMeasurement: Measurement | null = null;
    private _pendingMeasurementFitPosition: LookFrom | null = null;

    constructor( map: Map, controllerUpdate: ControllerUpdate ) {
        this._map = map;
        this._controllerUpdate = controllerUpdate;
        this._eventedSupport = new EventedSupport(
            [
                PENDING_MEASUREMENT_CHANGED_EVENT,
                MEASUREMENT_WRAPPERS_CHANGED_EVENT,
                CURRENT_MEASUREMENT_TYPE_CHANGED_EVENT,
            ],
            true
        );

        this._measurementModel = new FeatureModel( new MemoryStore(), {
            reference: MEASUREMENTS_MODEL_REFERENCE,
        } );
        const painter = new MeasurementPainter();
        this._measurementLayer = new FeatureLayer( this._measurementModel, {
            id: MEASUREMENT_LAYER_ID,
            label: 'Mediciones',
            painter,
            hoverable: true,
        } );
        map.layerTree.addChild( this._measurementLayer );

        for ( const wrapper of loadAllMeasurements() ) {
            this.addMeasurementWrapper( wrapper );
        }
    }

    get measurementWrappers(): MeasurementWrapper[] {
        return this._measurementWrappers;
    }

    get pendingMeasurement(): Measurement | null {
        return this._pendingMeasurement;
    }

    get currentMeasurementType(): MeasurementType | null {
        return this._currentMeasurementType;
    }

    startMeasurement( type: MeasurementType, mode: MeasurementMode ) {
        let projector: MeasurementProjector | undefined;
        if ( mode === MeasurementMode.PLANE_CAST ) {
            projector = new ThreePointRaycastedProjector( this._map, PLANE_MESH_URL );
        }
        else if ( mode === MeasurementMode.PLANE_PROJECTED ) {
            projector = new ThreePointOrthogonalProjector( this._map, PLANE_MESH_URL );
        }
        const maxSegments = type === MeasurementType.ORTHOGONAL ? 1 : undefined;
        const controller = new Ruler3DController( createMeasurement( type ), {
            styles: PAINT_STYLES,
            maxSegments,
            projector,
        } );

        controller.on( MEASUREMENT_FINISHED_EVENT, ( measurement: Measurement ) => {
            const originalCamera = this._map.camera;
            this._map.mapNavigator
                .fit( {bounds: measurement.bounds, animate: false} )
                .then( () => {
                    const fitCamera = this._map.camera as PerspectiveCamera;
                    this._map.camera = originalCamera;
                    controller.enabled = false;
                    AnimationManager.putAnimation(
                        this._map.cameraAnimationKey,
                        create3DCameraAnimation( this._map, fitCamera, 2000 ),
                        false
                    )
                        .catch()
                        .finally( () => {
                            this._pendingMeasurement = measurement;
                            this._eventedSupport.emit(
                                PENDING_MEASUREMENT_CHANGED_EVENT,
                                measurement
                            );
                            this._pendingMeasurementFitPosition = (
                                this._map.camera as PerspectiveCamera
                            ).asLookFrom();
                            this._currentMeasurementType = null;
                            this._eventedSupport.emit(
                                CURRENT_MEASUREMENT_TYPE_CHANGED_EVENT,
                                this._currentMeasurementType
                            );
                        } );
                } );
        } );

        this._controllerUpdate( ( prev ) => ({
            ...prev,
            interactionController: controller,
            selection: undefined
        }) )
        this._currentMeasurementType = type;
        this._eventedSupport.emit(
            CURRENT_MEASUREMENT_TYPE_CHANGED_EVENT,
            this._currentMeasurementType
        );
    }

    stopMeasurement() {
        this._map.controller = null;
        this._currentMeasurementType = null;
        this._eventedSupport.emit(
            CURRENT_MEASUREMENT_TYPE_CHANGED_EVENT,
            this._currentMeasurementType
        );
    }

    addMeasurementWrapper( wrapper: MeasurementWrapper ) {
        this.addToModel( wrapper );
        this._measurementWrappers.push( wrapper );
        this._eventedSupport.emit(
            MEASUREMENT_WRAPPERS_CHANGED_EVENT,
            this._measurementWrappers
        );
    }

    removeMeasurementWrapper( measurementId: string ) {
        const index = this._measurementWrappers.findIndex(
            ( {id} ) => id === measurementId
        );
        if ( index >= 0 ) {
            this._measurementModel.remove( measurementId );
            const wrapper = this._measurementWrappers.splice( index, 1 )[0];
            this._eventedSupport.emit(
                MEASUREMENT_WRAPPERS_CHANGED_EVENT,
                this._measurementWrappers
            );
            deleteMeasurement( wrapper );
        }
    }

    acceptPendingMeasurement( id: string, name: string ) {
        if ( this._pendingMeasurement && this._pendingMeasurementFitPosition ) {
            const newMeasurementWrapper = {
                id,
                name,
                measurement: this._pendingMeasurement,
                fitPosition: this._pendingMeasurementFitPosition,
                expanded: true,
            };
            this._measurementWrappers.push( newMeasurementWrapper );
            this._eventedSupport.emit(
                MEASUREMENT_WRAPPERS_CHANGED_EVENT,
                this._measurementWrappers
            );
            this.addToModel( newMeasurementWrapper );
            this._pendingMeasurement = null;
            this._eventedSupport.emit( PENDING_MEASUREMENT_CHANGED_EVENT, null );
            this._map.controller = null;
            persistMeasurement( newMeasurementWrapper );
        }
    }

    rejectPendingMeasurement() {
        this._pendingMeasurement = null;
        this._eventedSupport.emit( PENDING_MEASUREMENT_CHANGED_EVENT, null );
        this._map.controller = null;
    }

    private addToModel( measurementWrapper: MeasurementWrapper ) {
        this._measurementModel.put(
            new Feature(
                measurementWrapper.measurement.focusPoint,
                {measurementWrapper},
                measurementWrapper.id
            )
        );
    }

    expand( featureId: string, expanded: boolean ) {
        const feature = this._measurementModel.get( featureId ) as Feature;
        feature.properties.measurementWrapper.expanded = expanded;
        this._eventedSupport.emit(
            MEASUREMENT_WRAPPERS_CHANGED_EVENT,
            this._measurementWrappers
        );
        this._measurementLayer.painter.invalidate( feature );
        updateMeasurement( feature.properties.measurementWrapper );
    }

    on(
        event: typeof MEASUREMENT_WRAPPERS_CHANGED_EVENT,
        callback: ( wrappers: MeasurementWrapper[] ) => void
    ): Handle;
    on(
        event: typeof PENDING_MEASUREMENT_CHANGED_EVENT,
        callback: ( measurement: Measurement | null ) => void
    ): Handle;
    on(
        event: typeof CURRENT_MEASUREMENT_TYPE_CHANGED_EVENT,
        callback: ( type: MeasurementType ) => void
    ): Handle;

    on(
        event:
            | typeof MEASUREMENT_WRAPPERS_CHANGED_EVENT
            | typeof PENDING_MEASUREMENT_CHANGED_EVENT
            | typeof CURRENT_MEASUREMENT_TYPE_CHANGED_EVENT,
        callback: any
    ): Handle {
        return this._eventedSupport.on( event, callback );
    }
}
