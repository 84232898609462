import React, {useState} from "react";
import {Card, Inline, Text} from '@digitalreality/ui';
import {getImagePreview} from "../model/ThreeDObjectTypes";
import ThreeDObjectController from "../controller/ThreeDObjectController";
import "./ThreeDObjectCard.css";
import {EventedSupport} from "@luciad/ria/util/EventedSupport";
import {useControllerContext} from "../../navigation/ControllerContext";

export interface IThreeDObjectCardProps {
    name: string;
}

export const ThreeDObjectCard = ( {name}: IThreeDObjectCardProps ) => {

    const [, setControllerContext] = useControllerContext();
    const [selected, setSelected] = useState<boolean>( false );

    const setCreateController = () => {
        setSelected( true );
        const controller = ThreeDObjectController.createController( name );
        EventedSupport.once( controller, "Deactivated", () => {
            setSelected( false );
        } )
        setControllerContext( ( prev ) => (
            {
            ...prev,
            interactionController: controller,
            selection: undefined
        }) )
    };

    return <Card title={name}
                 onClick={setCreateController}
                 className={selected ? "threeDObjectCardSelected" : "threeDObjectCard"}>
        <Inline>
            <img style={{width: "40px", height: "40px",}} src={getImagePreview( name )} alt="sign preview"/>
            <Text noWrap>{name}</Text>
        </Inline>
    </Card>
}
