import {CreateItemsDrawer} from "../../../util/create_item_drawer/CreateItemsDrawer";
import {Map} from "@luciad/ria/view/Map";
import React, {ReactElement, useEffect, useState} from "react";
import {UpwellingCampaign} from "../model/UpwellingCampaign";
import {UpwellingCampaignCard} from "./UpwellingCampaignCard";
import {UndergroundCrossSectionOverlay} from "../../underground_cut/ui/UndergroundCrossSectionOverlay";
import {WaterOutlined} from "@mui/icons-material";
import {SwipeControllerUI} from "../../../util/swipe_controller/SwipeControllerUI";
import {AlertDialog} from "../../../util/AlertDialog";
import {UpwellingCampaignForm} from "./UpwellingCampaignForm";
import {ColorMapEntry} from "../../../util/color_map_picker/ColorMapPicker";
import {LayerGroup} from "@luciad/ria/view/LayerGroup";
import {UpwellingCampaignDTO} from "../model/UpwellingCampaignDTO";
import {ConfirmationDialog} from "../../../util/ConfirmationDialog";
import {Group} from "../../../layers/Group";
import {UpwellingCampaignSensorsPainter} from "../view/UpwellingCampaignSensorsPainter";
import {WFSCapabilities} from "@luciad/ria/model/capabilities/WFSCapabilities";
import {create} from "@luciad/ria/view/feature/transformation/ClusteringTransformer";
import {WMSLayer} from "../../../layers/WMSLayer";
import {WFSLayer} from "../../../layers/WFSLayer";
import {fitToLayerGroup} from "../../../util/FitToLayerGroup";

const SERVER_ENDPOINT = "https://campo-cartagena-gemelo-digital-luciadfusion.es";

interface UpwellingCampaignDrawerProps {
    open: boolean;
    onClose: () => void;
    map: Map;
    password: string;
}

export function UpwellingCampaignDrawer( {open, onClose, map, password}: UpwellingCampaignDrawerProps ) {

    const [campaigns, setCampaigns] = useState<UpwellingCampaign[] | undefined>( undefined );

    const [selectedCampaign, setSelectedCampaign] = useState<UpwellingCampaign | undefined>( undefined );
    const [selectedCampaignLayerGroup, setSelectedCampaignLayerGroup] = useState<LayerGroup | undefined>( undefined );

    const [comparingCampaign, setComparingCampaign] = useState<UpwellingCampaign | undefined>( undefined );
    const [comparingCampaignLayerGroup, setComparingCampaignLayerGroup] = useState<LayerGroup | undefined>( undefined );

    const [error, setError] = useState<Error | undefined>( undefined );

    const [parsingCampaign, setParsingCampaign] = useState<boolean>( false );
    const [campaignToUpload, setCampaignToUpload] = useState<File | undefined>( undefined );
    const [uploadingCampaign, setUploadingCampaign] = useState<boolean>( false );

    const [campaignToDelete, setCampaignToDelete] = useState<UpwellingCampaign | undefined>( undefined );

    useEffect( () => {
        if ( !open ) {
            setSelectedCampaign( undefined );
        }
    }, [open] )

    useEffect( () => refreshCampaigns(), [] );

    useEffect( () => {
        setComparingCampaign( undefined );
    }, [selectedCampaign] );

    function refreshCampaigns() {
        const formData = new FormData()
        formData.append( "password", password );

        fetch( SERVER_ENDPOINT + "/upwelling/campaigns", {
            method: 'POST',
            body: formData,
        } )
            .then( response => response.json() )
            .then( responseJson => {
                const dtos = responseJson as UpwellingCampaignDTO[];
                setCampaigns( dtos.map( dto => ({
                    name: dto.name,
                    displayName: dto.displayName,
                    date: new Date( dto.timestamp ),
                    rasterURL: dto.rasterURL,
                    sensorsURL: dto.sensorsURL
                }) ) );
                setSelectedCampaign( undefined );
            } )
    }

    const handleDrop = ( e: React.DragEvent<HTMLDivElement> ) => {
        if ( campaignToUpload ) {
            return;
        }

        if ( uploadingCampaign ) {
            setError( new Error( "Ya se esta procesando otra campaña" ) )
            return;
        }

        setParsingCampaign( true );
        const files = e.dataTransfer.files;
        setCampaignToUpload( parseCampaign( files ) );
        setParsingCampaign( false );

    }

    const parseCampaign = ( files: FileList ): File | undefined => {
        if ( files.length !== 1 ) {
            setError( new Error( "Solo se puede subir un archivo" ) )
            return;
        }

        const file = files[0];
        if ( !(file.name.endsWith( "csv" ) || file.name.endsWith( "tif" )) ) {
            setError( new Error( "Solo se soporta CSV(.csv) o GeoTIFF(.tif)" ) )
            return;
        }

        return file;
    }

    const uploadForm = ( file: File ): ReactElement => {
        return <UpwellingCampaignForm
            fileToUpload={file}
            onCancel={() => setCampaignToUpload( undefined )}
            onAccept={( name, date, file, colorMap ) => uploadCampaign( name, date, file, colorMap )}
        />
    }

    const uploadCampaign = ( displayName: string, date: Date, file: File, colorMap: ColorMapEntry[] ): void => {
        const normalizedName = normalizeName( displayName );

        if ( campaigns!.filter( campaign => campaign.name === normalizedName ).length !== 0 ) {
            setError( new Error( "El nombre de la campaña debe ser único" ) )
            return;
        }

        if ( uploadingCampaign ) {
            setError( new Error( "Ya se esta procesando otra campaña" ) )
            return;
        }

        const formData = new FormData()
        formData.append( "name", normalizedName );
        formData.append( "displayName", displayName );
        formData.append( "date", date.toISOString() );
        formData.append( "file", file );
        formData.append( "colorMap", JSON.stringify( colorMap ) );
        formData.append( "password", password );

        setCampaignToUpload( undefined );
        setUploadingCampaign( true );

        fetch( SERVER_ENDPOINT + "/upwelling/campaigns/upload", {
            method: 'POST',
            body: formData,
            keepalive: true
        } ).then( response => {
            if ( response.ok ) {
                refreshCampaigns();
            }
            else {
                response.json().then( responseJson => {
                    const message = responseJson.message as string;
                    setError( new Error( message.split( "reason phrase: " )[1] ) );
                } )
            }
        } ).catch( error => {
            setError( error )
        } ).finally( () => setUploadingCampaign( false ) )
    }

    function normalizeName( displayName: string ) {
        // All the accents, spaces and specials characters  are removed, as we cannot create services with these names
        // Example Más allá de Iruña -> Mas_alla_de_Iruna
        return displayName.replaceAll( " ", "_" )
            .normalize( 'NFKD' )
            .replace( /[\u0300-\u036f]/g, "" );
    }

    function errorDialog( error: Error ) {
        return <AlertDialog title={"Ha ocurrido un error"}
                            message={error.message}
                            onClose={() => setError( undefined )}/>;
    }

    function createCard( campaign: UpwellingCampaign ): ReactElement {
        const isSelected = selectedCampaign === campaign;
        return <UpwellingCampaignCard key={campaign.rasterURL}
                                      campaign={campaign}
                                      onSelected={() => setSelectedCampaign( campaign )}
                                      selected={isSelected}
                                      onComparing={() =>
                                          setComparingCampaign( campaign === comparingCampaign ?
                                                                undefined :
                                                                campaign )
                                      }
                                      comparing={comparingCampaign === campaign}
                                      canCompare={selectedCampaign !== undefined && !isSelected}
                                      deleteClicked={campaign => setCampaignToDelete( campaign )}
        />;
    }

    function deleteDialog( campaign: UpwellingCampaign ): ReactElement {
        return <ConfirmationDialog message={"Esta seguro de borrar " + campaign.displayName + " ?"}
                                   onAccept={() => {
                                       const formData = new FormData()
                                       formData.append( "campaignName", campaign.name );
                                       formData.append( "password", password );
                                       fetch( SERVER_ENDPOINT + "/upwelling/campaigns/delete", {
                                           method: 'DELETE',
                                           body: formData,
                                           keepalive: true
                                       } ).then( response => {
                                           if ( response.ok ) {
                                               refreshCampaigns();
                                           }
                                           else {
                                               setError( new Error( "No se ha podido borrar la campaña" ) )
                                           }
                                       } ).catch( error => {
                                           setError( error )
                                       } ).finally( () => setUploadingCampaign( false ) )
                                       setCampaignToDelete( undefined );
                                   }}
                                   onCancel={() => setCampaignToDelete( undefined )}/>
    }

    return <>
        <CreateItemsDrawer title={"Surgencias de agua"}
                           headerIcon={<WaterOutlined sx={{color: "#B5B5B5"}}/>}
                           open={open} onClose={onClose}
                           handleDrop={handleDrop}
                           error={error} errorDialogSupplier={errorDialog}
                           items={campaigns} itemToCreate={campaignToUpload} formToCreateSupplier={uploadForm}
                           processingItem={parsingCampaign} creatingItem={uploadingCampaign}
                           filter={( filterText, campaign ) => {
                               const name = campaign.name;
                               return name.toUpperCase().includes( filterText.toUpperCase() );
                           }}
                           itemCardSupplier={createCard}
                           itemToDelete={campaignToDelete}
                           deleteForm={deleteDialog}/>
        {selectedCampaign && <CampaignLayers key={"select_" + selectedCampaign.name}
                                             map={map}
                                             campaign={selectedCampaign}
                                             onLoad={layerGroup => {
                                                 fitToLayerGroup( layerGroup );
                                                 setSelectedCampaignLayerGroup( layerGroup );
                                             }}
                                             onUnload={() => setSelectedCampaignLayerGroup( undefined )}/>}
        {
            selectedCampaign && selectedCampaignLayerGroup && !comparingCampaign &&
            <UndergroundCrossSectionOverlay key={selectedCampaignLayerGroup.label + "_controller"}
                                            mainMap={map} campaignName={selectedCampaign.name}/>
        }
        {comparingCampaign && <CampaignLayers key={"compare_" + comparingCampaign.name}
                                              map={map}
                                              campaign={comparingCampaign}
                                              onLoad={setComparingCampaignLayerGroup}
                                              onUnload={() => setComparingCampaignLayerGroup( undefined )}/>}
        {
            selectedCampaignLayerGroup && comparingCampaignLayerGroup &&
            <SwipeControllerUI key={selectedCampaignLayerGroup.label + comparingCampaignLayerGroup.label}
                               map={map} leftLayerGroup={selectedCampaignLayerGroup}
                               rightLayerGroup={comparingCampaignLayerGroup}/>
        }
    </>
}

const CampaignLayers = ( {map, campaign, onLoad, onUnload}: {
    map: Map,
    campaign: UpwellingCampaign,
    onLoad: ( layerGroup: LayerGroup ) => void,
    onUnload: () => void
} ) => {
    const noBlanksName = (campaign.name).replaceAll( " ", "_" );

    const [sensorsFeatureName, setSensorsFeatureName] = useState<string | undefined>( undefined );

    useEffect( () => {
        if ( campaign.sensorsURL ) {
            WFSCapabilities.fromURL( SERVER_ENDPOINT + campaign.sensorsURL )
                .then( capabilities => {
                    setSensorsFeatureName( capabilities.featureTypes[0].name )
                } );
        }
    }, [] )

    if ( campaign.sensorsURL && sensorsFeatureName === undefined ) {
        // Prevent flickering for being rendered twice
        return <></>
    }

    return sensorsFeatureName === undefined ?
           <Group key={"without_sensors"} map={map} label={campaign.displayName} onLoad={onLoad} onUnload={onUnload}>
               <WMSLayer
                   key={campaign.name + "_raster_layer"}
                   url={SERVER_ENDPOINT + campaign.rasterURL}
                   layer={noBlanksName + "_raster"}/>
           </Group> :
           <Group key={"with_sensors"} map={map} label={campaign.displayName} onLoad={onLoad} onUnload={onUnload} ordered>
               <WFSLayer
                   id={campaign.name + "_sensors"}
                   key={campaign.name + "_sensors_layer"}
                   url={SERVER_ENDPOINT + campaign.sensorsURL}
                   featureId={"code"}
                   featureType={sensorsFeatureName}
                   painter={new UpwellingCampaignSensorsPainter()}
                   transformer={create( {
                                            defaultParameters: {
                                                clusterSize: 300,
                                                minimumPoints: 3
                                            }
                                        } )}/>
               <WMSLayer
                   id={campaign.name + "_raster"}
                   key={campaign.name + "_raster_layer"}
                   url={SERVER_ENDPOINT + campaign.rasterURL}
                   layer={noBlanksName + "_raster"}/>
           </Group>
}