import React, {PropsWithChildren, useEffect, useState} from "react";
import "./CollapsabelPanel.css";
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import {Center} from "@digitalreality/ui";

interface Props {
    /**
     * The CSS class(es)
     */
    className?: string;

    /**
     * The text in the header
     */
    header: string;

    /**
     * The CSS class for the icon. For example "lcd-icon-layer"
     */
    headerIcon: string;

    /**
     * The initial collapsed state of this component
     */
    isCollapsed?: boolean;

    /**
     * Triggered when it is collapsed
     */
    onCollapsedChange?: ( isCollapsed: boolean ) => void
}

export const CollapsablePanel = ( {
                                      className,
                                      children,
                                      header,
                                      headerIcon,
                                      isCollapsed,
                                      onCollapsedChange
                                  }: PropsWithChildren<Props> ) => {

    const [collapsed, setCollapsed] = useState( isCollapsed ? isCollapsed : false );

    const toggleCollapsed = () => {
        setCollapsed( () => !collapsed );
    }

    useEffect( () => {
        if ( onCollapsedChange ) {
            onCollapsedChange( collapsed );
        }
    }, [collapsed] )

    const collapsedClass = collapsed ? "collapsed" : "open";

    return (
        <div className={`collapsable-panel ${className} ${collapsedClass}`}>
            <div className={`collapsable-panel-header ${collapsedClass}`} onClick={toggleCollapsed}>
                <span className="collapsable-panel-title">{header}</span>
                <div className="collapsable-panel-collapse-button">
                    <Center>
                        {collapsed ? <KeyboardArrowLeftOutlinedIcon/> : <KeyboardArrowDownOutlinedIcon/>}
                    </Center>
                </div>
            </div>
            <div className="collapsable-panel-body">
                {children}
            </div>
        </div>
    );
}
