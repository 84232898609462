import React, {useEffect, useState} from "react";
import {Group, LayerGroupType} from "./Group";
import {create3DTileLayer} from "./LayerFactory";
import {LayerGroup} from "@luciad/ria/view/LayerGroup";
import {Layer} from "@luciad/ria/view/Layer";
import {LayerOptions} from "./LayerOptions";
import {WMSLayer} from "./WMSLayer";
import {Endpoint} from "./Endpoint";

export const MESH_LAYER_ID = "MESH_LAYER";

enum LayerType {
    true_ortho = "true_ortho",
    land_cover = "land_cover",
    buildings = "buildings",
    trees = "trees",
    mesh = "mesh",
}

interface Block {
    blockNumber: number,
    mesh: Endpoints[],
    obj: Endpoints[]
}

interface Endpoints {
    type: LayerType,
    entries: Endpoint[]
}

export type OwnedLayerOptions = {
    parent?: LayerGroup,
    onLoad?: ( url: string ) => void,
    onFailedLoad?: ( url: string ) => void
} & LayerOptions;

/**
 * Maps an array of Endpoints to a map of LayerType -> Endpoint[]
 */
function byType( unsortedEndpoints: Endpoints[] ) {
    const byType: { [key: string]: Endpoint[] } = {}

    for ( let endpoints of unsortedEndpoints ) {
        let listForType = byType[endpoints.type] || [];
        listForType = listForType.concat( endpoints.entries )
        byType[endpoints.type] = listForType
    }

    return byType;
}

const Tileset3DLayer = ( props: { isPartOfTerrain?: boolean } & Endpoint & OwnedLayerOptions ) => {
    const [layer, setLayer] = useState<Layer>()

    useEffect( () => {
        create3DTileLayer( {
                               id: props.url,
                               selectable: false,
                               visible: true,
                               label: "",
                               isDrapeTarget: true,
                               ...props
                           } ).then( newLayer => {
            props.parent?.addChild( newLayer );
            setLayer( newLayer );
            props.onLoad?.( props.url );
        } ).catch( () => {
            console.error( "Could not load layer at " + props.url );
            props.onFailedLoad?.( props.url );
        } )

        return () => layer && props.parent?.removeChild( layer )
    }, [] )

    return <></>
}

export const CartagenaLayers = () => {

    const [layersData, setLayersData] = useState<Block[]>( [] );

    useEffect( () => {
        fetch( '/resources_ria/blocklayers.json' )
            .then( response => response.json() )
            .then( setLayersData )
    }, [] );

    if ( !layersData || layersData.length === 0 ) {
        return <></>
    }

    const meshes = byType( layersData.flatMap( (block => block.mesh) ) );
    const objs = byType( layersData.flatMap( (block => block.obj) ) );

    const Imageries =
        <Group label={"Imágenes"} hideFit hideVisibilityToggle>
            <Group key={LayerType.land_cover} label={"Uso de suelo"} type={LayerGroupType.RASTER} visible={false} lazy>
                <WMSLayer key={LayerType.land_cover}
                          url={"https://campo-cartagena-gemelo-digital-luciadfusion.es/ogc/wms/land_cover_all_blocks"}
                          layer={"land_cover_all_blocks"}
                          hideInLayerTree />
            </Group>
            <Group key={LayerType.true_ortho} label={"True ortho"} type={LayerGroupType.FOUR_BANDS} visible={false} lazy>
                <WMSLayer key={LayerType.true_ortho}
                          url={"https://campo-cartagena-gemelo-digital-luciadfusion.es/ogc/wms/all_blocks_true_ortho"}
                          layer={"natural_color"}
                          hideInLayerTree />
            </Group>
        </Group>

    const vectorBuildingsEndpoint = {
        url: "https://campo-cartagena-gemelo-digital-luciadfusion.es/ogc/wms/all_blocks_geopackage_building",
        layers: [
            "block_1_geopackage_building", "block_2_geopackage_building", "block_3_geopackage_building",
            "block_4_geopackage_building", "block_5_geopackage_building", "block_6_geopackage_building",
            "block_7_geopackage_building", "block_8_geopackage_building", "block_9_geopackage_building",
            "block_3456_geopackage_building"]
    }

    const VectorBuildings = <Group key={"vector_buildings"} label={"Edificios (vector)"} type={LayerGroupType.RASTER}
                                   visible={false} lazy>
        <WMSLayer key={vectorBuildingsEndpoint.url} hideInLayerTree {...vectorBuildingsEndpoint} minScale={1 / 100_000}/>
    </Group>

    const vectorTreesEndpoint = {
        url: "https://campo-cartagena-gemelo-digital-luciadfusion.es/ogc/wms/all_blocks_geopackage_tree",
        layers: [
            "block_1_geopackage_tree", "block_2_geopackage_tree", "block_3_geopackage_tree",
            "block_4_geopackage_tree", "block_5_geopackage_tree", "block_6_geopackage_tree",
            "block_7_geopackage_tree", "block_8_geopackage_tree", "block_9_geopackage_tree",
            "block_3456_geopackage_tree"]
    }

    const VectorTrees = <Group key={"vector_tree"} label={"Árboles (vector)"} type={LayerGroupType.RASTER}
                               visible={false} lazy>
        <WMSLayer key={vectorTreesEndpoint.url} hideInLayerTree {...vectorTreesEndpoint} minScale={1 / 100_000}/>
    </Group>

    const ObjBuildings =
        <Group label={"Edificios (LOD2)"} visible={false} lazy>
            {objs[LayerType.buildings]?.map( endpoint => <Tileset3DLayer key={endpoint.url}
                                                                         hideInLayerTree
                                                                         {...endpoint} /> )}
        </Group>

    const ObjTrees =
        <Group label={"Árboles (LOD2)"} visible={false} lazy>
            {objs[LayerType.trees]?.map( endpoint => <Tileset3DLayer key={endpoint.url}
                                                                     hideInLayerTree
                                                                     {...endpoint} /> )}
        </Group>

    const Meshes =
        <Group label={"3D Mesh"} visible={false} id={MESH_LAYER_ID} lazy>
            {meshes[LayerType.mesh]?.map( endpoint => <Tileset3DLayer key={endpoint.url}
                                                                      hideInLayerTree
                                                                      isPartOfTerrain
                                                                      {...endpoint} /> )}
        </Group>

    return <Group label={"Cartagena"} hideFit hideVisibilityToggle>
        {Imageries}
        {VectorBuildings}
        {VectorTrees}
        {ObjBuildings}
        {ObjTrees}
        {Meshes}
    </Group>
}
