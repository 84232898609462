import {
    Drawer,
    DrawerActions,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerTitle,
    useTheme
} from "@digitalreality/ui";
import React, {KeyboardEvent, ReactElement, useState} from "react";
import {Button, Stack, TextField, Typography} from "@mui/material";

interface AuthenticateDrawerProps {
    title: string,
    icon: ReactElement,
    open: boolean;
    onClose: () => void;
    isValidPassword: ( password: string ) => Promise<boolean>
    sensitiveComponentSupplier: ( password: string ) => ReactElement
}

/**
 * Drawer that shows a text field to input a password. If the password is correct it shows the target element.
 */
export function AuthenticateDrawer( {
                                        title,
                                        icon,
                                        open,
                                        onClose,
                                        isValidPassword,
                                        sensitiveComponentSupplier
                                    }: AuthenticateDrawerProps ) {

    const theme = useTheme();

    const [password, setPassword] = useState<string>( "" );
    const [authenticated, setAuthenticated] = useState<boolean | undefined>( undefined );

    function consumeEvent( event: KeyboardEvent<HTMLInputElement> ) {
        event.stopPropagation();
    }

    return <>
        {
            authenticated ? sensitiveComponentSupplier( password ) :
            <Drawer variant="persistent" open={open} onClose={onClose}>
                <DrawerContent pl={theme.sizes.navbar} width={380}>
                    <DrawerHeader>
                        <DrawerTitle icon={icon}>{title}</DrawerTitle>
                        <DrawerActions>
                            <DrawerCloseButton tooltipLabel={"Cerrar panel"} onClick={onClose}/>
                        </DrawerActions>
                    </DrawerHeader>
                    <DrawerBody marginLeft="8px" marginTop="8px" marginRight="8px">
                        <div onKeyUp={consumeEvent} onKeyDown={consumeEvent}>
                            <Stack spacing={"15px"}>
                                <TextField label="Contraseña" sx={{top: "10px"}} type={"password"} required
                                           value={password} onChange={event => setPassword( event.target.value )}/>
                                <Button onClick={() => {
                                    isValidPassword( password ).then( ok => setAuthenticated( ok ) );
                                }}>Activar</Button>
                                {authenticated === false &&
                                 <Typography color={"red"}>La contraseña no es válida</Typography>}
                            </Stack>
                        </div>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        }
    </>
}