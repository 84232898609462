import React from 'react';

import {Grid, Slider, Tooltip, Typography} from "@digitalreality/ui";
import {Opacity} from "@mui/icons-material";
import {RasterLayerGroup} from "./RasterLayerGroup";
import {RasterLayer} from "@luciad/ria/view/RasterLayer";
import {RasterTileSetLayer} from "@luciad/ria/view/tileset/RasterTileSetLayer";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import {FourBandsStyle, FourBandsWMSLayerGroup} from "./FourBandsWMSLayerGroup";

export type RasterStyleable = RasterLayerGroup | RasterLayer | RasterTileSetLayer;

interface Props {
    node: RasterStyleable;
}

const BandSelection = ( {node}: { node: FourBandsWMSLayerGroup } ) => {
    const [bandSelection, setBandSelection] = React.useState<FourBandsStyle>( node.bandStyle || FourBandsStyle.NATURAL_COLOR );

    function updateBandSelection( event: any, newValue: FourBandsStyle ) {
        if ( newValue !== null ) {
            setBandSelection( newValue as FourBandsStyle );
            node.bandStyle = newValue
        }
    }

    return (<ToggleButtonGroup
        value={bandSelection}
        exclusive
        onChange={updateBandSelection}>
        <ToggleButton value={FourBandsStyle.NATURAL_COLOR}>
            <Typography>
                Colores naturales
            </Typography>
        </ToggleButton>
        <ToggleButton value={FourBandsStyle.INFRARED}>
            <Typography>
                Infrarojo
            </Typography>
        </ToggleButton>
    </ToggleButtonGroup>)
}

export const LayerSettings = ( {node}: Props ) => {
    const [alpha, setAlpha] = React.useState<number>( node.rasterStyle.alpha || 1 );

    function updateAlpha( event: Event, newValue: number | number[] ) {
        setAlpha( newValue as number );
        node.rasterStyle = {...node.rasterStyle, alpha: newValue as number}
    }

    return <Grid container spacing={2} sx={{width: "250px", margin: "0", padding: "8px 0"}}>
        <Grid item xs={2}>
            <Tooltip title={"Opacidad"}>
                <Opacity/>
            </Tooltip>
        </Grid>
        <Grid item sx={{paddingRight: "26px"}} xs={10}>
            <Slider size="small"
                    value={alpha}
                    onChange={updateAlpha}
                    step={0.01}
                    min={0}
                    max={1}/>
        </Grid>

        {node instanceof FourBandsWMSLayerGroup &&
         <Grid item xs={12}>
           <BandSelection node={node as FourBandsWMSLayerGroup}/>
         </Grid>}
    </Grid>
}

