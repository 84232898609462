import {RotateController} from "@luciad/ria/view/controller/RotateController";
import {GestureEvent} from "@luciad/ria/view/input/GestureEvent";
import {PerspectiveCamera} from "@luciad/ria/view/camera/PerspectiveCamera";
import {HandleEventResult} from "@luciad/ria/view/controller/HandleEventResult";


/**
 * A RotateController on which one can configure bounds for the pitch angle. This means the camera will not be
 * allowed to have a pitch angle outside the configured range.
 */
export default class BoundedRotateController extends RotateController {

    private readonly _minPitch: number;
    private readonly _maxPitch: number;
    private _rotating: boolean = false;
    private _startAngle: number = 0;

    constructor( options: { minPitch: number, maxPitch: number } ) {
        super();
        this._minPitch = options.minPitch
        this._maxPitch = options.maxPitch
    }


    onGestureEvent( gestureEvent: GestureEvent ): HandleEventResult {
        // The camera's pitch changes constantly while rotating, but somehow RIA expects getPitchAngle to return
        // the angle since the start of the drag event.
        // So we keep the start angle as state for the duration of the drag event.
        if ( this.isRotateEvent( gestureEvent ) && !this._rotating ) {
            this._rotating = true
            this._startAngle = (this.map!.camera as PerspectiveCamera).asLookFrom().pitch;
        }

        if ( this.isRotateEndEvent( gestureEvent ) && this._rotating ) {
            this._rotating = false
        }

        return super.onGestureEvent( gestureEvent );
    }

    getPitchAngle( gestureEvent: GestureEvent ): number {
        // We make sure that the pitch delta is such that the resulting map pitch does not leave the configured
        // minPitch & maxPitch
        return this.clamp( this._startAngle, super.getPitchAngle( gestureEvent ) );
    }

    private clamp( startAngle: number, pitchDelta: number ) {
        if ( startAngle + pitchDelta > this._maxPitch ) {
            return this._maxPitch - startAngle;
        }
        if ( startAngle + pitchDelta < this._minPitch ) {
            return this._minPitch - startAngle;
        }

        return pitchDelta;
    }
}
