import {LayerConstructorOptions, LayerTreeNode} from "@luciad/ria/view/LayerTreeNode";
import {Layer} from "@luciad/ria/view/Layer";
import {LayerGroup} from "@luciad/ria/view/LayerGroup";
import {RasterStyle} from "@luciad/ria/view/style/RasterStyle";
import {LayerTreeVisitor} from "@luciad/ria/view/LayerTreeVisitor";
import {RasterLayer} from "@luciad/ria/view/RasterLayer";
import ReturnValue = LayerTreeVisitor.ReturnValue;
import VisitOrder = LayerTreeNode.VisitOrder;
import {RasterTileSetLayer} from "@luciad/ria/view/tileset/RasterTileSetLayer";

/**
 * A group of raster layers. We use this to bundle several layers together and let the user configure them together,
 * without showing the individual layers in the tree.
 */
export class RasterLayerGroup extends LayerGroup {

    private _rasterStyle: RasterStyle;

    constructor( options: LayerConstructorOptions ) {
        super( options );
        this._rasterStyle = {}
    }

    get rasterStyle(): RasterStyle {
        return this._rasterStyle;
    }

    set rasterStyle( value: RasterStyle ) {
        this._rasterStyle = value;
        this.visitChildren(new StyleUpdator( value ), VisitOrder.TOP_DOWN);
    }
}


class StyleUpdator implements LayerTreeVisitor {
    private readonly style: RasterStyle;

    constructor( style: RasterStyle ) {
        this.style = style;
    }

    visitLayer( layer: Layer ): LayerTreeVisitor.ReturnValue {
        if( layer instanceof RasterLayer || layer instanceof RasterTileSetLayer ) {
            layer.rasterStyle = this.style
        }
        return ReturnValue.CONTINUE;
    }

    visitLayerGroup( layerGroup: LayerGroup ): LayerTreeVisitor.ReturnValue {
        layerGroup.visitChildren(this, VisitOrder.TOP_DOWN);
        return ReturnValue.CONTINUE;
    }
}
