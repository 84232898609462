import React from "react";
import {Absolute, Card, Text} from '@digitalreality/ui';
import "./SimulationCard.css";
import {PlayOutlineIcon} from "@digitalreality/ui-icons";

import {Simulation} from "./Simulation";
import {Loop} from "@mui/icons-material";
import {IconButton} from "@mui/material";

export interface SimulationCardProps {
    simulation: Simulation,
    onClick: () => void,
    selected: boolean,
    loading: boolean
}

export const SimulationCard = ( {simulation, onClick, selected, loading}: SimulationCardProps ) => {
    return <Card title={simulation.name}
                 sx={( theme ) => ({
                     width: '285px',
                     height: '200px',
                     position: 'relative',
                     backgroundColor: selected ? "rgba(255,255,255,50%)" : theme.colors.ui.menu,
                     padding: '8px',
                 })}
                 onClick={onClick}>
        {simulation.threeD &&
         <Absolute left={"20px"} top={"18px"}>
           <Text size={"20px"} fontWeight={"bold"} noWrap>3D</Text>
         </Absolute> }
        {loading &&
         <Absolute right={"20px"} top={"18px"}>
           <IconButton className={"spin"} sx={{color: "#ffffff !important"}} size="large" disabled>
             <Loop fontSize="large"/>
           </IconButton>
         </Absolute> }
        <div className={"play-overlay"}>
            <Absolute left={"106px"} top={"56px"}>
                <PlayOutlineIcon sx={{width: "72px", height: "72px"}}/>
            </Absolute>
        </div>

        <img className={"card-preview"}
             src={ simulation.threeD ?
                   'resources_ria/simulations/hecras_depth_3d.png' :
                   'resources_ria/simulations/hecras_depth.png' }
             alt="simuation preview"/>
        <Absolute left={"16px"} bottom={"16px"}>
            <Text size={"12px"} noWrap>{simulation.name}</Text>
        </Absolute>
    </Card>
}
