import React, {useEffect} from 'react';
import {ISimulationContext} from "./SimulationContext";
import {Map} from "@luciad/ria/view/Map";
import {Player} from "../player/Player/Player";
import {SimulationLayerTreeVisitor} from "./SimulationLayerTreeVisitor";
import TimeDimensionPlayer from "../player/TimeDimensionPlayer";
import {WMSFeatureInfoController} from "../util/wmsfeatureinfocontroller/WMSFeatureInfoController";
import {useControllerContext} from "../navigation/ControllerContext";
import {usePlayerContext} from "../player/Player/PlayerContext";
import fetchIntercept from 'fetch-intercept';
import {WMSTileSetLayer} from "@luciad/ria/view/tileset/WMSTileSetLayer";

const IBER_NO_DATA_VALUE = "-9999.0";
const HECRAS_NO_DATA_VALUE = "-32767.0";

interface SimulationPlayerProps {
    simulationContext: ISimulationContext,
    map: Map
}

export const SimulationPlayer = ( {simulationContext, map}: SimulationPlayerProps ) => {

    const layerSupplier = () => {
        const visitor = new SimulationLayerTreeVisitor();
        map.layerTree.accept( visitor );
        return visitor.layer!;
    }
    const player = new TimeDimensionPlayer( simulationContext.startTimeUnixEpoch, simulationContext.endTimeUnixEpoch, layerSupplier );

    const simulationLayer = layerSupplier();
    return <Player player={player}
                   minSliderValue={simulationContext.startTimeUnixEpoch}
                   maxSliderValue={simulationContext.endTimeUnixEpoch}
                   interval={simulationContext.intervalMilliSeconds}>
        {simulationLayer instanceof WMSTileSetLayer &&
         <WMSFeatureInfo controller={createInfoController( simulationLayer )}/>}
    </Player>
}

function createInfoController( simulationLayer: WMSTileSetLayer ) {
    return new WMSFeatureInfoController(
        simulationLayer,
        [IBER_NO_DATA_VALUE, HECRAS_NO_DATA_VALUE],
        value => {
            const meters = parseFloat( value );
            return meters.toFixed( 2 );
        } )
}

interface WMSFeatureInfoProps {
    controller: WMSFeatureInfoController
}

const WMSFeatureInfo = ( {controller}: WMSFeatureInfoProps ) => {

    const [controllerContext, setControllerContext] = useControllerContext();
    const [playerState, _] = usePlayerContext();

    useEffect( () => {
        setControllerContext( ( prev ) => ({...prev, interactionController: controller}) );

        //FIXME This is a workaround to intercept the get feature info and make it point to HTTPS, it should be
        // better to configure Fusion somehow
        const unregister = fetchIntercept.register(
            {
                request: function ( url, config ) {
                    if ( url.includes( "http://campo-cartagena-gemelo-digital-luciadfusion.es:80/" ) ) {
                        const httpsURL = url.replace( "http://campo-cartagena-gemelo-digital-luciadfusion.es:80",
                                                      "https://campo-cartagena-gemelo-digital-luciadfusion.es" );
                        return [httpsURL, config];
                    }
                    return [url, config];
                }
            } );
        return () => {
            setControllerContext( ( prev ) => ({...prev, interactionController: undefined}) );
            unregister();
        };
    }, [] );

    useEffect( () => {
        const controller = controllerContext.interactionController;
        if ( controller instanceof WMSFeatureInfoController ) {
            controller.disabled = playerState.playing;
            controller.clear();
        }
    }, [playerState.playing] );

    useEffect( () => {
        const controller = controllerContext.interactionController;
        if ( controller instanceof WMSFeatureInfoController && !controller.disabled ) {
            controller.clear();
        }
    }, [playerState.fraction] );

    return <span/>;
}