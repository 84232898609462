import {UnitOfMeasure} from "@luciad/ria/uom/UnitOfMeasure";
import {Map} from "@luciad/ria/view/Map";
import React, {useRef} from "react";
import {ScaleType, useMapScale} from "../../hooks/useMapScale";
import "./ScaleIndicator.css";
import {useScaleIndicator} from "../../hooks/useScaleIndicator";
import {useMapContext} from "../../hooks/useMapContext";

const MAX_WIDTH_PIXELS = 150;

interface Props {
  uom?: UnitOfMeasure;
}

export const ScaleIndicator = ({uom}: Props) => {
    const map = useMapContext().map;

  const ref = useRef<HTMLDivElement>(null);
  const mapScale = useMapScale(map, ScaleType.MAP_CENTER);
  const {sizes, text} = useScaleIndicator(mapScale,
      ref.current ? ref.current.getBoundingClientRect().width : MAX_WIDTH_PIXELS, uom);

  return (
      <div className="scale-indicator" ref={ref}>
        <div className="scale-indicator-text">{text}</div>
        <div className="scale-indicator-background" style={{width: `${sizes.width}px`, left: `${sizes.left}px`}}>
          <div className="scale-indicator-foreground first"/>
          <div className="scale-indicator-foreground third"/>
        </div>
      </div>
  );
}
