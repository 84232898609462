import {OrientedBox} from "@luciad/ria/shape/OrientedBox";
import {GeoCanvas} from "@luciad/ria/view/style/GeoCanvas";
import {OcclusionMode} from "@luciad/ria/view/style/OcclusionMode";
import {Polygon} from "@luciad/ria/shape/Polygon";

const OUTLINE_COLOR = "rgb(255,255,255)";
const OUTLINE_COLOR_OCCLUDED = "rgba(255, 255, 255, 0.2)";
const FILL_COLOR = "rgba(171,232,229, 0.3)";

export interface DrawBoxOptions {
  hightlighted?: boolean;
  withOccludedPart?: boolean;
}

/**
 * Draws the given box on the given canvas
 */
export function drawBox(geoCanvas: GeoCanvas, box: OrientedBox, options?: DrawBoxOptions) {
  const highlighted = !!options?.hightlighted;
  const withOccludedPart = !!options?.withOccludedPart;

  geoCanvas.drawShape(box, {
    stroke: {
      width: highlighted ? 4 : 2,
      color: OUTLINE_COLOR
    },
    fill: highlighted ? {
      color: FILL_COLOR,
    } : undefined
  });

  if (withOccludedPart) {
    geoCanvas.drawShape(box, {
      stroke: {
        width: highlighted ? 4 : 2,
        color: OUTLINE_COLOR_OCCLUDED
      },
      fill: highlighted ? {
        color: FILL_COLOR,
      } : undefined,
      occlusionMode: OcclusionMode.OCCLUDED_ONLY
    });
  }
}

/**
 * Draws the given box face on the given canvas
 */
export function drawFacePolygon(geoCanvas: GeoCanvas, polygon: Polygon, hovered: boolean) {
  geoCanvas.drawShape(polygon, {
    stroke: {
      width: hovered ? 4 : 2,
      color: OUTLINE_COLOR
    },
    fill: hovered ? {
      color: FILL_COLOR,
    } : undefined
  });
}
