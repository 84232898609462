import {ISimulationLayer} from "./ISimulationLayer";
import {Bounds} from "@luciad/ria/shape/Bounds";
import {createFrom3DSimulation, TimeDimensions} from "../model/TimeDimensions";
import {FeatureLayer} from "@luciad/ria/view/feature/FeatureLayer";
import {FeatureModel} from "@luciad/ria/model/feature/FeatureModel";
import {ISimulationPolygonProperties} from "../model/ISimulationPolygon";
import {FeaturePainter, PaintState} from "@luciad/ria/view/feature/FeaturePainter";
import {GeoCanvas} from "@luciad/ria/view/style/GeoCanvas";
import {Feature} from "@luciad/ria/model/feature/Feature";
import {Map} from "@luciad/ria/view/Map";
import {Shape} from "@luciad/ria/shape/Shape";
import {Layer} from "@luciad/ria/view/Layer";
import {ShapeStyle} from "@luciad/ria/view/style/ShapeStyle";

export class SimulationLayer3D extends FeatureLayer implements ISimulationLayer {

    private readonly _bounds: Bounds;
    private readonly _timeDimensions: TimeDimensions;
    private _currentDate: Date;

    constructor( model: FeatureModel ) {
        super( model );
        this._bounds = model.bounds!;
        this._timeDimensions = createFrom3DSimulation( model );
        this._currentDate = this._timeDimensions.startDate;
        this.painter = new ExtrudedShapesPainter();

        this.applyDate( this._currentDate );
    }

    get bounds(): Bounds {
        return this._bounds;
    }

    get timeDimensions(): TimeDimensions {
        return this._timeDimensions;
    }

    get currentDate(): Date {
        return this._currentDate;
    }

    applyDate( newDate: Date ): void {
        this.filter = feature => {
            const properties = feature.properties as ISimulationPolygonProperties;
            return properties.instant === newDate.getTime();
        }
        this._currentDate = newDate;
    }
}

class ExtrudedShapesPainter extends FeaturePainter {

    paintBody( geoCanvas: GeoCanvas, feature: Feature, shape: Shape, layer: Layer, map: Map, paintState: PaintState ) {
        const properties = feature.properties as ISimulationPolygonProperties;
        const style = {
            stroke: {
                color: 'rgb(255,255,255)',
                width: 2
            },
            fill: {
                color: properties.height == 2 ? 'rgba(255,0,0,0.5)' : 'rgba(1,113,255,0.5)'
            }
        } as ShapeStyle;
        geoCanvas.drawShape( shape, style );
    }
}