import {LayerTreeVisitor} from "@luciad/ria/view/LayerTreeVisitor";
import {Layer} from "@luciad/ria/view/Layer";
import {LayerGroup} from "@luciad/ria/view/LayerGroup";
import {LayerTreeNode} from "@luciad/ria/view/LayerTreeNode";
import {ISimulationLayer} from "./view/ISimulationLayer";
import ReturnValue = LayerTreeVisitor.ReturnValue;

export class SimulationLayerTreeVisitor implements LayerTreeVisitor {

    layer: ISimulationLayer | undefined;

    visitLayer( layer: Layer ): LayerTreeVisitor.ReturnValue {
        // @ts-ignore
        if ( layer.timeDimensions !== undefined ) {
            this.layer = layer as unknown as ISimulationLayer;
            return ReturnValue.ABORT;
        }
        return ReturnValue.CONTINUE;
    }

    visitLayerGroup( layerGroup: LayerGroup ): LayerTreeVisitor.ReturnValue {
        layerGroup.visitChildren( this, LayerTreeNode.VisitOrder.TOP_DOWN );
        return ReturnValue.CONTINUE;
    }
}