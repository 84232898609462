import {useEffect, useState} from 'react';
import {IPlayer} from "../../PlayerTypes";

export function usePlayerProgress( player: IPlayer, fraction: number, minSliderValue: number, maxSliderValue: number, duration: number ): [number, string] {
    const [formattedValue, setFormattedValue] = useState<string>( '' );
    const [sliderValue, setSliderValue] = useState<number>( 0 );

    useEffect( () => {
        setFormattedValue( formatValue( minSliderValue, maxSliderValue ) )
        setSliderValue( minSliderValue );
    }, [player] )

    useEffect( () => {
        const value = Math.floor( minSliderValue + fraction * duration );
        setFormattedValue( formatValue( value, maxSliderValue ) );
        setSliderValue( value );
    }, [fraction] );

    return [sliderValue, formattedValue];
}

function formatValue( value: number, maxSliderValue: number ): string {
    return `${timeFormatter( value )} - ${timeFormatter( maxSliderValue )}`;
}

function timeFormatter( value: number ): string {
    const date = new Date( value );
    return withTwoZeros( date.getHours() ) + ":" + withTwoZeros( date.getMinutes() ) + ":" + withTwoZeros( date.getSeconds() ) + " " +
           withTwoZeros( date.getDate() ) + "/" + withTwoZeros( date.getMonth() + 1 ) + "/" + date.getFullYear();
}

function withTwoZeros( value: number ): string {
    return value.toString().padStart( 2, "0" );
}
