import {Animation} from "@luciad/ria/view/animation/Animation";
import TimeDimensionPlayer from "./TimeDimensionPlayer";
import {TimeDimensions} from "../simulation/model/TimeDimensions";
import {ISimulationLayer} from "../simulation/view/ISimulationLayer";

export class TimeDimensionPlayerAnimation extends Animation {

    private readonly _player: TimeDimensionPlayer;
    private readonly _startFraction: number;
    private readonly _speedFactorSeconds: number;
    private readonly _layerSupplier: () => ISimulationLayer;

    constructor( player: TimeDimensionPlayer, startFraction: number, duration: number, speedFactor: number, layerSupplier: () => ISimulationLayer ) {
        super( duration );
        this._player = player;
        this._startFraction = startFraction;
        this._speedFactorSeconds = speedFactor;
        this._layerSupplier = layerSupplier;
    }

    update( fraction: number ) {
        const newFraction = this._startFraction + (fraction * this._speedFactorSeconds);
        if ( newFraction > 1 ) {
            this._player.stop();
            return;
        }
        this._player.updateAnimationState( newFraction );

        const layer = this._layerSupplier();
        const timeDimensions = layer.timeDimensions;

        const startStamp = timeDimensions.startDate.getTime();
        const delta = newFraction * timeDimensions.durationMilliSeconds;
        let newDate = new Date( startStamp + delta );

        const intervalMillis = TimeDimensionPlayerAnimation.calculateInterval( timeDimensions, this._speedFactorSeconds );
        newDate = new Date( Math.floor( newDate.getTime() / intervalMillis ) * intervalMillis )

        if ( layer.currentDate.toISOString() !== newDate.toISOString() ) {
            console.debug( "Update", newFraction )
            console.debug( newDate.toISOString() )
            layer.applyDate( newDate );
        }
    }

    private static calculateInterval( modelDescriptor: TimeDimensions, speedFactorSeconds: number ) {
        // Make at maximum 1 request per second if the speed factor is too high.
        const intervalMilliSeconds = modelDescriptor.intervalMilliSeconds;
        const speedFactorMillis = speedFactorSeconds * 1000;
        return intervalMilliSeconds < speedFactorMillis ?
               Math.floor( speedFactorMillis / intervalMilliSeconds ) * intervalMilliSeconds :
               intervalMilliSeconds;
    }
}


