import {Controller} from "@luciad/ria/view/controller/Controller";
import {LocationMode} from "@luciad/ria/transformation/LocationMode";
import {Map} from "@luciad/ria/view/Map";
import {Transformation} from "@luciad/ria/transformation/Transformation";
import {createPoint} from "@luciad/ria/shape/ShapeFactory";
import {getReference} from "@luciad/ria/reference/ReferenceProvider";
import {Point} from "@luciad/ria/shape/Point";
import {GestureEvent} from "@luciad/ria/view/input/GestureEvent";
import {HandleEventResult} from "@luciad/ria/view/controller/HandleEventResult";
import {GestureEventType} from "@luciad/ria/view/input/GestureEventType";
import {PerspectiveCamera} from "@luciad/ria/view/camera/PerspectiveCamera";
import {create3DCameraAnimation} from "../common/controller/animation/Move3DCameraAnimation";
import {AnimationManager} from "@luciad/ria/view/animation/AnimationManager";
import {createTransformation} from "@luciad/ria/transformation/TransformationFactory";
import {LookFrom} from "@luciad/ria/view/camera/LookFrom";
import {Vector3} from "@luciad/ria/util/Vector3";

const WGS84_REF = getReference( "CRS:84" );

/**
 * A controller that moves the camera eye to the clicked location
 */
export default class ObliqueController extends Controller {

    private _handling: boolean;

    private _worldPoint?: Point;
    private _viewToWorld?: Transformation;

    private _wgsPoint?: Point;
    private _worldToWGS?: Transformation;
    private _wgsToWord?: Transformation;

    constructor() {
        super();
        this._handling = false;
    }

    onActivate( map: Map ) {
        super.onActivate( map );
        this._worldPoint = createPoint( map.reference, [0, 0] );
        this._viewToWorld = map.getViewToMapTransformation( LocationMode.TERRAIN );

        this._wgsPoint = createPoint( WGS84_REF, [0, 0] );
        this._worldToWGS = createTransformation( map.reference, WGS84_REF );
        this._wgsToWord = createTransformation( WGS84_REF, map.reference );
    }

    onGestureEvent( gestureEvent: GestureEvent ): HandleEventResult {
        if ( this._handling ) {
            // Prevent any interaction while handling a request
            return HandleEventResult.EVENT_HANDLED;
        }

        if ( gestureEvent.type === GestureEventType.SINGLE_CLICK_UP ) {
            this._handling = true;

            const map = this.map!;
            const camera = map.camera as PerspectiveCamera;
            const lookFrom = camera.asLookFrom();
            const targetEyePoint = this.getTargetEyePoint( gestureEvent, lookFrom );
            const targetCamera = camera.lookFrom( {
                                                      ...lookFrom,
                                                      eye: targetEyePoint
                                                  } );

            const animation = create3DCameraAnimation( map, targetCamera, 2000 );
            animation.onStop = () => this._handling = false;
            AnimationManager.putAnimation( map.cameraAnimationKey, animation, false );

            return HandleEventResult.EVENT_HANDLED;
        }
        return HandleEventResult.EVENT_IGNORED;
    }

    private getTargetEyePoint( gestureEvent: GestureEvent, lookFrom: LookFrom ): Vector3 {
        const currentEye = lookFrom.eye;
        this._worldPoint!.move3D( currentEye.x, currentEye.y, currentEye.z );
        this._worldToWGS!.transform( this._worldPoint!, this._wgsPoint );
        const currentHeight = this._wgsPoint!.z;

        this._viewToWorld!.transform( gestureEvent.viewPoint, this._worldPoint );
        this._worldToWGS!.transform( this._worldPoint!, this._wgsPoint );
        this._wgsPoint?.move3D( this._wgsPoint.x, this._wgsPoint.y, currentHeight );

        this._wgsToWord!.transform( this._wgsPoint!, this._worldPoint );
        return {x: this._worldPoint!.x, y: this._worldPoint!.y, z: this._worldPoint!.z};
    }
}