import {FeatureLayerConstructorOptions} from "@luciad/ria/view/feature/FeatureLayer";
import React, {useEffect, useState} from "react";
import {Layer} from "@luciad/ria/view/Layer";
import {createWFSLayer} from "./LayerFactory";
import {OwnedLayerOptions} from "./CartagenaLayers";
import {Endpoint} from "./Endpoint";

export interface WFSEndpoint extends Endpoint {
    featureType: string,
    featureId: string
}

export const WFSLayer = ( props: WFSEndpoint & OwnedLayerOptions & FeatureLayerConstructorOptions ) => {
    const [layer, setLayer] = useState<Layer>()

    useEffect( () => {
        createWFSLayer( {
                            id: props.url + props.featureType,
                            featureTypeName: props.featureType,
                            featureIdProperty: props.featureId,
                            selectable: true,
                            ...props
                        } ).then( newLayer => {
            props.parent?.addChild( newLayer );
            setLayer( newLayer );
            props.onLoad?.( props.url );
        } ).catch( () => {
            console.error( "Could not load layer at " + props.url + " with feature type being " + props.featureType );
            props.onFailedLoad?.( props.url );
        } )

        return () => layer && props.parent?.removeChild( layer )
    }, [] )

    return <></>
}