import {IPlayer, IPlayerTimeInfo, PLAYER_TIME_EVENT} from "./PlayerTypes";
import {EventedSupport} from "@luciad/ria/util/EventedSupport";
import {AnimationManager} from "@luciad/ria/view/animation/AnimationManager";
import {TimeDimensionPlayerAnimation} from "./TimeDimensionPlayerAnimation";
import {Animation} from "@luciad/ria/view/animation/Animation";
import {ISimulationLayer} from "../simulation/view/ISimulationLayer";

const ANIMATION_KEY = "TimePlayerAnimation";

export default class TimeDimensionPlayer extends EventedSupport implements IPlayer {

    private readonly _startTimeUnixEpoch: number;
    private readonly _endTimeUnixEpoch: number;
    private readonly _durationTimeMillis: number;
    private readonly _layerSupplier: () => ISimulationLayer;

    private animation: Animation | null = null;
    private speedFactor = 1.0;
    private fraction = 0.0;
    private playing = false;

    constructor( startTimeUnixEpoch: number, endTimeUnixEpoch: number, layerSupplier: () => ISimulationLayer ) {
        super();
        this._startTimeUnixEpoch = startTimeUnixEpoch;
        this._endTimeUnixEpoch = endTimeUnixEpoch;
        this._durationTimeMillis = endTimeUnixEpoch - startTimeUnixEpoch;
        this._layerSupplier = layerSupplier;
    }

    init(): void {
    }

    move( newFraction: number ): void {
        if ( this.playing ) {
            throw new Error( "Cannot move while playing" );
        }
        this.fraction = newFraction;
        this.animation = new TimeDimensionPlayerAnimation( this, this.fraction, this._durationTimeMillis, this.speedFactor, this._layerSupplier );
        this.animation.update( 0 );
    }

    setSpeedFactor( factor: number ): void {
        this.speedFactor = factor;
        if ( this.playing ) {
            this.play();
        }
    }

    play(): void {
        this.animation = new TimeDimensionPlayerAnimation( this, this.fraction, this._durationTimeMillis, this.speedFactor, this._layerSupplier );
        this.animation.update( 0 );

        this.updateAnimationPlaying( true )
        AnimationManager.putAnimation( ANIMATION_KEY, this.animation, false )
            .catch( () => {
            } );
    }

    pause(): void {
        AnimationManager.removeAnimation( ANIMATION_KEY );
        this.updateAnimationPlaying( false );
    }

    stop(): void {
        AnimationManager.removeAnimation( ANIMATION_KEY );
        this.updateAnimationPlaying( false );
        this.move( 0 );
    }

    updateAnimationState( newFraction: number ): void {
        this.fraction = newFraction;
        this.updateAnimationPlaying( this.playing );
    }

    private updateAnimationPlaying( playing: boolean ): void {
        this.playing = playing;
        this.emitPlayerEvent();
    }

    private emitPlayerEvent(): void {
        const info: IPlayerTimeInfo = {
            playing: this.playing,
            fraction: this.fraction,
        };
        this.emit( PLAYER_TIME_EVENT, info );
    }
}
