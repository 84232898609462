import React, {useEffect, useRef, useState} from "react"
import {WebGLMap} from "@luciad/ria/view/WebGLMap"
import {Box} from "@digitalreality/ui";
import {getReference} from "@luciad/ria/reference/ReferenceProvider";
import {MapContext} from "../common/hooks/useMapContext";

interface Props {
    mapReference?: string
    onMapReady?: ( map: WebGLMap ) => void
    children?: React.ReactNode
}

export const RiaMap = ( {mapReference, onMapReady, children}: Props ) => {
    const [map, setMap] = useState<WebGLMap | null>( null )
    const mapRef = useRef<HTMLDivElement>( null );

    useEffect( () => {
        const webGLMap = new WebGLMap( mapRef.current!, {
            reference: getReference( mapReference || 'EPSG:4978' ),
            globeColor: 'rgb(0,0,0)',
        } );

        webGLMap.effects.antiAliasing = true;

        webGLMap.maxMemoryUsageHint = {cpuMB: 1000, gpuMB: 2500};

        setMap( webGLMap )
        if ( onMapReady ) {
            onMapReady( webGLMap )
        }

        return () => {
            console.debug( "Destroying", webGLMap )
            webGLMap.destroy()
        }
    }, [] )


    return (
        <Box sx={{position: "relative", width: "100%", height: "100%", zIndex: 0}}>
            <Box sx={{width: "100%", height: "100%"}}
                 ref={mapRef}
                 onClick={focus}    // Request focus on click to the map. This makes sure that we get keyboard events otherwise they sometimes don't make it through the iframe.
                 className={"luciad"}/>

            {map && <MapContext.Provider value={{map: map}}> {children} </MapContext.Provider>}
        </Box>
    )
}
