import {WMSTileSetModel} from "@luciad/ria/model/tileset/WMSTileSetModel";
import {WMSTileSetLayer} from "@luciad/ria/view/tileset/WMSTileSetLayer";
import {WMSCapabilities} from "@luciad/ria/model/capabilities/WMSCapabilities";
import {ISimulationLayer} from "./ISimulationLayer";
import {Bounds} from "@luciad/ria/shape/Bounds";
import {createFrom2DSimulation, TimeDimensions} from "../model/TimeDimensions";

export class SimulationLayer2D extends WMSTileSetLayer implements ISimulationLayer {

    private readonly _bounds: Bounds;
    private readonly _timeDimensions: TimeDimensions;
    private _currentDate: Date;

    constructor( model: WMSTileSetModel, capabilities: WMSCapabilities ) {
        super( model );
        this._bounds = model.bounds!;
        this._timeDimensions = createFrom2DSimulation( capabilities );
        this._currentDate = this._timeDimensions.startDate;

        this.applyDate( this._currentDate );
    }

    get bounds(): Bounds {
        return this._bounds;
    }

    get timeDimensions(): TimeDimensions {
        return this._timeDimensions;
    }

    get currentDate(): Date {
        return this._currentDate;
    }

    applyDate( newDate: Date ): void {
        this.model.dimensions = {TIME: newDate.toISOString()}
        this._currentDate = newDate;
    }
}