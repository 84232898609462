import {Feature} from "@luciad/ria/model/feature/Feature";
import {FeaturePainter} from "@luciad/ria/view/feature/FeaturePainter";
import {GeoCanvas} from "@luciad/ria/view/style/GeoCanvas";
import {LabelCanvas} from "@luciad/ria/view/style/LabelCanvas";
import {Measurement} from "../ruler3d/measurement/Measurement";
import {PAINT_STYLES} from "../../measurements/MeasurementPainter";
import {Shape} from "@luciad/ria/shape/Shape";
import {DEFAULT_POINT_STYLE} from "./CartesianRulerController";

/**
 * Painter used to draw cross-section measurements on the main map.
 */
export class CrossSectionMeasurementPainter extends FeaturePainter {

  paintBody(geoCanvas: GeoCanvas, feature: Feature, shape: Shape): void {
    if (!!feature.properties.measurement) {
      const measurement = feature.properties.measurement as Measurement;
      measurement.paintBody(geoCanvas, PAINT_STYLES);
    } else {
      geoCanvas.drawIcon(shape, DEFAULT_POINT_STYLE);
    }
  }

  paintLabel(labelCanvas: LabelCanvas, feature: Feature): void {
    if (!!feature.properties.measurement) {
      const measurement = feature.properties.measurement as Measurement;
      measurement.paintLabel(labelCanvas, PAINT_STYLES);
    }
  }
}