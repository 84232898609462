import {Map} from "@luciad/ria/view/Map";
import {FeatureLayer} from "@luciad/ria/view/feature/FeatureLayer";
import {createTransformation} from "@luciad/ria/transformation/TransformationFactory";
import {Feature} from "@luciad/ria/model/feature/Feature";
import {MemoryStore} from "@luciad/ria/model/store/MemoryStore";
import {FeatureModel} from "@luciad/ria/model/feature/FeatureModel";
import {BasicFeaturePainter} from "@luciad/ria/view/feature/BasicFeaturePainter";
import {ShapeType} from "@luciad/ria/shape/ShapeType";
import {PerspectiveCamera} from "@luciad/ria/view/camera/PerspectiveCamera";
import {createPoint, createPolygon, createShapeList} from "@luciad/ria/shape/ShapeFactory";
import {ShapeStyle} from "@luciad/ria/view/style/ShapeStyle";
import {IconStyle} from "@luciad/ria/view/style/IconStyle";
import {getReference} from "@luciad/ria/reference/ReferenceProvider";
import {Transformation} from "@luciad/ria/transformation/Transformation";
import {Polygon} from "@luciad/ria/shape/Polygon";
import {Point} from "@luciad/ria/shape/Point";
import {add, normalize, scale, sub, toPoint} from "../../common/util/Vector3Util";

export const createCameraLocationLayer = ( cameraMap: Map ): FeatureLayer => {
    const toLLH = createTransformation( cameraMap.reference, LLH_REF );
    const cameraFeature = new Feature( createCameraFOVPolygon( cameraMap, toLLH ), {}, 1 );
    const store = new MemoryStore( {data: [cameraFeature]} );
    const model = new FeatureModel( store, {reference: LLH_REF} );
    const painter = new BasicFeaturePainter();
    painter.setStyle( ShapeType.POLYGON, {selected: false}, CAMERA_FOV_STYLE );
    const cameraIconStyle = {...CAMERA_ICON_STYLE};
    painter.setStyle( ShapeType.POINT, {selected: false}, cameraIconStyle );
    const layer = new FeatureLayer( model, {painter} );
    syncCameraIconWithMainMap();

    cameraMap.on( "MapChange", () => {
        syncCameraIconWithMainMap();
    } );

    function syncCameraIconWithMainMap() {
        const cameraFOVPolygon = createCameraFOVPolygon( cameraMap, toLLH );
        if ( cameraMap.camera instanceof PerspectiveCamera ) {
            const camera = cameraMap.camera as PerspectiveCamera;
            cameraIconStyle.heading = camera.asLookFrom().yaw;
        }
        // @ts-ignore
        cameraFeature.shape = createShapeList( LLH_REF, [
            cameraFOVPolygon.getPoint( 0 ), // painted as oriented icon
            cameraFOVPolygon // painted with polygon style
        ] );
        store.put( cameraFeature );
    }
    return layer;
};


const CAMERA_POLY_SIZE = 20; // meters
const CAMERA_FILL_COLOR = "rgba(80,255,90,0.5)";

const CAMERA_STROKE_COLOR = "rgba(80,255,90, 0.8)";

const CAMERA_FOV_STYLE: ShapeStyle = {
    fill: {
        color: CAMERA_FILL_COLOR
    },
    stroke: {
        color: CAMERA_STROKE_COLOR
    }
};

const createCameraIcon = ( width: number, height: number, strokeColor: string, strokeWidth: number, fillColor: string ): HTMLCanvasElement => {
    const canvas = document.createElement( 'canvas' );
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext( '2d' )!;
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.fillRect( 0, 0, canvas.width, canvas.height );

    ctx.fillStyle = fillColor;
    ctx.strokeStyle = strokeColor;
    ctx.lineWidth = strokeWidth;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";

    const padding = strokeWidth;
    const paddedWidth = canvas.width - 2 * padding;
    const paddedHeight = canvas.height - 2 * padding;
    const boxWidth = paddedWidth * 0.6;
    const lensWidth = paddedWidth * 0.4;
    // camera "box"
    ctx.beginPath();
    ctx.rect( padding, padding, boxWidth, paddedHeight );
    ctx.fill();
    ctx.stroke();

    // camera "lens"
    ctx.beginPath();
    ctx.moveTo( canvas.width - (padding + lensWidth), canvas.height / 2 );
    ctx.lineTo( canvas.width - padding, padding );
    ctx.lineTo( canvas.width - padding, canvas.height - padding );
    ctx.closePath();
    ctx.fill();
    ctx.stroke();

    return canvas;
}
const CAMERA_ICON_WIDTH = 24;
const CAMERA_ICON_HEIGHT = 12;

const CAMERA_ICON_STYLE: IconStyle = {
    image: createCameraIcon( CAMERA_ICON_WIDTH, CAMERA_ICON_HEIGHT, CAMERA_STROKE_COLOR, 2, CAMERA_FILL_COLOR ),
    anchorX: CAMERA_ICON_WIDTH + "px",
    anchorY: (CAMERA_ICON_HEIGHT / 2) + "px",
    heading: 0,
    rotation: -90
}

const LLH_REF = getReference( "CRS:84" );

const createCameraFOVPolygon = ( map: Map, toLLH: Transformation ): Polygon => {
    let points: Point[] = [];
    if ( map.camera instanceof PerspectiveCamera ) {
        const leftViewPoint = createPoint( null, [0, map.camera.height / 2, 0] );
        const rightViewPoint = createPoint( null, [map.camera.width, map.camera.height / 2, 0] );
        const leftWorldPoint = map.camera.toWorldPoint( leftViewPoint );
        const rightWorldPoint = map.camera.toWorldPoint( rightViewPoint );

        // scale the size of the poly back down so it's not extremely big
        const scaledLeftWorldPoint = toPoint( map.reference,
                                              add( map.camera.eyePoint, scale( normalize( sub( leftWorldPoint, map.camera.eyePoint ) ), CAMERA_POLY_SIZE ) ) );
        const scaledRightWorldPoint = toPoint( map.reference,
                                               add( map.camera.eyePoint, scale( normalize( sub( rightWorldPoint, map.camera.eyePoint ) ), CAMERA_POLY_SIZE ) ) );

        points = [map.camera.eyePoint, scaledLeftWorldPoint, scaledRightWorldPoint].map( p => toLLH.transform( p ) );
    }
    return createPolygon( LLH_REF, points );
};
