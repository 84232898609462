import React, {ReactNode} from "react";
import {Color, ColorPicker, useTranslate} from "material-ui-color";
import {Inline} from "@digitalreality/ui";
import {Stack, TextField, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "./ColorMapPicker.css";

export interface ColorMapEntry {
    value: number,
    color: string
}

interface ColorMapPickerProps {
    title: string,
    colorMapEntries: ColorMapEntry[],
    setColorMapEntries: ( entries: ColorMapEntry[] ) => void,
}

function isValidEntry( i: number, colorMapEntries: ColorMapEntry[] ) {
    return isValidValue( i, colorMapEntries ) && isValidColor( i, colorMapEntries );
}

export function isValidValue( index: number, colorMapEntries: ColorMapEntry[] ) {
    const value = colorMapEntries[index].value;
    if ( isNaN( value ) ) {
        return false;
    }
    if ( index === 0 ) {
        return true;
    }

    const previousValue = colorMapEntries[index - 1].value;
    return isNaN( previousValue ) || previousValue < value;
}

export function isValidColor( index: number, colorMapEntries: ColorMapEntry[] ) {
    const color = colorMapEntries[index].color;
    if ( index === 0 ) {
        return true;
    }

    const previousColor = colorMapEntries[index - 1].color;
    return previousColor !== color;
}

export function ColorMapPicker( {title, colorMapEntries, setColorMapEntries}: ColorMapPickerProps ) {

    useTranslate( () => ({i18n: {language: 'esEs'}, t: value => value === "Set" ? "Aceptar" : value}) );

    function createColorMapEntry() {
        setColorMapEntries( [...colorMapEntries, {color: "#ffffff", value: 0}] );
    }

    function updateColor( index: number, color: Color ): void {
        const newEntries = [...colorMapEntries];
        newEntries[index].color = "#" + color.hex;
        setColorMapEntries( newEntries );
    }

    function updateValue( index: number, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> ) {
        const newEntries = [...colorMapEntries];
        newEntries[index].value = Number( event.target.value );
        setColorMapEntries( newEntries );
    }

    function deleteEntry( index: number ) {
        const newEntries = [...colorMapEntries];
        newEntries.splice( index, 1 );
        setColorMapEntries( newEntries );
    }

    return <Stack gap="12px" sx={{width: "325px", height: "425px", paddingTop: "8px"}}>
        <Inline gap={"8px"}>
            <Typography>{title}</Typography>
            <AddCircleIcon sx={{"& :hover": {color: "#5098A1"}}} onClick={createColorMapEntry}/>
        </Inline>
        <div style={{overflow: "auto"}}>
            {colorMapEntries.map( ( entry, i ) =>
                                      <Inline key={i}>
                                          <ColorPicker key={i} value={entry.color}
                                                       onChange={color => updateColor( i, color )} hideTextfield
                                                       deferred/>
                                          <TextField type={"text"} value={entry.value} variant={"standard"}
                                                     error={!isValidEntry( i, colorMapEntries )}
                                                     onChange={event => updateValue( i, event )}/>
                                          <DeleteIcon sx={{"& :hover": {color: "#5098A1"}}}
                                                      onClick={() => deleteEntry( i )}/>
                                      </Inline> )
            }
        </div>
    </Stack>
}