import {FeaturePainter, PaintState} from "@luciad/ria/view/feature/FeaturePainter";
import {GeoCanvas} from "@luciad/ria/view/style/GeoCanvas";
import {Feature} from "@luciad/ria/model/feature/Feature";
import {Shape} from "@luciad/ria/shape/Shape";
import {Layer} from "@luciad/ria/view/Layer";
import {Map} from "@luciad/ria/view/Map";
import {IconStyle, UrlIconStyle} from "@luciad/ria/view/style/IconStyle";
import {LabelCanvas} from "@luciad/ria/view/style/LabelCanvas";
import "./UpwellingCampaignSensorsPainter.css"
import {PointLabelPosition} from "@luciad/ria/view/style/PointLabelPosition";
import {clusteredFeatures, isCluster} from "@luciad/ria/view/feature/transformation/ClusteringTransformer.js";
import {IconFactory} from "../../../common/IconFactory";
import {DrapeTarget} from "@luciad/ria/view/style/DrapeTarget";

export class UpwellingCampaignSensorsPainter extends FeaturePainter {
    private readonly featureStyle: UrlIconStyle;
    private readonly clusterStyle: IconStyle;

    constructor() {
        super();
        this.featureStyle = {
            url: "/resources_ria/sensors/sensor.png",
            width: "32px",
            height: "32px",
            drapeTarget: DrapeTarget.NOT_DRAPED
        };
        this.clusterStyle = {
            image: IconFactory.circle( {
                                           fill: "rgb(0,0,0)",
                                           stroke: "rgb(255,255,255)",
                                           strokeWidth: 3,
                                           width: 43,
                                           height: 43,
                                       } ), width: "43px", height: "43px"
        };
    }

    getDetailLevelScales(): number[] {
        return [1 / 10_000];
    }

    paintBody( aGeoCanvas: GeoCanvas, aObject: Feature, aShape: Shape, aLayer: Layer, aMap: Map, aPaintState: PaintState ) {
        if ( isCluster( aObject ) ) {
            aGeoCanvas.drawIcon( aShape, this.clusterStyle );
        }
        else {
            aGeoCanvas.drawIcon( aShape, this.featureStyle );
        }
    }

    paintLabel( labelCanvas: LabelCanvas, feature: Feature, shape: Shape, layer: Layer, map: Map, paintState: PaintState ) {
        if ( isCluster( feature ) ) {
            const numberOfFeatures = clusteredFeatures( feature ).length;
            labelCanvas.drawLabel( String( numberOfFeatures ), shape, {positions: PointLabelPosition.CENTER} )
            return;
        }

        if ( paintState.level !== 1 ) {
            return
        }
        const html = "<div class='upwelling-sensors-label'>" +
                     "<a>Código: " + feature.properties["code"] + "</a></br>" +
                     "<a>Cota nivel msnm: " + feature.properties["level"] + " m</a></br>" +
                     "<a>Profundidad: " + feature.properties["deepness"] + " m</a></br>" +
                     "<a>Niveles msnm: " + feature.properties["level"] + " m</a></br>" +
                     "<a>Comment: " + feature.properties["comment"] + "</a></br>" +
                     "</div>";
        labelCanvas.drawLabel( html, shape, {offset: 30, positions: PointLabelPosition.NORTH} )
    }
}
