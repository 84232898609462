import JSZip from "jszip";

export interface SimulationToUpload {
    file: File,
    name?: string,
    type: "Velocidad" | "Calado" | "WSE",
    startDate?: Date,
    endDate?: Date,
    intervalMinutes: number,
    numberOfFiles: number
}

const IBER_PATTERN = new RegExp( ".*(?<type>Depth|Velocity)____(?<time>[0-9]+)_.*.tif" );
const HECRAS_PATTERN = new RegExp( "(?<type>Depth|Speed|WSE) " +
                                   "\\((?<day>[0-9]{2})(?<month>[A-Z]{3})(?<year>[0-9]{4})" +
                                   " (?<hour>[0-9]{2}) (?<minute>[0-9]{2}) (?<seconds>[0-9]{2})\\)" +
                                   "\\.(?<name>.*)\\.tif" );

export class SimulationParser {

    parse( files: FileList ): Promise<SimulationToUpload> {
        if ( files.length != 1 ) {
            throw new Error( "Solo se puede subir una simulación  a la vez" );
        }

        const file = files[0];
        if ( !file.name.endsWith( ".zip" ) ) {
            throw new Error( "El archivo debe ser un zip" );
        }

        return JSZip.loadAsync( file )
            .then( zip => {
                const fileNames = [] as string[];
                for ( let filesKey in zip.files ) {
                    const name = zip.files[filesKey].name;
                    fileNames.push( name )
                }
                return fileNames;
            } )
            .then( fileNames => {
                if ( this.allMatchIber( fileNames ) ) {
                    const startDateEpoch = this.getIberStartDate( fileNames ).getTime();
                    const endDateEpoch = this.getIberEndDate( fileNames ).getTime();
                    const elapsedTime = endDateEpoch - startDateEpoch;
                    const intervalMillis = elapsedTime / (fileNames.length - 1);

                    return {
                        file: file,
                        type: this.getType( IBER_PATTERN.exec( fileNames[0] )!.groups!["type"] ),
                        intervalMinutes: Math.round( intervalMillis / 1000 / 60 ),
                        numberOfFiles: fileNames.length
                    };
                }
                if ( this.allMatchHecras( fileNames ) ) {
                    const startDate = this.getHecrasStartDate( fileNames );
                    const endDate = this.getHecrasEndDate( fileNames );
                    const elapsedTime = endDate.getTime() - startDate.getTime();
                    const intervalMillis = elapsedTime / (fileNames.length - 1);

                    const groups = HECRAS_PATTERN.exec( fileNames[0] )!.groups!;
                    return {
                        file: file,
                        name: groups["name"],
                        type: this.getType( groups["type"] ),
                        startDate: startDate,
                        endDate: endDate,
                        intervalMinutes: Math.round( intervalMillis / 1000 / 60 ),
                        numberOfFiles: fileNames.length
                    };
                }
                throw new Error( "Todos los archivos tienen que seguir la nomenclatura de IBER o HECRAS" );
            } )
    }

    private allMatchIber( fileNames: string[] ) {
        for ( const fileName of fileNames ) {
            if ( !IBER_PATTERN.test( fileName ) ) {
                return false;
            }
        }
        return true;
    }

    private getIberStartDate( iberFileNames: string[] ): Date {
        const epochs = iberFileNames.map( fileName => this.getIberDate( fileName ) * 1000 );
        return new Date( Math.min( ...epochs ) );
    }

    private getIberEndDate( iberFileNames: string[] ): Date {
        const epochs = iberFileNames.map( fileName => this.getIberDate( fileName ) * 1000 );
        return new Date( Math.max( ...epochs ) );
    }

    private getIberDate( iberFileName: string ): number {
        const groups = IBER_PATTERN.exec( iberFileName )!.groups!;
        return +groups["time"];
    }

    private allMatchHecras( fileNames: string[] ) {
        for ( const fileName of fileNames ) {
            if ( !HECRAS_PATTERN.test( fileName ) ) {
                return false;
            }
        }
        return true;
    }

    private getHecrasStartDate( hecrasFileNames: string[] ): Date {
        const epochs = hecrasFileNames.map( fileName => this.getHecrasDate( fileName ) ).map( date => date.getTime() );
        return new Date( Math.min( ...epochs ) );
    }

    private getHecrasEndDate( hecrasFileNames: string[] ): Date {
        const epochs = hecrasFileNames.map( fileName => this.getHecrasDate( fileName ) ).map( date => date.getTime() );
        return new Date( Math.max( ...epochs ) );
    }

    private getHecrasDate( hecrasFileName: string ): Date {
        const regexResult = HECRAS_PATTERN.exec( hecrasFileName );
        const groups = regexResult!.groups!;

        const hour = groups["hour"];
        const minute = groups["minute"];
        const seconds = groups["seconds"];
        const day = groups["day"];
        const month = groups["month"];
        const year = groups["year"];

        const date = new Date();
        date.setHours( +hour, +minute, +seconds );
        date.setDate( +day )
        date.setMonth( this.getMonth( month ) )
        date.setFullYear( +year );

        return date;
    }

    private getMonth( month: string ): number {
        switch ( month ) {
            case "ENE":
                return 0;
            case "FEB":
                return 1;
            case "MAR":
                return 2;
            case "ABR":
                return 3;
            case "MAY":
                return 4;
            case "JUN":
                return 5;
            case "JUL":
                return 6;
            case "AGO":
                return 7;
            case "SEP":
                return 8;
            case "OCT":
                return 9;
            case "NOV":
                return 10;
            case "DIC":
                return 11;
            default:
                throw new Error( "Mes desconocido: " + month );
        }
    }

    private getType( simulationType: string ): "Calado" | "Velocidad" | "WSE" {
        switch ( simulationType ) {
            case "Depth":
                return "Calado";
            case "Velocity":
            case "Speed":
                return "Velocidad";
            case "WSE":
                return "WSE"
            default:
                throw new Error( "Tipo de simulación desconocido: " + simulationType )
        }
    }
}
