import React, {PropsWithChildren, ReactElement, useEffect, useRef, useState} from "react";
import {LayerGroup} from "@luciad/ria/view/LayerGroup";
import {Map} from "@luciad/ria/view/Map";
import {LayerConstructorOptions} from "@luciad/ria/view/LayerTreeNode";
import {useMapContext} from "../common/hooks/useMapContext";
import {
    FourBandsWMSLayerGroupWithOptions,
    LayerGroupWithOptions,
    LayerOptions,
    RasterLayerGroupWithOptions
} from "./LayerOptions";
import {OwnedLayerOptions} from "./CartagenaLayers";
import {
    createLayerLoadedEvent,
    LOAD_LAYER_REQUEST_EVENT_TYPE,
    LoadLayerRequestEventDetail
} from "./LoadLayerEventFactory";

export enum LayerGroupType {
    DEFAULT,
    RASTER,
    FOUR_BANDS
}

interface LayerLoadedStatus {
    layerURL: string,
    successful: boolean,
    error?: Error
}

type LayerGroupProperties = {
    loaded?: boolean
} & LayerOptions;

/**
 * A layer group node that is added to the layer tree.
 * If the layer group is not lazy, it will immediately load and add its children.
 * If it is not, only the layer group node is added. The layer tree will render it with a  + button.
 * When this button is clicked a LOAD_LAYER_REQUEST_EVENT_TYPE is dispatched.
 * Then the group will load and add all its children, once they are loaded it will dispatch a
 * LAYER_LOADED_EVENT_TYPE event.
 */
export const Group = ( props: PropsWithChildren<{
    map?: Map, // If not provided the map context will be used
    type?: LayerGroupType,
    parent?: LayerGroup
    onLoad?: ( group: LayerGroup ) => void
    onUnload?: () => void,
    ordered?: boolean // All layers must have a defined ID as string for this to work
}> & LayerConstructorOptions & LayerOptions ) => {
    const thisGroupRef = useRef<LayerGroup & LayerGroupProperties>( createLayerGroupWithOptions( props ) );
    thisGroupRef.current.hideVisibilityToggle = props.hideVisibilityToggle;
    thisGroupRef.current.hideInLayerTree = props.hideInLayerTree;
    thisGroupRef.current.hideFit = props.hideFit;
    thisGroupRef.current.lazy = props.lazy;
    thisGroupRef.current.loaded = !props.lazy;

    const map = props.map ?? useMapContext().map;
    const owner = props.parent || map.layerTree;

    const [loadRequested, setLoadRequested] = useState<boolean>( false )
    const layersLoadedStatus = useRef<LayerLoadedStatus[]>( [] )

    const numberChildren = React.Children.count( props.children );

    useEffect( () => {
        owner.addChild( thisGroupRef.current );
        return () => {
            owner.removeChild( thisGroupRef.current )
            props.onUnload?.();
        }
    }, [] )

    useEffect( () => {
        if ( !props.lazy ) {
            return;
        }

        function listenToLoadRequests( event: Event ) {
            const detail = (event as CustomEvent).detail as LoadLayerRequestEventDetail;
            if ( detail.layerId === thisGroupRef.current.id ) {
                setLoadRequested( true );
                removeEventListener( LOAD_LAYER_REQUEST_EVENT_TYPE, listenToLoadRequests );
            }
        }

        addEventListener( LOAD_LAYER_REQUEST_EVENT_TYPE, listenToLoadRequests );
    }, [] )

    function onLoad() {
        const layerGroup = thisGroupRef.current;
        if ( props.ordered ) {
            orderLayers( layerGroup );
        }

        const failed = layersLoadedStatus.current.filter( status => !status.successful ).length;
        dispatchEvent( createLayerLoadedEvent( layerGroup.id, failed === 0 ) );
        props.onLoad?.( layerGroup );
        thisGroupRef.current.loaded = true;
    }

    function orderLayers( layerGroup: LayerGroup ) {
        const expectedChildrenIDsOrder = React.Children.map( props.children, child => {
            if ( React.isValidElement( child ) ) {
                return child.props.id as string;
            }
        } ) ?? [];
        for ( let i = expectedChildrenIDsOrder.length; i >= 0; i-- ) {
            const layer = layerGroup.findLayerById( expectedChildrenIDsOrder[i] );
            if ( layer ) {
                layerGroup.moveChild( layer, "top" )
            }
        }
    }

    function onChildLoaded( url: string ): void {
        layersLoadedStatus.current.push( {layerURL: url, successful: true} );
        if ( layersLoadedStatus.current.length === numberChildren ) {
            onLoad();
        }
    }

    function onChildFailedLoad( url: string ): void {
        layersLoadedStatus.current.push( {layerURL: url, successful: false} );
        if ( layersLoadedStatus.current.length === numberChildren ) {
            onLoad();
        }
    }

    return (!props.lazy || loadRequested) ? <>{React.Children.map( props.children, child => {
        if ( React.isValidElement( child ) ) {
            const ownedLayer = child as OwnedLayerOptions & ReactElement;
            const childProps = {
                parent: thisGroupRef.current,
                onLoad: ( url: string ) => {
                    ownedLayer.props.onLoad?.( url );
                    onChildLoaded( url );
                },
                onFailedLoad: ( url: string ) => {
                    ownedLayer.props.onFailedLoad?.( url );
                    onChildFailedLoad( url );
                },
            };
            return React.cloneElement( ownedLayer, childProps )
        }

        return child
    } )}</> : <></>
}

function createLayerGroupWithOptions( props: PropsWithChildren<{
    type?: LayerGroupType,
    parent?: LayerGroup
}> & LayerConstructorOptions & LayerOptions ): LayerGroup & LayerOptions {
    switch ( props.type ) {
        case LayerGroupType.RASTER:
            return new RasterLayerGroupWithOptions( props );
        case LayerGroupType.FOUR_BANDS:
            return new FourBandsWMSLayerGroupWithOptions( props );
        case LayerGroupType.DEFAULT:
        default:
            return new LayerGroupWithOptions( props );
    }
}
