import {Field, FormModal} from '@digitalreality/ui';
import React, {KeyboardEvent, useState} from 'react';
import {createNewMeasurementId} from './MeasurementPersistenceUtil';

interface Props {
    onSave: ( id: string, name: string ) => void;
    onCancel: () => void;
}

/**
 * Modal component to confirm or cancel the creation of a measurement
 */
export const MeasurementConfirmationModal = ( {onSave, onCancel}: Props ) => {
    const [name, setName] = useState( '' );

    function handleSubmit() {
        if ( name ) {
            const id = createNewMeasurementId();
            onSave( id, name );
        }
    }

    function consumeEvent( event: KeyboardEvent<HTMLInputElement> ) {
        event.stopPropagation();
    }

    return (
        <div onKeyUp={consumeEvent} onKeyDown={consumeEvent}>
            <FormModal
                title="Guardar medición"
                open={true}
                onSubmit={handleSubmit}
                onCancel={onCancel}
                loading={false}
                cancelButtonText={"Cancelar"}
                submitButtonText={"Aceptar"}
            >
                <Field
                    id="measurement-name"
                    label="Nombre de la medición"
                    required
                    value={name}
                    onChange={( e ) => setName( e.target.value )}
                />
            </FormModal>
        </div>
    );
};
