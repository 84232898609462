import PublicIcon from "@mui/icons-material/Public";
import MapIcon from "@mui/icons-material/Map";
import {IconButton} from "@mui/material";
import React from "react";
import {useControllerContext} from "./ControllerContext";
import {Tooltip} from "@digitalreality/ui";


export const ThreeDButton = () => {
    const [controllerContext, setControllerContext] = useControllerContext();

    return <Tooltip title={`Cambiar a ${controllerContext.threeD ? '2D' : '3D'}`} placement={"left"}>
        <IconButton onClick={() => setControllerContext( ctx => ({
            ...ctx,
            threeD: !ctx.threeD
        }) )}>
            {controllerContext.threeD ? <PublicIcon fontSize={"large"}/> : <MapIcon fontSize={"large"}/>}
        </IconButton>
    </Tooltip>

}
