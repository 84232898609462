import React, {useEffect, useRef, useState} from 'react';
import {Absolute, Box, CssBaseline, Inline, Relative, Stack, ThemeProvider,} from '@digitalreality/ui';
import {WebGLMap} from '@luciad/ria/view/WebGLMap';
import {addReference, isValidReferenceIdentifier, parseWellKnownText} from '@luciad/ria/reference/ReferenceProvider';
import {Toolbar} from './components/Toolbar';
import {ControllerContext, useControllerState} from "./navigation/ControllerContext";
import {ThreeDMap} from "./map/ThreeDMap";
import {SimulationContext, useSimulationState} from "./simulation/SimulationContext";
import {DEFAULT_SETTINGS, ISettings, Settings} from './settings/Settings';
import {SimulationPlayer} from "./simulation/SimulationPlayer";

/**
 * Root level component of the LuciadRIA app template.
 */
export const App = () => {
    const mapRef = useRef<HTMLDivElement>( null );
    const [mainMap, setMainMap] = useState<WebGLMap | undefined>( undefined );
    const settings = useState<ISettings>( DEFAULT_SETTINGS );
    const [controllerContext, setControllerContext] = useControllerState( mainMap )
    const [simulationContext, setSimulationContext] = useSimulationState()

    useEffect( () => {
        addNeededCRS();
    }, [] );

    return (
        <ThemeProvider>
            <Settings.Provider value={settings}>
                <ControllerContext.Provider value={[controllerContext, setControllerContext]}>
                    <SimulationContext.Provider value={[simulationContext, setSimulationContext]}>
                        <CssBaseline/>
                        <Inline alignItems="center" gap={0}>
                            <Toolbar map={mainMap}/>
                            <Stack width="100%" height="100vh">
                                <Relative width="100%" height="100vh">
                                    <Box width="100%" height="100%" id="map" ref={mapRef}>
                                        <ThreeDMap onMapReady={setMainMap}/>
                                    </Box>
                                    <Absolute right={"s"} bottom={"s"} sx={{pointerEvents: 'none'}}>
                                        <img src={"/resources_ria/HEXAGON_WHITE_REVERSED_RGB_LOGO_156.png"}
                                             alt={"Hexagon"}/>
                                    </Absolute>
                                </Relative>
                                {mainMap && simulationContext &&
                                 <SimulationPlayer simulationContext={simulationContext} map={mainMap}/>}
                            </Stack>
                        </Inline>
                    </SimulationContext.Provider>
                </ControllerContext.Provider>
            </Settings.Provider>
        </ThemeProvider>
    );
};

function addNeededCRS() {
    const EPSG_25830_WKT = "PROJCS[\"ETRS89 / UTM zone 30N\",\n" +
                           "    GEOGCS[\"ETRS89\",\n" +
                           "        DATUM[\"European_Terrestrial_Reference_System_1989\",\n" +
                           "            SPHEROID[\"GRS 1980\",6378137,298.257222101,\n" +
                           "                AUTHORITY[\"EPSG\",\"7019\"]],\n" +
                           "            TOWGS84[0,0,0,0,0,0,0],\n" +
                           "            AUTHORITY[\"EPSG\",\"6258\"]],\n" +
                           "        PRIMEM[\"Greenwich\",0,\n" +
                           "            AUTHORITY[\"EPSG\",\"8901\"]],\n" +
                           "        UNIT[\"degree\",0.0174532925199433,\n" +
                           "            AUTHORITY[\"EPSG\",\"9122\"]],\n" +
                           "        AUTHORITY[\"EPSG\",\"4258\"]],\n" +
                           "    PROJECTION[\"Transverse_Mercator\"],\n" +
                           "    PARAMETER[\"latitude_of_origin\",0],\n" +
                           "    PARAMETER[\"central_meridian\",-3],\n" +
                           "    PARAMETER[\"scale_factor\",0.9996],\n" +
                           "    PARAMETER[\"false_easting\",500000],\n" +
                           "    PARAMETER[\"false_northing\",0],\n" +
                           "    UNIT[\"metre\",1,\n" +
                           "        AUTHORITY[\"EPSG\",\"9001\"]],\n" +
                           "    AXIS[\"Easting\",EAST],\n" +
                           "    AXIS[\"Northing\",NORTH],\n" +
                           "    AUTHORITY[\"EPSG\",\"25830\"]]";
    const EPSG_25830_reference = parseWellKnownText( EPSG_25830_WKT );
    if ( EPSG_25830_reference && EPSG_25830_reference.identifier ) {
        if ( !isValidReferenceIdentifier( EPSG_25830_reference.identifier ) ) {
            addReference( EPSG_25830_reference );
        }
    }

    const EPSG_3042_WKT = "PROJCS[\"ETRS89 / UTM zone 30N (N-E)\",GEOGCS[\"ETRS89\",DATUM[\"European Terrestrial Reference System 1989\",SPHEROID[\"GRS 1980\",6378137.0,298.257222101],TOWGS84[0.0,0.0,0.0]],PRIMEM[\"Greenwich\",0.0],UNIT[\"degree\",0.017453292519943295],AXIS[\"Geodetic latitude\",NORTH],AXIS[\"Geodetic longitude\",EAST],AUTHORITY[\"EPSG\",4258]],PROJECTION[\"Transverse Mercator\"],PARAMETER[\"Latitude of natural origin\",0.0],PARAMETER[\"central_meridian\",-3.0],PARAMETER[\"Scale factor at natural origin\",0.9996],PARAMETER[\"False easting\",500000.0],PARAMETER[\"False northing\",0.0],UNIT[\"Meter\",1.0],AXIS[\"Northing\",NORTH],AXIS[\"Easting\",EAST],AUTHORITY[\"EPSG\",3042]]";
    const EPSG_3042_reference = parseWellKnownText( EPSG_3042_WKT );
    if ( EPSG_3042_reference && EPSG_3042_reference.identifier ) {
        if ( !isValidReferenceIdentifier( EPSG_3042_reference.identifier ) ) {
            addReference( EPSG_3042_reference );
        }
    }

    const EPSG_4258_WKT = "GEOGCS[\"ETRS89\",DATUM[\"European Terrestrial Reference System 1989\",SPHEROID[\"GRS 1980\",6378137.0,298.257222101],TOWGS84[0.0,0.0,0.0]],PRIMEM[\"Greenwich\",0.0],UNIT[\"degree\",0.017453292519943295],AXIS[\"Geodetic latitude\",NORTH],AXIS[\"Geodetic longitude\",EAST],AUTHORITY[\"EPSG\",4258]]";
    const EPSG_4258_reference = parseWellKnownText( EPSG_4258_WKT );
    if ( EPSG_4258_reference && EPSG_4258_reference.identifier ) {
        if ( !isValidReferenceIdentifier( EPSG_4258_reference.identifier ) ) {
            addReference( EPSG_4258_reference );
        }
    }

    const EPSG_32630_WKT = "PROJCS[\"WGS 84 / UTM zone 30N\",GEOGCS[\"WGS 84\",DATUM[\"WGS_1984\",SPHEROID[\"WGS 84\",6378137,298.257223563,AUTHORITY[\"EPSG\",\"7030\"]],AUTHORITY[\"EPSG\",\"6326\"]],PRIMEM[\"Greenwich\",0,AUTHORITY[\"EPSG\",\"8901\"]],UNIT[\"degree\",0.0174532925199433,AUTHORITY[\"EPSG\",\"9122\"]],AUTHORITY[\"EPSG\",\"4326\"]],PROJECTION[\"Transverse_Mercator\"],PARAMETER[\"latitude_of_origin\",0],PARAMETER[\"central_meridian\",-3],PARAMETER[\"scale_factor\",0.9996],PARAMETER[\"false_easting\",500000],PARAMETER[\"false_northing\",0],UNIT[\"metre\",1,AUTHORITY[\"EPSG\",\"9001\"]],AXIS[\"Easting\",EAST],AXIS[\"Northing\",NORTH],AUTHORITY[\"EPSG\",\"32630\"]]";
    const EPSG_32630_reference = parseWellKnownText( EPSG_32630_WKT );
    if ( EPSG_32630_reference && EPSG_32630_reference.identifier ) {
        if ( !isValidReferenceIdentifier( EPSG_32630_reference.identifier ) ) {
            addReference( EPSG_32630_reference );
        }
    }

    const EPSG_25832_WKT = "PROJCS[\"ETRS89 / UTM zone 32N\",GEOGCS[\"ETRS89\",DATUM[\"European_Terrestrial_Reference_System_1989\",SPHEROID[\"GRS 1980\",6378137,298.257222101,AUTHORITY[\"EPSG\",\"7019\"]],TOWGS84[0,0,0,0,0,0,0],AUTHORITY[\"EPSG\",\"6258\"]],PRIMEM[\"Greenwich\",0,AUTHORITY[\"EPSG\",\"8901\"]],UNIT[\"degree\",0.0174532925199433,AUTHORITY[\"EPSG\",\"9122\"]],AUTHORITY[\"EPSG\",\"4258\"]],PROJECTION[\"Transverse_Mercator\"],PARAMETER[\"latitude_of_origin\",0],PARAMETER[\"central_meridian\",9],PARAMETER[\"scale_factor\",0.9996],PARAMETER[\"false_easting\",500000],PARAMETER[\"false_northing\",0],UNIT[\"metre\",1,AUTHORITY[\"EPSG\",\"9001\"]],AXIS[\"Easting\",EAST],AXIS[\"Northing\",NORTH],AUTHORITY[\"EPSG\",\"25832\"]]";
    const EPSG_25832_reference = parseWellKnownText( EPSG_25832_WKT );
    if ( EPSG_25832_reference && EPSG_25832_reference.identifier ) {
        if ( !isValidReferenceIdentifier( EPSG_25832_reference.identifier ) ) {
            addReference( EPSG_25832_reference );
        }
    }
}

