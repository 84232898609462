import React, {Dispatch, useEffect, useState} from "react";
import {Map} from "@luciad/ria/view/Map";
import {
    Drawer,
    DrawerActions,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerTitle,
    Grid, Inline,
    SolidToggle,
    SolidToggleGroup, Text,
    useTheme
} from "@digitalreality/ui";
import PublicIcon from "@mui/icons-material/Public";
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import {SettingsIcon} from "@digitalreality/ui-icons";
import CircleIcon from '@mui/icons-material/Circle';
import {CoordinateFormat, DEFAULT_SETTINGS, useSettings} from "./Settings";
import {ALL_CLASSES} from "./PointCloudClasses";
import {ToggleButton} from "@mui/material";


interface Props {
    open: boolean;
    onClose: () => void;
    map: Map;
}

export function SettingsDrawer( {open, onClose, map}: Props ) {
    const theme = useTheme();
    const [settings, setSettings] = useSettings();
    const [coordFormat, setCoordFormat] = useState<string>( CoordinateFormat[settings.coordinateFormat] );

    const pcClasses: { [key: string]: [boolean, Dispatch<boolean>]; } = {}

    for ( let pcClass of ALL_CLASSES ) {
        pcClasses[pcClass.id] = useState<boolean>( DEFAULT_SETTINGS.pointCloudClasses.includes( pcClass.id ) )
    }

    const stateDeps = Object.values( pcClasses ).map( e => e[0] ) as any[];
    stateDeps.push( coordFormat );

    useEffect( () => {
        setSettings( {
                         coordinateFormat: CoordinateFormat[coordFormat as keyof typeof CoordinateFormat],
                         pointCloudClasses: ALL_CLASSES.filter( c => pcClasses[c.id][0] ).map( c => c.id )
                     } );
    }, stateDeps )

    const updateCoordFormat = ( event: any, value: string ) => {
        setCoordFormat( value );
    };


    return <Drawer variant="persistent" open={open} onClose={onClose}>
        <DrawerContent pl={theme.sizes.navbar} width={380}>
            <DrawerHeader>
                <DrawerTitle icon={<SettingsIcon/>}>Ajustes</DrawerTitle>

                <DrawerActions>
                    <DrawerCloseButton tooltipLabel={"Cerrar panel"} onClick={onClose}/>
                </DrawerActions>
            </DrawerHeader>

            <DrawerBody marginLeft="8px" marginTop="8px" marginRight="8px" overflow={"hidden"}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                        <Text variant={"label"}>
                            Formato de coordenadas
                        </Text>
                    </Grid>
                    <Grid item xs={8}>
                        <SolidToggleGroup value={coordFormat}
                                          onChange={updateCoordFormat}
                                          size="small">
                            <SolidToggle value={"LON_LAT"}> <PublicIcon/> Lon / lat </SolidToggle>
                            <SolidToggle value={"UTM"}> <Grid3x3Icon/> UTM</SolidToggle>
                        </SolidToggleGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <Text variant={"label"}>
                            Clasificación de nubes de puntos
                        </Text>
                    </Grid>

                    {
                        ALL_CLASSES.map( c => <Grid key={c.id} item xs={6}>
                            <ToggleButton value={c.id}
                                          selected={pcClasses[c.id][0]}
                                          onChange={() => pcClasses[c.id][1]( !pcClasses[c.id][0] )}
                                          fullWidth={true} sx={{justifyContent: "flex-start"}}>
                                <Inline spacing={1}>
                                    <CircleIcon sx={{color: c.color}}/>
                                    <Text variant={"label"} alignSelf={"center"} alignContent={"center"}
                                          noWrap>{c.name}</Text>
                                </Inline>
                            </ToggleButton>
                        </Grid> )
                    }

                </Grid>
            </DrawerBody>
        </DrawerContent>
    </Drawer>
}
