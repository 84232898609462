import {
  Absolute,
  Drawer,
  DrawerActions,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  Inline,
  Relative,
  Text,
  useTheme,
} from '@digitalreality/ui';
import React, {ComponentProps, useRef, useState} from 'react';
import {Map} from '@luciad/ria/view/Map';
import {CrossSectionOverlay} from './CrossSectionOverlay';
import {CrossSectionIcon, PlayOutlineIcon} from '@digitalreality/ui-icons';

interface Props extends ComponentProps<typeof Drawer> {
  map: Map;
}

/**
 * Side panel that shows how to use the cross-section tool and renders the cross-section overlay when the panel is open.
 */
export const CrossSectionDrawer = ({map, onClose, open, ...rest}: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isVideoPlaying, setVideoPlaying] = useState(false);
  const theme = useTheme();

  function toggleVideo() {
    if (!videoRef.current) {
      return;
    }
    if (videoRef.current.currentTime === 0 || videoRef.current.paused) {
      videoRef.current.play();
      setVideoPlaying(true);
    } else {
      videoRef.current.pause();
      setVideoPlaying(false);
    }
  }

  return (
    <>
      <Drawer variant="persistent" open={open} {...rest}>
        <DrawerContent pl={theme.sizes.navbar} width={380}>
          <DrawerHeader>
            <DrawerTitle icon={<CrossSectionIcon />}>Corte vertical</DrawerTitle>
            <DrawerActions>
              <DrawerCloseButton
                onClick={(e) => onClose?.(e, 'backdropClick')}
              />
            </DrawerActions>
          </DrawerHeader>
          <DrawerBody marginLeft="8px" marginTop="8px">
            <Relative width="100%">
              <video
                ref={videoRef}
                muted
                loop
                onClick={toggleVideo}
                style={{width: '100%', cursor: 'pointer'}}
              >
                <source
                  src="./resources_ria/CrossSectionExample.webm"
                  type='video/webm; codecs="av01.0.00M.08"'
                />
              </video>
              {!isVideoPlaying && (
                <Absolute
                  onClick={toggleVideo}
                  position="absolute"
                  left="50%"
                  top="50%"
                  sx={{
                    transform: 'translate(-50%,-50%)',
                    cursor: 'pointer',
                  }}
                >
                  <PlayOutlineIcon fontSize="large" />
                </Absolute>
              )}
            </Relative>
            <Inline alignItems="flex-start">
              <Text width="16px" textAlign="end">
                1.
              </Text>
              <Text>
                Haga click en cualquier lugar para definir el centro del plano del corte vertical
              </Text>
            </Inline>
            <Inline alignItems="flex-start">
              <Text width="16px" textAlign="end">
                2.
              </Text>
              <Text>Haga click para definir la extensión del corte vertical</Text>
            </Inline>
            <Inline alignItems="flex-start">
              <Text width="16px" textAlign="end">
                3.
              </Text>
              <Text>
                Rote el corte vertical manteniendo el botón izquierdo del ratón en la flecha de rotación
              </Text>
            </Inline>
            <Inline alignItems="flex-start">
              <Text width="16px" textAlign="end">
                4.
              </Text>
              <Text>
                Mueva el corte vertical en el mapa principal donde desee
              </Text>
            </Inline>
            <Inline alignItems="flex-start">
              <Text width="16px" textAlign="end">
                5.
              </Text>
              <Text>
                Haga zoom en el panel inferior para modificar el tamaño del corte vertical
              </Text>
            </Inline>
            <Inline alignItems="flex-start">
              <Text width="16px" textAlign="end">
                6.
              </Text>
              <Text>
                Desplácese en el panel inferior para mover el corte vertical sobre su propio plano
              </Text>
            </Inline>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {open && <CrossSectionOverlay mainMap={map} />}
    </>
  );
};
