export const LOAD_LAYER_REQUEST_EVENT_TYPE = "LOAD_LAYER_REQUEST_EVENT_TYPE";
export const LAYER_LOADED_EVENT_TYPE = "LAYER_LOADED_EVENT_TYPE";

export type LoadLayerRequestEventDetail = {
    layerId: string | number
}

export type LoadedLayerEventDetail = {
    layerId: string | number,
    loadedSuccessful: boolean
}

export function createLoadLayerRequestEvent( layerId: string | number ): CustomEvent<LoadLayerRequestEventDetail> {
    return new CustomEvent<LoadLayerRequestEventDetail>( LOAD_LAYER_REQUEST_EVENT_TYPE, {
        detail: {
            layerId
        }
    } );
}

export function createLayerLoadedEvent( layerId: string | number, loadedSuccessful: boolean ): CustomEvent<LoadedLayerEventDetail> {
    return new CustomEvent<LoadedLayerEventDetail>( LAYER_LOADED_EVENT_TYPE, {
        detail: {
            layerId,
            loadedSuccessful
        }
    } );
}

