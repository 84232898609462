import {Controller} from "@luciad/ria/view/controller/Controller";
import {WMSTileSetLayer} from "@luciad/ria/view/tileset/WMSTileSetLayer";
import {GestureEvent} from "@luciad/ria/view/input/GestureEvent";
import {HandleEventResult} from "@luciad/ria/view/controller/HandleEventResult";
import {GestureEventType} from "@luciad/ria/view/input/GestureEventType";
import {LabelCanvas} from "@luciad/ria/view/style/LabelCanvas";
import {GeoJsonCodec} from "@luciad/ria/model/codec/GeoJsonCodec";
import {getReference} from "@luciad/ria/reference/ReferenceProvider";
import {Shape} from "@luciad/ria/shape/Shape";
import "./WMSFeatureInfoController.css"
import {PointLabelPosition} from "@luciad/ria/view/style/PointLabelPosition";

export class WMSFeatureInfoController extends Controller {

    private readonly _layer: WMSTileSetLayer;
    private readonly _noDataValues: string[];
    private readonly _valueFormatter?: ( value: string ) => string;
    private readonly _geoJsonCodec;

    private _value?: string;
    private _featureInfoPoint?: Shape;
    private _disabled: boolean;

    constructor( layer: WMSTileSetLayer, noDataValues?: string[], valueFormatter?: ( value: string ) => string ) {
        super();
        this._layer = layer;
        this._noDataValues = noDataValues ?? [];
        this._valueFormatter = valueFormatter;
        this._geoJsonCodec = new GeoJsonCodec();

        this._disabled = false;
    }

    set disabled( value: boolean ) {
        this._disabled = value;
    }

    clear() {
        this.updateLabel( undefined, undefined );
    }

    onGestureEvent( gestureEvent: GestureEvent ): HandleEventResult {
        if ( gestureEvent.type === GestureEventType.SINGLE_CLICK_CONFIRMED && !this._disabled ) {
            this._layer.getFeatureInfo( gestureEvent.viewPoint.x, gestureEvent.viewPoint.y )?.then( info => {
                const infoJson = JSON.parse( info.text );
                const feature = infoJson.features[0];
                if ( feature ) {
                    const value = this.getValue( feature );
                    if ( this._noDataValues.includes( value ) ) {
                        this.updateLabel( undefined, undefined )
                    }
                    else {
                        this.updateLabel( value, this.getLocation( infoJson, feature ) )
                    }
                }
            } ).catch( e => {
                console.debug( "Could not get info for feature layer: " + e )
            } );
        }
        return super.onGestureEvent( gestureEvent );
    }

    private getValue( feature: any ): string {
        const values = feature.properties.values;
        //Values is a string like [value, Unknown]
        return values.split( "," )[0].replace( "[", "" );
    }

    private getLocation( infoJson: any, feature: any ): Shape {
        const crsCode = infoJson.crs.properties.name;
        return this._geoJsonCodec.decodeGeometryObject( feature.geometry, getReference( crsCode ) );
    }

    private updateLabel( value: string | undefined, shape: Shape | undefined ) {
        this._value = value;
        this._featureInfoPoint = shape;
        this.invalidate();
    }

    onDrawLabel( labelCanvas: LabelCanvas ) {
        if ( !this._value || !this._featureInfoPoint ) {
            return;
        }
        const text = this._valueFormatter ? this._valueFormatter( this._value ) : this._value;
        const html = "<div class='wms-info-label'>" + text + "</div>";
        labelCanvas.drawLabel( html, this._featureInfoPoint, {positions: PointLabelPosition.NORTH} );
    }
}
