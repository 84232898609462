import {WMSTileSetLayer} from "@luciad/ria/view/tileset/WMSTileSetLayer";
import {TileSet3DLayer} from "@luciad/ria/view/tileset/TileSet3DLayer";
import {FeatureLayer} from "@luciad/ria/view/feature/FeatureLayer";
import {LayerGroup} from "@luciad/ria/view/LayerGroup";
import {LayerConstructorOptions} from "@luciad/ria/view/LayerTreeNode";
import {RasterLayerGroup} from "./RasterLayerGroup";
import {FourBandsWMSLayerGroup} from "./FourBandsWMSLayerGroup";

export type LayerOptions = {
    hideInLayerTree?: boolean,
    hideFit?: boolean,
    hideVisibilityToggle?: boolean,
    lazy?: boolean
} & LayerConstructorOptions;


// Typescript mixin
type Constructor = new ( ...args: any[] ) => {};

function WithOptions<TBase extends Constructor>( Base: TBase ) {
    return class HasOptions extends Base {
        _hideInLayerTree: boolean | undefined = false;
        _hideVisibilityToggle: boolean | undefined = true;
        _hideFit: boolean | undefined = true;
        _lazy: boolean | undefined = false;

        set hideInLayerTree( newValue: boolean | undefined ) {
            this._hideInLayerTree = newValue;
        }

        get hideInLayerTree(): boolean | undefined {
            return this._hideInLayerTree;
        }

        set hideVisibilityToggle( newValue: boolean | undefined ) {
            this._hideVisibilityToggle = newValue;
        }

        get hideVisibilityToggle(): boolean | undefined {
            return this._hideVisibilityToggle;
        }

        set hideFit( newValue: boolean | undefined ) {
            this._hideFit = newValue;
        }

        get hideFit(): boolean | undefined {
            return this._hideFit;
        }

        set lazy( newValue: boolean | undefined ) {
            this._lazy = newValue;
        }

        get lazy(): boolean | undefined {
            return this._lazy;
        }
    };
}

export const WMSTileSetLayerWithOptions = WithOptions( WMSTileSetLayer )
export const TileSet3DLayerWithOptions = WithOptions( TileSet3DLayer )
export const FeatureLayerWithOptions = WithOptions( FeatureLayer )
export const LayerGroupWithOptions = WithOptions( LayerGroup )
export const RasterLayerGroupWithOptions = WithOptions( RasterLayerGroup )
export const FourBandsWMSLayerGroupWithOptions = WithOptions( FourBandsWMSLayerGroup )

export function isHidden( arg: any ): arg is { hideInLayerTree: boolean } {
    return arg.hideInLayerTree
}

export function hasFit( arg: any ): arg is { hideFit: boolean }   {
    return !arg.hideFit;
}

export function hasVisibilityToggle( arg: any ): arg is { hideVisibilityToggle: boolean }   {
    return !arg.hideVisibilityToggle;
}

export function isLazy( arg: any ): arg is { lazy: boolean } {
    return arg.lazy
}

export function isLoaded( arg: any ): arg is { loaded: boolean } {
    return arg.loaded
}
