import ReactDOM from 'react-dom';
import React from 'react';
import {loadLicenseFromUrl} from '@luciad/ria/util/License';
import './common/typings/image-typings';

//workaround for https://github.com/mui/material-ui/issues/35287 (@mui clashes with react 17 typings)
declare global {
    namespace React {
        interface DOMAttributes<T> {
            onResize?: ReactEventHandler<T> | undefined;
            onResizeCapture?: ReactEventHandler<T> | undefined;
            nonce?: string | undefined;
        }
    }
}

const registerServiceWorker = async () => {
    if ( "serviceWorker" in navigator ) {
        try {
            const registration = await navigator.serviceWorker.register( "/aws-service-worker.js", {
                scope: "/",
            } );
            if ( registration.installing ) {
                console.log( "Service worker installing" );
            }
            else if ( registration.waiting ) {
                console.log( "Service worker installed" );
            }
            else if ( registration.active ) {
                console.log( "Service worker active" );
            }
        } catch ( error ) {
            console.error( `Registration failed with ${error}` );
        }
    }
    else {
        console.log( "Service worker not present in navigator" );
    }
};

/**
 * Main render function of the LuciadRIA application template.
 * This is called once each time that the web page is loaded.
 */
async function render() {
    await loadLicenseFromUrl( process.env.NODE_ENV === "production" ? "../licenses/luciadria_deployment.txt" : "../licenses/luciadria_development.txt" );
    await registerServiceWorker();
    const App = require( './App' ).App;
    const componentToRender = <App/>;
    ReactDOM.render( componentToRender, document.getElementById( 'root' ) );
}

render();
