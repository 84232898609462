import {WMSCapabilities} from "@luciad/ria/model/capabilities/WMSCapabilities";
import {FeatureModel} from "@luciad/ria/model/feature/FeatureModel";

export function createFrom2DSimulation( capabilities: WMSCapabilities ) {
    const dataDates = getDataDates( capabilities );
    const startDate = dataDates[0];
    const endDate = dataDates[dataDates.length - 1];
    const durationMilliSeconds = endDate.getTime() - startDate.getTime();

    const intervalMinutes = Math.round( (durationMilliSeconds / dataDates.length) / 1000 / 60 );
    const intervalMilliSeconds = intervalMinutes * 60 * 1000;

    return new TimeDimensions( startDate, endDate, durationMilliSeconds, intervalMilliSeconds )
}

function getDataDates( capabilities: WMSCapabilities ): Date[] {
    // @ts-ignore Lets avoid another call to the service
    const xml = capabilities._capabilitiesXML;
    const xmlDoc = new DOMParser().parseFromString( xml, "text/xml" );
    const dimensionTag = xmlDoc.getElementsByTagName( "Dimension" ).item( 0 );
    if ( !dimensionTag || dimensionTag.getAttribute( "name" ) !== "TIME" ) {
        return [];
    }
    const dimensionText = dimensionTag.firstChild!.textContent;
    const dimensions = dimensionText!.split( "," );
    return dimensions.map( dimension => new Date( dimension ) );
}

export function createFrom3DSimulation( model: FeatureModel ) {
    //TODO get this info from the server
    const startDate = new Date( 1220234700000 );
    const endDate = new Date( 1220259300000 );
    return new TimeDimensions( startDate, endDate, 1220259300000 - 1220234700000, 300_000 )
}

export class TimeDimensions {

    private readonly _startDate: Date;
    private readonly _endDate: Date;
    private readonly _durationMilliSeconds: number;
    private readonly _intervalMilliSeconds: number;

    constructor( startDate: Date, endDate: Date, durationMilliSeconds: number, intervalMilliSeconds: number ) {

        this._startDate = startDate;
        this._endDate = endDate;
        this._durationMilliSeconds = durationMilliSeconds;
        this._intervalMilliSeconds = intervalMilliSeconds;
    }

    get startDate(): Date {
        return this._startDate;
    }

    get endDate(): Date {
        return this._endDate;
    }

    get durationMilliSeconds(): number {
        return this._durationMilliSeconds;
    }

    get intervalMilliSeconds(): number {
        return this._intervalMilliSeconds;
    }
}