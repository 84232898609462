import {Invalidation} from "@luciad/ria/util/Invalidation";

/**
 * An object that calls {@link Invalidation#on} when the value is modified.
 */
export class ObservableNumber extends Invalidation {

    private _value: number;

    constructor( value: number ) {
        super();
        this._value = value;
    }

    get value(): number {
        return this._value;
    }

    set value( newValue: number ) {
        const oldValue = this._value;
        if ( oldValue !== newValue ) {
            this._value = newValue;
            this.invalidate();
        }
    }
}