import {useMapContext} from "../common/hooks/useMapContext";
import React, {useEffect} from "react";
import {FeatureModel} from "@luciad/ria/model/feature/FeatureModel";
import {FeatureLayer} from "@luciad/ria/view/feature/FeatureLayer";
import ThreeDObjectPainter from "../3dobjects/view/ThreeDObjectPainter";
import {MemoryStore} from "@luciad/ria/model/store/MemoryStore";
import {getReference} from "@luciad/ria/reference/ReferenceProvider";

export const THREE_D_OBJECTS_LAYER_ID = "THREE_D_OBJECTS";
export const ThreeDObjects = () => {
    const map = useMapContext().map;

    useEffect( () => {
        if ( map ) {
            const store = new MemoryStore({ reference: getReference("CRS:84") });
            const model = new FeatureModel( store );
            const layer = new FeatureLayer( model, {
                id: THREE_D_OBJECTS_LAYER_ID,
                label: "Objetos 3D",
                selectable: true,
                editable: true,
                painter: new ThreeDObjectPainter()
            } );
            map.layerTree.addChild( layer )
        }
    }, [map] )

    return <></>
}
