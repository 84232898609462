import {useEffect, useState} from 'react';
import {IPlayer, PLAYER_TIME_EVENT} from "../../PlayerTypes";

export function useInitPlayer( player: IPlayer, minSliderValue: number, duration: number ) {
    const [playing, setPlaying] = useState<boolean>( false );
    const [fraction, setFraction] = useState<number>( 0 );

    useEffect( () => {
        setPlaying( false );
        setFraction( 0 );
        const handle = player.on( PLAYER_TIME_EVENT, ( {playing, fraction} ) => {
            setPlaying( playing );
            setFraction( fraction );
        } );
        return () => handle.remove();
    }, [player] );

    function actionPlay( play: boolean ) {
        play ? player.play() : player.pause();
        setPlaying( play );
    }

    function actionMove( value: number, updatePlayer: boolean ) {
        const fraction = (value - minSliderValue) / duration;
        setFraction( fraction );
        if ( updatePlayer ) {
            player.move( fraction );
        }
    }

    return {playing, fraction, actionPlay, actionMove,};
}
