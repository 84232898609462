import {Handle} from '@luciad/ria/util/Evented';

export const PLAYER_TIME_EVENT = 'PlayerChangeEvent';

export interface IPlayerTimeInfo {
  playing: boolean;
  fraction: number;
}

export type PlayerTimeCallback = (info: IPlayerTimeInfo) => void;

export interface IPlayable {
  init(): void;

  play(): void;

  pause(): void;

  move(f: number): void;
}

export interface IPlayer extends IPlayable {
  setSpeedFactor(factor: number): void;

  on(event: typeof PLAYER_TIME_EVENT, callback: PlayerTimeCallback): Handle;
}
