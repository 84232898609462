import React from "react";
import {Absolute, Card, Text} from '@digitalreality/ui';
import {UpwellingCampaign} from "../model/UpwellingCampaign";
import {Compare, DeleteOutlined} from "@mui/icons-material";
import {IconButton} from "@mui/material";

export interface UpwellingCampaignCardProps {
    campaign: UpwellingCampaign,
    onSelected: () => void,
    selected: boolean,
    onComparing: () => void,
    comparing: boolean,
    canCompare: boolean,
    deleteClicked: ( campaign: UpwellingCampaign ) => void
}

export const UpwellingCampaignCard = ( {
                                           campaign,
                                           onSelected,
                                           selected,
                                           onComparing,
                                           comparing,
                                           canCompare,
                                           deleteClicked
                                       }: UpwellingCampaignCardProps ) => {
    function buttonClicked( e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>, callback: () => void ) {
        callback();
        e.preventDefault();
        e.stopPropagation();
    }

    return <Card title={campaign.displayName}
                 sx={( theme ) => ({
                     width: '285px',
                     height: '60px',
                     position: 'relative',
                     backgroundColor: selected ? "rgba(255,255,255,50%)" : theme.colors.ui.menu,
                     padding: '8px',
                 })}
                 onClick={onSelected}>
        <Absolute left={"8px"} bottom={"8px"} right={"8px"} top={"8px"} borderRadius={"7px"} bgcolor={"#212121"}>
            <Absolute left={"4px"} bottom={"4px"} right={"4px"} top={"4px"}>
                <Text size={"12px"} noWrap>{campaign.displayName}</Text>
                <Text size={"12px"} noWrap>{campaign.date.toLocaleDateString( "es-ES" )}</Text>
            </Absolute>
            <Absolute right={"2px"} top={"2px"}>
                <IconButton onClick={e => buttonClicked( e, onComparing )}
                            sx={{"color": comparing ? "#5098A1" : "white"}}
                            disabled={!canCompare}>
                    <Compare/>
                </IconButton>
                <IconButton onClick={e => buttonClicked( e, () => deleteClicked( campaign ) )}>
                    <DeleteOutlined/>
                </IconButton>
            </Absolute>
        </Absolute>
    </Card>
}
