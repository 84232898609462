import {RotateController} from "@luciad/ria/view/controller/RotateController";
import {createPoint} from "@luciad/ria/shape/ShapeFactory";

export const createNoRotateController = () => {
    // A rotate controller for 3D that does not allow rotating along the pitch axis (thus mimicking a 2d map)
    const rotateController = new RotateController();

    // Override the rotate controller to function like in 2D:
    // 1. The default implementation does not use the center of the screen a rotation center in a 3D map.
    rotateController.getRotationCenter = () => {
        return createPoint( null, [
            rotateController.map!.viewSize[0] / 2,
            rotateController.map!.viewSize[1] / 2
        ] );
    }

    // 2. Make the yaw rotation be the same as the "rotation angle", which is what is used in 2D.
    rotateController.getYawAngle = rotateController.getRotationAngle

    // 3. Never rotate along the pitch axis
    rotateController.getPitchAngle = () => 0
    return rotateController
}
