import React, {useEffect, useRef, useState} from 'react';
import {Map} from "@luciad/ria/view/Map";
import {useControllerContext} from "../../navigation/ControllerContext";
import {SwipeController, SwipeLineOrientation} from "@luciad/ria/view/controller/SwipeController";
import "./SwipeController.css";
import {Layer} from "@luciad/ria/view/Layer";
import {LayerGroup} from "@luciad/ria/view/LayerGroup";

interface SwipeControllerUIProps {
    map: Map,
    leftLayerGroup: LayerGroup,
    rightLayerGroup: LayerGroup
}

/**
 * Swipe controller that accepts two layer groups to be compared.
 * Only first child layers are taken into account.
 */
export function SwipeControllerUI( {map, leftLayerGroup, rightLayerGroup}: SwipeControllerUIProps ) {

    const swipeControllerRef = useRef<SwipeController>( new SwipeController() );
    const [controllerContext, setControllerContext] = useControllerContext();
    const [isVertical, setVertical] = useState<boolean>( true );
    const [centerPos, setCenterPos] = useState<number>( map.viewSize[0] / 2 );
    const [isHovering, setHovering] = useState<boolean>( false );

    function setLayers( swipeController: SwipeController ) {
        const leftLayers = leftLayerGroup.children.filter( child => child instanceof Layer ) as Layer[];
        const rightLayers = rightLayerGroup.children.filter( child => child instanceof Layer ) as Layer[];

        swipeController.layers = [leftLayers, rightLayers];
    }

    useEffect( () => {
        const swipeController = swipeControllerRef.current;
        setLayers( swipeController );

        function handleSwipeLineChange() {
            const {swipeLineOrientation, swipeLineLocation} = swipeController;
            const vertical = swipeLineOrientation === SwipeLineOrientation.VERTICAL;
            setCenterPos( vertical ? swipeLineLocation.x : swipeLineLocation.y );
            setVertical( vertical );
        }

        swipeController.on( "SwipeLineLocationChange", handleSwipeLineChange );
        handleSwipeLineChange();


        function updateHovering() {
            setHovering( swipeController.swiping || swipeController.hovering );
        }

        swipeController.on( "HoveringChanged", updateHovering );
        swipeController.on( "SwipingChanged", updateHovering );
        updateHovering();

        swipeController.on( "Activated", () => {
            // Needed for 2D/3D switch
            handleSwipeLineChange()
        } )

        setControllerContext( ( prev ) => ({...prev, interactionController: swipeController}) )

        return () => {
            if ( controllerContext.interactionController === swipeController ) {
                setControllerContext( ( prev ) => ({...prev, interactionController: undefined}) );
            }
        };
    }, [] )

    useEffect( () => {
        let cursor = "auto";
        if ( isHovering ) {
            cursor = isVertical ? "col-resize" : "row-resize";
        }
        map.domNode.style.cursor = cursor;

        return () => {
            map.domNode.style.cursor = "auto";
        }
    }, [isHovering, isVertical] )

    const toolbarWidth = 50;
    const moveLine = isVertical ? `translateX(${centerPos + toolbarWidth}px)` : `translateY(${centerPos}px)`;

    return <div style={{transform: moveLine}}
                className={"swipe-line " + (isVertical ? "swipe-vertical" : "swipe-horizontal")}>
        <label className={isVertical ? "left-label" : "top-label"}>{leftLayerGroup.label}</label>
        <label className={isVertical ? "right-label" : "bottom-label"}>{rightLayerGroup.label}</label>
    </div>

}