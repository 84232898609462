import {CoordinateReference} from "@luciad/ria/reference/CoordinateReference";
import {getReference} from "@luciad/ria/reference/ReferenceProvider";
import {ReferenceType} from "@luciad/ria/reference/ReferenceType";
import {LonLatPointFormat} from "@luciad/ria/shape/format/LonLatPointFormat";
import {Point} from "@luciad/ria/shape/Point";
import React from "react";
import {Formatter, useFormattedMouseCoordinate} from "../../hooks/useMouseCoordinate";
import {useMouseHeight} from "../../hooks/useMouseHeight";
import "./MouseCoordinateReadout.css";
import {useMapContext} from "../../hooks/useMapContext";

interface Props {
  reference?: CoordinateReference;
  formatter?: Formatter;
  defaultReadout?: string;
  getHeight?: (wgs84MouseLocation: Point) => Promise<number>;
}

export const MouseCoordinateReadout = ({
                                         reference,
                                         formatter,
                                         defaultReadout,
                                         getHeight
                                       }: Props) => {
  formatter = formatter ?? new LonLatPointFormat();
  reference = reference ?? getReference("CRS:84");
  defaultReadout = defaultReadout ?? "---°--'--\",----°--'--\"";

  const map = useMapContext().map;

  const formattedMouseLocation = useFormattedMouseCoordinate(map, formatter, reference) ?? defaultReadout;

  let heightText = "";
  if (reference.referenceType != ReferenceType.CARTESIAN) {
    const height = useMouseHeight(map, getHeight);
    heightText = `${height.toFixed(1)}m`;
  }

  return (
      <div className="mouse-coordinate-readout">
        <div className="mouse-coordinates">
          {formattedMouseLocation}
        </div>
        <div className="mouse-height">
          {heightText}
        </div>
      </div>
  );
}
