import React, {FC, ReactElement, useEffect, useState} from 'react';
import {IPlayer} from "../PlayerTypes";
import {FormControl, MenuItem, Select} from "@mui/material";

interface SpeedFactorProps {
    player: IPlayer;
    intervalMilliSeconds: number;
    disabled?: boolean;
}

export const SpeedFactor: FC<SpeedFactorProps> = ( {player, intervalMilliSeconds, disabled = false} ) => {

    const [speedFactor, setSpeedFactor] = useState<number>( 1 );
    const [options, setOptions] = useState<ReactElement[]>( [] );

    useEffect( () => {
        const newOptions = createOptions( intervalMilliSeconds );
        setOptions( newOptions );
        const fastest = newOptions[newOptions.length - 1];
        const value = fastest.props.value as number;
        setSpeedFactor( value );
    }, [intervalMilliSeconds] )

    useEffect( () => {
        player.setSpeedFactor( speedFactor );
    }, [speedFactor] )

    function createOptions( intervalMilliSeconds: number ): ReactElement[] {
        const options = [] as ReactElement[];
        if ( intervalMilliSeconds < 1000 ) {
            return options;
        }

        const steps = [0.5, 1, 2, 5, 10];
        for ( const step of steps ) {
            const seconds = Math.round( intervalMilliSeconds * step / 1000 );
            if ( seconds >= 60 ) {
                const minutes = Math.round( seconds / 60 );
                options.push( <MenuItem key={step} value={minutes * 60}>x {minutes} min</MenuItem> )
            }
            else {
                options.push( <MenuItem key={step} value={seconds}>x {seconds} seg</MenuItem> )
            }
        }
        return options;
    }

    return (
        <div className={'replayRight'} style={{marginTop: "5px"}}>
            <FormControl>
                <Select value={speedFactor} id="speedFactor" displayEmpty sx={{fontSize: "14px"}} disableUnderline
                        variant={"standard"}
                        disabled={disabled}
                        onChange={( event ) => setSpeedFactor( +event.target.value )}>
                    <MenuItem key={0} value={1}>x 1 seg</MenuItem>
                    {options}
                </Select>
            </FormControl>
        </div>
    );
};
