import {createContext, Dispatch, SetStateAction, useContext} from "react";
import {ALL_CLASSES} from "./PointCloudClasses";

export enum CoordinateFormat {
    LON_LAT, UTM
}

export interface ISettings {
    coordinateFormat: CoordinateFormat,
    pointCloudClasses: number[]
}

export const DEFAULT_SETTINGS: ISettings = {
    coordinateFormat: CoordinateFormat.LON_LAT,
    pointCloudClasses: ALL_CLASSES.filter( c => c.defaultEnabled ).map( c => c.id )
}

export type SettingsUpdate = Dispatch<SetStateAction<ISettings>>;

export const Settings = createContext<[ISettings, SettingsUpdate]>( [DEFAULT_SETTINGS, () => {} ] );

export const useSettings = () => {
    return useContext( Settings )
}
