import React from "react";
import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";

export interface ConfirmationDialogProps {
    message: string,
    onAccept: () => void,
    onCancel: () => void
}

export function ConfirmationDialog( {message, onAccept, onCancel}: ConfirmationDialogProps ) {

    return (
        <div>
            <Dialog open={true} onClose={onCancel} aria-describedby="alert-dialog-description">
                <DialogContent>{message}</DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} autoFocus>No</Button>
                    <Button onClick={onAccept} autoFocus>Si</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}