import React, {createContext, Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {Feature} from "@luciad/ria/model/feature/Feature";
import {createNoRotateController,} from "./ControllerFactory";
import {FeatureLayer} from "@luciad/ria/view/feature/FeatureLayer";
import {WebGLMap} from "@luciad/ria/view/WebGLMap";
import {CompositeController} from "@luciad/ria/view/controller/CompositeController";
import {PerspectiveCamera} from "@luciad/ria/view/camera/PerspectiveCamera";
import {createPoint} from "@luciad/ria/shape/ShapeFactory";
import {createCartesianGeodesy} from "@luciad/ria/geodesy/GeodesyFactory";
import {create3DCameraAnimation} from "../common/controller/animation/Move3DCameraAnimation";
import {AnimationManager} from "@luciad/ria/view/animation/AnimationManager";
import {NoopController} from "../common/controller/NoopController";
import {Controller} from "@luciad/ria/view/controller/Controller";
import BoundedRotateController from "./BoundedRotateController";
import {THREE_D_OBJECTS_LAYER_ID} from "../layers/ThreeDObjects";
import ThreeDObjectController from "../3dobjects/controller/ThreeDObjectController";
import {PickInfo} from "@luciad/ria/view/PickInfo";


export interface IControllerContext {
    threeD: boolean,
    // The interaction controller is whatever tool is being used by the user (measuring, editing, creating, slicing, cross section... )
    interactionController?: Controller,
    // Keep the selection when switching from 2D to 3D
    selection?: PickInfo[]
}

export type ControllerUpdate = Dispatch<SetStateAction<IControllerContext>>;

export const ControllerContext = createContext<[IControllerContext, ControllerUpdate]>( undefined! );

function tiltCamera( map: WebGLMap, pitch: number ) {
    const camera = map.camera as PerspectiveCamera;
    const center = [map.viewSize[0] / 2, map.viewSize[1] / 2];
    let distance;
    try {
        const centerMap = map.viewToMapTransformation.transform( createPoint( null, center ) )
        distance = createCartesianGeodesy( map.reference ).distance( centerMap, map.camera.eyePoint );
    } catch ( e ) {
        distance = 500
    }

    const lookAt = camera.asLookAt( distance );
    const targetCamera = camera.lookAt(
        {
            ...lookAt,
            // ref: lookAt.ref,
            // distance: distance,
            yaw: 0,
            pitch: pitch,
            roll: 0
        } );

    return create3DCameraAnimation( map, targetCamera, 2000 )
}

function resolveController( previous: IControllerContext,
                            current: IControllerContext,
                            map: WebGLMap ) {
    console.debug( "Resolving controller", previous, current )

    const controller = new CompositeController();
    const noOpController = new NoopController( {enabled: false} );

    if ( current.interactionController ) {
        controller.appendController( current.interactionController )
    }

    controller.appendController( noOpController )

    if ( current.threeD ) {
        controller.appendController( new BoundedRotateController( {maxPitch: -40, minPitch: -90} ) )
    }
    else {
        controller.appendController( createNoRotateController() )
    }

    map.controller = controller;

    // When switching from 2D to 3D or vice-versa and keeping the same controller, keep the selection for styling
    if ( previous.threeD !== current.threeD && previous.interactionController === current.interactionController &&
         previous.selection ) {
        map.selectObjects( previous.selection );
    }

    // When switching from 3D to 2D, force back the camera to top-down
    if ( previous.threeD && !current.threeD ) {
        animateCameraTilt( -89 )
    }

    // When switching from 2D to 3D, tilt the camera
    if ( !previous.threeD && current.threeD ) {
        animateCameraTilt( -45 );
    }

    function animateCameraTilt( pitch: number ) {
        const animation = tiltCamera( map, pitch );
        // Prevent user from moving the camera during the animation
        animation.onStart = () => noOpController.enabled = true
        animation.onStop = () => noOpController.enabled = false

        AnimationManager.putAnimation( map.cameraAnimationKey, animation, false );
    }
}

export const useControllerContext = () => {
    return useContext( ControllerContext )
}

const defaultContext = {threeD: true};

export const useControllerState = ( map?: WebGLMap ): [IControllerContext, Dispatch<SetStateAction<IControllerContext>>] => {
    const [controllerContext, setControllerContext] = useState<IControllerContext>( defaultContext );
    const previous = useRef<IControllerContext>( defaultContext )

    // Install listeners.
    useEffect( () => {
        if ( !map ) {
            return
        }

        const resetDefaultController = map.on( 'ControllerChanged', ( newController, previousController ) => {
            if ( newController == null ) {
                // We assume that this happens due to an interaction controller terminating.
                setControllerContext( prev => ({
                    ...prev,
                    interactionController: undefined,
                    selection:undefined
                }) )
            }
        } );

        // Turn on editing when a shape is selected.
        const turnOnEditing = map.on( 'SelectionChanged', event => {
            const selectedFeature: Feature | null = (event.selectionChanges[0]?.selected[0] as Feature) ?? null;

            if ( !selectedFeature ) {
                return;
            }

            const layer = event.selectionChanges[0].layer as FeatureLayer;

            if ( !(layer.editable && layer.visibleInTree) ) {
                return;
            }

            let editController: Controller;
            if ( layer.id === THREE_D_OBJECTS_LAYER_ID ) {
                editController = ThreeDObjectController.editController( selectedFeature );
            }

            setControllerContext( prev => ({
                ...prev,
                interactionController: editController,
                selection: [{layer, objects: [selectedFeature]}]
            }) )
        } );

        return () => {
            resetDefaultController.remove();
            turnOnEditing.remove();
        }
    }, [map, controllerContext] )

    useEffect( () => {
        if ( !map ) {
            return
        }
        resolveController( previous.current, controllerContext, map );
        previous.current = controllerContext
    }, [controllerContext, map] )

    if ( !map ) {
        return [controllerContext, setControllerContext]
    }

    return [controllerContext, setControllerContext]
}
