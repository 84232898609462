import {getReference} from "@luciad/ria/reference/ReferenceProvider";
import {Map} from "@luciad/ria/view/Map";
import React, {useEffect, useState} from "react";
import {Handle} from "@luciad/ria/util/Evented";
import "./OverviewMap.css";
import {CollapsablePanel} from "../../common/components/CollapsablePanel";
import {Absolute, Box} from "@digitalreality/ui";
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {createCameraLocationLayer} from "./CameraLayerFactory";
import {MapContext, useMapContext} from "../../common/hooks/useMapContext";
import {CompassButton} from "../../common/components/overlay/CompassButton";
import {createWMSTileSetLayer} from "../../layers/LayerFactory";

interface Props {
    initiallyCollapsed: boolean
}

export const OverviewMap = ( props: Props ) => {
    const mainMap = useMapContext().map;
    const [overviewMap, setOverviewMap] = useState<Map | null>( null )
    const [followMainCamera, setFollowMainCamera] = useState<boolean>( true )
    const [collapsed, setCollapsed] = useState<boolean>( props.initiallyCollapsed )
    const [followMainCameraListener, setFollowMainCameraListener] = useState<Handle | null>( null )

    useEffect( () => {
        const webMercatorRef = getReference( "EPSG:3857" );
        const map = new Map( "overview-map", {reference: webMercatorRef} );
        map.mapNavigator.zoom( {animate: false, factor: {x: 50000, y: 50000}} );

        const layerTree = map.layerTree;
        layerTree.addChild( createCameraLocationLayer( mainMap ) )

        createWMSTileSetLayer( {
                                   url: "https://campo-cartagena-gemelo-digital-luciadfusion.es/ogc/wms/cog_general",
                                   layers: ["cog_general"],
                                   visible: true,
                                   id: "OF_EXPEDITA_COG_MTN25_con_censuras_WMS",
                                   label: "COG TM25 con censura",
                                   reference: webMercatorRef
                               } ).then( layer => layerTree.addChild( layer, "bottom" ) );

        setOverviewMap( map );
        return () => {
            map.destroy();
        }
    }, [] )

    useEffect( () => {
        if ( !overviewMap ) {
            return;
        }

        if ( followMainCamera ) {
            overviewMap.mapNavigator.pan( {animate: true, targetLocation: mainMap.camera.eyePoint} )
            const handle = mainMap.on( "MapChange", () => {
                overviewMap.mapNavigator.pan( {targetLocation: mainMap.camera.eyePoint} );
            } );
            setFollowMainCameraListener( handle );
        }
        else if ( followMainCameraListener ) {
            followMainCameraListener.remove();
            setFollowMainCameraListener( null );
        }
    }, [overviewMap, followMainCamera] )

    return (
        <CollapsablePanel className="overview-map-panel" header={"Vista general"} headerIcon={"lcd-icon-location"}
                          isCollapsed={props.initiallyCollapsed}
                          onCollapsedChange={( collapsedState ) => setCollapsed( collapsedState )}>
            <Box id="overview-map" className="overview-map">
                {overviewMap && <MapContext.Provider value={{map: overviewMap}}>
                    {/*Collapsed panel does not get along with internal nodes, do not rely on default behaviour*/}
                    {!collapsed &&
                     <>
                       <Box className="lock-container" onClick={() => setFollowMainCamera( !followMainCamera )}>
                           {followMainCamera ? <LockOutlinedIcon/> : <LockOpenOutlinedIcon/>}
                       </Box>
                       <Absolute bottom={"s"} right={"s"}>
                         <CompassButton/>
                       </Absolute>
                     </>
                    }
                </MapContext.Provider>}
            </Box>
        </CollapsablePanel>
    )

}
