import {createContext, Dispatch, SetStateAction, useContext, useState} from "react";

export interface IPlayerContext {
    playing: boolean,
    fraction: number
}

export type PlayerUpdate = Dispatch<SetStateAction<IPlayerContext>>;

export const PlayerContext = createContext<[IPlayerContext, PlayerUpdate]>( undefined! );

export const usePlayerContext = () => {
    return useContext( PlayerContext )
}

export const usePlayerState = (): [IPlayerContext, PlayerUpdate] => {
    const [playerContext, setPlayerContext] = useState<IPlayerContext>( {playing: false, fraction: 0} );
    return [playerContext, setPlayerContext]
}
