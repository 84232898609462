import React, {FC, PropsWithChildren, useEffect} from 'react';
import IconPlay from '../../resources_ria/icons/play_fill.svg';
import IconPause from '../../resources_ria/icons/pause_fill.svg';
import './Player.css';
import {ActionIcon} from '../ActionIcon/ActionIcon';
import {usePlayerProgress} from './hooks/UsePlayerProgress';
import {useInitPlayer} from './hooks/UseInitPlayer';
import {IPlayer} from "../PlayerTypes";
import {Slider} from "@digitalreality/ui";
import {SpeedFactor} from "./SpeedFactor";
import {PlayerContext, usePlayerState} from "./PlayerContext";

export interface ReplayProps {
    player: IPlayer,
    minSliderValue: number,
    maxSliderValue: number,
    interval: number,
}

export const Player: FC<PropsWithChildren<ReplayProps>> = ( {
                                                                player,
                                                                minSliderValue,
                                                                maxSliderValue,
                                                                interval,
                                                                children
                                                            } ) => {
    const sliderDuration = maxSliderValue - minSliderValue;
    const {playing, fraction, actionPlay, actionMove} = useInitPlayer( player, minSliderValue, sliderDuration );
    const [sliderValue, formattedValue] = usePlayerProgress( player, fraction, minSliderValue, maxSliderValue, sliderDuration );
    const [playerContext, setPlayerContext] = usePlayerState();

    useEffect( () => setPlayerContext( {playing, fraction} ), [playing, fraction] );

    function updateSlider( event: React.SyntheticEvent | Event, value: number | number[] ) {
        actionMove( value as number, false )
    }

    function updatePlayer( event: React.SyntheticEvent | Event, value: number | number[] ) {
        actionMove( value as number, true )
    }

    return (
        <>
            <div className={'replayPanel'}>
                <span className={'replayTopRight'} style={{marginTop: '0'}}/>
                <span style={{marginTop: '0', alignItems: "center", justifyContent: "center", display: "flex"}}>
                {playing ?
                 <ActionIcon Icon={IconPause} action={() => actionPlay( false )} size={60}/> :
                 <ActionIcon Icon={IconPlay} action={() => actionPlay( true )} size={60}/>}
            </span>
                <span id={'sliderBox'}>
                    {player && !playing ?
                     <Slider id="replaySlider" min={minSliderValue} max={maxSliderValue} value={sliderValue}
                             disabled={playing}
                             onChange={updateSlider}
                             onChangeCommitted={updatePlayer}/> :
                     <div style={{width: 412, height: 24}}/>}
                </span>
                <div style={{
                    backgroundColor: "#383838",
                    borderRadius: "5px",
                    marginTop: '0',
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex"
                }}>
                    <span style={{fontSize: '14px'}}>{formattedValue}</span>
                    <SpeedFactor player={player} intervalMilliSeconds={interval} disabled={playing}/>
                </div>
            </div>
            <PlayerContext.Provider value={[playerContext, setPlayerContext]}>
                {children}
            </PlayerContext.Provider>
        </>
    );
};
