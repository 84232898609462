export interface PointCloudClass {
    id: number,
    name: string,
    color: string,
    defaultEnabled: boolean
}

export const ALL_CLASSES: PointCloudClass[] = [
    {
        id: 1,
        name: "Sin clasificar",
        color: "#f1eef6",
        defaultEnabled: false
    },
    {
        id: 2,
        name: "Suelo",
        color: "#fee391",
        defaultEnabled: true
    },
    {
        id: 3,
        name: "Vegetación baja",
        color: "#a1d99b",
        defaultEnabled: true
    },
    {
        id: 4,
        name: "Vegetación media",
        color: "#31a354",
        defaultEnabled: true
    },
    {
        id: 5,
        name: "Vegetación alta",
        color: "#006d2c",
        defaultEnabled: true
    },
    {
        id: 6,
        name: "Edificios",
        color: "#f03b20",
        defaultEnabled: true
    },
    {
        id: 7,
        name: "Punto bajo (Ruido)",
        color: "#fcbba1",
        defaultEnabled: false
    },
    {
        id: 8,
        name: "Reservado",
        color: "#38F9D5",
        defaultEnabled: false
    },
    {
        id: 9,
        name: "Agua",
        color: "#0868ac",
        defaultEnabled: false
    }, {
        id: 10,
        name: "Tren",
        color: "#FF7C6A",
        defaultEnabled: false
    },
    {
        id: 11,
        name: "Carretera",
        color: "#e34a33",
        defaultEnabled: false
    },
    {
        id: 12,
        name: "Reservado",
        color: "#384093",
        defaultEnabled: false
    },
    {
        id: 13,
        name: "Cable - Escudo",
        color: "#bcbddc",
        defaultEnabled: false
    },
    {
        id: 14,
        name: "Transmisión - Cable",
        color: "#756bb1",
        defaultEnabled: false
    },
    {
        id: 15,
        name: "Transmisión - Poste",
        color: "#54278f",
        defaultEnabled: false
    },
]
