import {MeasurementWrapper} from './MeasurementSupport';
import React from 'react';
import {Card, IconButton, Inline, Stack, Text} from '@digitalreality/ui';
import {CloseFilledIcon, LocateIcon, ViewFilledIcon, ViewOffFilledIcon,} from '@digitalreality/ui-icons';

interface MeasurementCardProps {
  measurementWrapper: MeasurementWrapper;
  onRemove: () => void;
  onFit: () => void;
  expanded: boolean;
  onChangeExpanded: (expanded: boolean) => void;
}

/**
 * Component displaying measurement information that allows users to edit and remove measurements.
 */
export const MeasurementCard = ({
  measurementWrapper,
  onRemove,
  onFit,
  expanded,
  onChangeExpanded,
}: MeasurementCardProps) => {
  return (
    <Card
      title={measurementWrapper.name}
      sx={(theme) => ({
        width: '100%',
        position: 'relative',
        backgroundColor: theme.colors.ui.menuSecondary,
        padding: '8px',
      })}
    >
      <Text noWrap>{measurementWrapper.name}</Text>
      <Stack width="100%" padding="4px" borderRadius="10px" gap="0">
        <Text size={1}>Tipo: {measurementWrapper.measurement.type}</Text>
        <Stack>
          {measurementWrapper.measurement
            .getFormattedTotalInfo()
            .map(({label, value}) => (
              <Text size={0} key={label}>
                {`${label}: ${value}`}
              </Text>
            ))}
        </Stack>
        <Inline sx={{position: 'absolute', bottom: '8px', right: '8px'}}>
          <IconButton size="small" onClick={onFit}>
            <LocateIcon />
          </IconButton>
          <IconButton size="small" onClick={() => onChangeExpanded(!expanded)}>
            {expanded ? <ViewFilledIcon /> : <ViewOffFilledIcon />}
          </IconButton>
        </Inline>
        <IconButton
          sx={{position: 'absolute', top: '8px', right: '8px'}}
          size="small"
          onClick={onRemove}
        >
          <CloseFilledIcon />
        </IconButton>
      </Stack>
    </Card>
  );
};
