import {FeaturePainter} from "@luciad/ria/view/feature/FeaturePainter";
import {PathLabelPosition} from "@luciad/ria/view/style/PathLabelPosition";
import {PathLabelRotation} from "@luciad/ria/view/style/PathLabelRotation";
import {LabelCanvas} from "@luciad/ria/view/style/LabelCanvas";
import {Feature} from "@luciad/ria/model/feature/Feature";
import {Shape} from "@luciad/ria/shape/Shape";
import {OnPathLabelStyle} from "@luciad/ria/view/style/OnPathLabelStyle";
import {GeoCanvas} from "@luciad/ria/view/style/GeoCanvas";
import {UndergroundLineId} from "../ui/UndergroundCrossSectionOverlay";

export class UndergroundLinesPainter extends FeaturePainter {

    private readonly _labelStyle: OnPathLabelStyle;

    constructor() {
        super();
        this._labelStyle = {
            positions: PathLabelPosition.BELOW,
            rotation: PathLabelRotation.FIXED_LINE_ANGLE
        };
    }

    paintBody( geoCanvas: GeoCanvas, feature: Feature, shape: Shape ) {
        switch ( feature.id ) {
            case UndergroundLineId.TERRAIN:
                geoCanvas.drawShape( shape, {stroke: {color: "#B97A57"}} );
                break;
            case UndergroundLineId.WATER:
                geoCanvas.drawShape( shape, {stroke: {color: "#C8BFE7"}} );
                break;
            case UndergroundLineId.GEOID:
                geoCanvas.drawShape( shape, {stroke: {color: "#00A2E8"}} );
                break;
        }
    }

    paintLabel( labelCanvas: LabelCanvas, feature: Feature, shape: Shape ) {
        switch ( feature.id ) {
            case UndergroundLineId.TERRAIN:
                labelCanvas.drawLabelOnPath( "<span style=\"color:#B97A57\">Terreno</span>", shape, this._labelStyle );
                break;
            case UndergroundLineId.WATER:
                labelCanvas.drawLabelOnPath( "<span style=\"color:#C8BFE7\">Lámina de agua</span>", shape, this._labelStyle );
                break;
            case UndergroundLineId.GEOID:
                labelCanvas.drawLabelOnPath( "<span style=\"color:#00A2E8\">Nivel del mar</span>", shape, this._labelStyle );
                break;
        }
    }
}