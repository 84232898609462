import {OGC3DTilesModel} from "@luciad/ria/model/tileset/OGC3DTilesModel";
import {
    TileLoadingStrategy,
    TileSet3DLayer,
    TileSet3DLayerConstructorOptions
} from "@luciad/ria/view/tileset/TileSet3DLayer";
import {attribute, cases, color, eq, number} from "@luciad/ria/util/expression/ExpressionFactory";
import {HSPCTilesModel} from "@luciad/ria/model/tileset/HSPCTilesModel";
import {WMSTileSetLayer} from "@luciad/ria/view/tileset/WMSTileSetLayer";
import {WMSTileSetModel} from "@luciad/ria/model/tileset/WMSTileSetModel";
import {CoordinateReference} from "@luciad/ria/reference/CoordinateReference";
import {FeaturePainter} from "@luciad/ria/view/feature/FeaturePainter";
import {FeatureLayer} from "@luciad/ria/view/feature/FeatureLayer";
import {WFSFeatureStore} from "@luciad/ria/model/store/WFSFeatureStore";
import {Store} from "@luciad/ria/model/store/Store";
import {eq as eqOGC, literal, property} from "@luciad/ria/ogc/filter/FilterFactory";
import {FeatureModel} from "@luciad/ria/model/feature/FeatureModel";
import {WMSCapabilities} from "@luciad/ria/model/capabilities/WMSCapabilities";
import {Codec} from "@luciad/ria/model/codec/Codec";
import {LoadingStrategy} from "@luciad/ria/view/feature/loadingstrategy/LoadingStrategy";
import {
    FeatureLayerWithOptions,
    LayerOptions,
    TileSet3DLayerWithOptions,
    WMSTileSetLayerWithOptions
} from "./LayerOptions";
import {Simulation} from "../simulation/Simulation";
import {SimulationLayer2D} from "../simulation/view/SimulationLayer2D";
import {ISimulationLayer} from "../simulation/view/ISimulationLayer";
import {SimulationLayer3D} from "../simulation/view/SimulationLayer3D";
import {MemoryStore} from "@luciad/ria/model/store/MemoryStore";
import {getReference} from "@luciad/ria/reference/ReferenceProvider";
import {GeoJsonCodec} from "@luciad/ria/model/codec/GeoJsonCodec";
import {Bounds} from "@luciad/ria/shape/Bounds";
import {createBounds, createExtrudedShape} from "@luciad/ria/shape/ShapeFactory";
import {ISimulationPolygonProperties} from "../simulation/model/ISimulationPolygon";

interface WMSTileSetLayerInput extends LayerOptions {
    url: string,
    layers: string[],
    style?: string,
    reference?: CoordinateReference,
    hasTimeDimension?: boolean
}

export function createWMSTileSetLayer( input: WMSTileSetLayerInput ): Promise<WMSTileSetLayer> {
    return WMSCapabilities.fromURL( input.url )
        .then( capabilities => {
                   const wmsLayers = input.layers.map( layer => ({layer, style: input.style}) );

                   const modelOptions = {reference: input.reference, getMapRoot: input.url};
                   const model = WMSTileSetModel.createFromCapabilities( capabilities, wmsLayers, modelOptions );
                   const options = {
                       id: input.id,
                       label: input.label,
                       visible: input.visible,
                   };

                   const layerWithOptions = new WMSTileSetLayerWithOptions( model, options );
                   layerWithOptions.hideInLayerTree = input.hideInLayerTree;
                   layerWithOptions.hideVisibilityToggle = input.hideVisibilityToggle;
                   layerWithOptions.hideFit = input.hideFit;
                   return layerWithOptions
               }
        );
}

export async function create3DTileLayer( input: {
    url: string,
} & TileSet3DLayerConstructorOptions & LayerOptions ): Promise<TileSet3DLayer> {
    if ( input.url.endsWith( "tileset.json" ) ) {
        const model = await OGC3DTilesModel.create( input.url )

        const layerWithOptions = new TileSet3DLayerWithOptions( model, input );
        layerWithOptions.hideInLayerTree = input.hideInLayerTree;
        layerWithOptions.hideVisibilityToggle = input.hideVisibilityToggle;
        layerWithOptions.hideFit = input.hideFit;
        return layerWithOptions
    }
    else {
        const classification = attribute( "CLASSIFICATION" );
        const model = await HSPCTilesModel.create( input.url )
        const hspcOptions = {
            ...input,
            qualityFactor: 1.3,
            isPartOfTerrain: input.isPartOfTerrain,
            loadingStrategy: TileLoadingStrategy.DETAIL_FIRST,
            pointCloudStyle: {
                colorExpression: cases( color( 'rgb(0,0,0)' ) )
                    .when( eq( number( 1 ), classification ) ).then( color( '#f1eef6' ) )
                    .when( eq( number( 2 ), classification ) ).then( color( '#fee391' ) )
                    .when( eq( number( 3 ), classification ) ).then( color( '#a1d99b' ) )
                    .when( eq( number( 4 ), classification ) ).then( color( '#31a354' ) )
                    .when( eq( number( 5 ), classification ) ).then( color( '#006d2c' ) )
                    .when( eq( number( 6 ), classification ) ).then( color( '#f03b20' ) )
                    .when( eq( number( 7 ), classification ) ).then( color( '#fcbba1' ) )
                    .when( eq( number( 8 ), classification ) ).then( color( '#38F9D5' ) )
                    .when( eq( number( 9 ), classification ) ).then( color( '#0868ac' ) )
                    .when( eq( number( 10 ), classification ) ).then( color( '#FF7C6A' ) )
                    .when( eq( number( 11 ), classification ) ).then( color( '#e34a33' ) )
                    .when( eq( number( 12 ), classification ) ).then( color( '#384093' ) )
                    .when( eq( number( 13 ), classification ) ).then( color( '#bcbddc' ) )
                    .when( eq( number( 14 ), classification ) ).then( color( '#756bb1' ) )
                    .when( eq( number( 15 ), classification ) ).then( color( '#54278f' ) )
            }
        };
        const layerWithOptions = new TileSet3DLayerWithOptions( model, hspcOptions );
        layerWithOptions.hideInLayerTree = input.hideInLayerTree;
        layerWithOptions.hideVisibilityToggle = input.hideVisibilityToggle;
        layerWithOptions.hideFit = input.hideFit;
        return layerWithOptions
    }
}

export function createWFSLayer( input: {
    url: string,
    featureTypeName: string,
    featureIdProperty?: string,
    selectable: boolean,
    painter?: FeaturePainter,
    reference?: CoordinateReference,
    codec?: Codec,
    loadStrategy?: LoadingStrategy,
    minScale?: number
} & LayerOptions ): Promise<FeatureLayer> {
    return WFSFeatureStore.createFromURL( input.url, input.featureTypeName, {
        reference: input.reference,
        serviceURL: input.url,
        codec: input.codec
    } )
        .then( store => {
            if ( input.featureIdProperty ) {
                (store as Store).get = ( id: number | string ) => {
                    //Prevent having to know the id property name when querying this model
                    const promise = store.query( {filter: eqOGC( property( input.featureIdProperty! ), literal( id ) )} );
                    return new Promise( resolve => {
                        promise.then( cursor => {
                            if ( cursor.hasNext() ) {
                                resolve( cursor.next() );
                            }
                            else {
                                return undefined;
                            }
                        } );
                    } )
                };
            }

            const model = new FeatureModel( store );
            const layerWithOptions = new FeatureLayerWithOptions( model, input );
            layerWithOptions.hideInLayerTree = input.hideInLayerTree;
            layerWithOptions.hideVisibilityToggle = input.hideVisibilityToggle;
            layerWithOptions.hideFit = input.hideFit;
            return layerWithOptions
        } );
}

export function createSimulationLayer( simulation: Simulation ): Promise<ISimulationLayer> {
    return simulation.threeD ?
           createSimulationLayer3D( simulation ) :
           createSimulationLayer2D( simulation );
}

function createSimulationLayer2D( simulation: Simulation ): Promise<SimulationLayer2D> {
    return WMSCapabilities.fromURL( simulation.url )
        .then( capabilities => {
            const wmsLayers = [{layer: simulation.name}];
            const modelOptions = {getMapRoot: simulation.url};
            const model = WMSTileSetModel.createFromCapabilities( capabilities, wmsLayers, modelOptions );

            return new SimulationLayer2D( model, capabilities );
        } );
}

function createSimulationLayer3D( simulation: Simulation ): Promise<SimulationLayer3D> {
    const reference = getReference( "EPSG:25830" );
    const store = new MemoryStore( {reference} );

    return fetch( simulation.url )
        .then( res => res.json() )
        .then( json => {
            const bounds = createSimulation3DBounds( reference, json.bbox );
            const model = new FeatureModel( store, {reference, bounds: bounds as Bounds} );

            const geoJsonCodec = new GeoJsonCodec( {reference} );
            const featureCursor = geoJsonCodec.decodeObject( json );
            while ( featureCursor.hasNext() ) {
                const feature = featureCursor.next();
                const properties = feature.properties as ISimulationPolygonProperties;
                properties.height = Math.random() < 0.5 ? 1.0 : 2.0;
                const baseShape = feature.shape;
                feature.shape = createExtrudedShape( reference, baseShape!, properties.baseHeight, properties.baseHeight + properties.height );
                model.add( feature );
            }

            return new SimulationLayer3D( model );
        } );
}

function createSimulation3DBounds( reference: CoordinateReference, geoJsonBBOX: number[] ): Bounds {
    const x1 = geoJsonBBOX[0];
    const y1 = geoJsonBBOX[1];

    const x2 = geoJsonBBOX[2];
    const y2 = geoJsonBBOX[3];

    return createBounds( reference, [x1, x2 - x1, y1, y2 - y1] );
}
