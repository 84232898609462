import {useMapContext} from "../common/hooks/useMapContext";
import React, {useEffect} from "react";
import {LayerGroup} from "@luciad/ria/view/LayerGroup";
import {create3DTileLayer, createWMSTileSetLayer} from "./LayerFactory";
import {LTSCapabilities} from "@luciad/ria/model/capabilities/LTSCapabilities";
import {FusionTileSetModel} from "@luciad/ria/model/tileset/FusionTileSetModel";
import {RasterTileSetLayer} from "@luciad/ria/view/tileset/RasterTileSetLayer";
import {ISettings, useSettings} from "../settings/Settings";
import {LayerTreeNode} from "@luciad/ria/view/LayerTreeNode";
import {LayerTreeVisitor} from "@luciad/ria/view/LayerTreeVisitor";
import {TileSet3DLayer} from "@luciad/ria/view/tileset/TileSet3DLayer";
import {attribute, boolean, eq, number, or} from "@luciad/ria/util/expression/ExpressionFactory";
import {Layer} from "@luciad/ria/view/Layer";
import VisitOrder = LayerTreeNode.VisitOrder;

export const GeneralLayers = () => {
    const map = useMapContext().map;
    const [settings, _] = useSettings();

    useEffect( () => {
        const generalGroup = new LayerGroup( {id: "general", label: "General", visible: true} );
        map.layerTree.addChild( generalGroup )
        addTerrainLayer( generalGroup )
            .then( () => addPleaiadesLayers( generalGroup ) )
            .then( () => addCOG( generalGroup ) )
            .then( () => addPointcloudLayer( generalGroup ) )
            .then( () => applySettings() )
    }, [map] );

    useEffect( () => {
        applySettings();
    }, [settings] )

    function applySettings() {
        map.layerTree.visitChildren( new SettingsApplier( settings ), VisitOrder.TOP_DOWN );
    }

    function addTerrainLayer( generalGroup: LayerGroup ) {
        return LTSCapabilities.fromURL( "https://campo-cartagena-gemelo-digital-luciadfusion.es/lts/mdt01_campo_de_cartagena" )
            .then( capabilities => {
                const coverage = capabilities.coverages[0];
                const model = FusionTileSetModel.createFromCapabilities( capabilities, coverage.id );
                const layer = new RasterTileSetLayer( model, {
                    id: "terrain_cartagena",
                    label: "MDT Campo Cartagena",
                    visible: true
                } );
                generalGroup.addChild( layer );
            } );
    }

    function addPleaiadesLayers( generalGroup: LayerGroup ) {
        const pleiadesGroup = new LayerGroup( {id: "Pleiades Neo", label: "Pleiades Neo", visible: false} );
        generalGroup.addChild( pleiadesGroup );
        createWMSTileSetLayer( {
                                   url: "https://campo-cartagena-gemelo-digital-luciadfusion.es/ogc/wms/jp2_pleiades_neo_cn",
                                   layers: ["55f3b9dc_39de_4940_882f_1c5a48cad502"],
                                   visible: false,
                                   id: "Pleiades_Neo_CN",
                                   label: "Pleiades Neo CN",
                               } ).then( layer => pleiadesGroup.addChild( layer ) );

        createWMSTileSetLayer( {
                                   url: "https://campo-cartagena-gemelo-digital-luciadfusion.es/ogc/wms/jp2-pleiades_neo_ir",
                                   layers: ["4f56a00d_a58a_403a_9797_e906edbc2e7b"],
                                   visible: false,
                                   id: "Pleiades_Neo_IR",
                                   label: "Pleiades Neo IR",
                               } ).then( layer => pleiadesGroup.addChild( layer ) );
    }

    function addCOG( generalGroup: LayerGroup ) {
        return createWMSTileSetLayer( {
                                          url: "https://campo-cartagena-gemelo-digital-luciadfusion.es/ogc/wms/cog_general",
                                          layers: ["cog_general"],
                                          visible: true,
                                          id: "OF_EXPEDITA_COG_MTN25_con_censuras_WMS",
                                          label: "COG TM25 con censura",
                                      } ).then( layer => generalGroup.addChild( layer ) );
    }

    function addPointcloudLayer( generalGroup: LayerGroup ) {
        return create3DTileLayer( {
                                      id: "general_pointcloud",
                                      label: "LiDAR",
                                      url: "https://hxdr-data-raw-items-eu-west-1.s3.eu-west-1.amazonaws.com/SIG-Spain/Cartagena-Digital-Twin/Pointcloud/HSPC/tree.hspc",
                                      isDrapeTarget: false,
                                      isPartOfTerrain: false,
                                      visible: false
                                  } ).then( layer => generalGroup.addChild( layer ) );
    }

    return <span/>
}

class SettingsApplier implements LayerTreeVisitor {

    private readonly _visibilityExp;

    constructor( settings: ISettings ) {
        this._visibilityExp = boolean( false );
        const classification = attribute( "CLASSIFICATION" );

        for ( let accepted of settings.pointCloudClasses ) {
            this._visibilityExp = or( this._visibilityExp, eq( classification, number( accepted ) ) );
        }
    }

    visitLayer( layer: Layer ): LayerTreeVisitor.ReturnValue {
        if ( layer instanceof TileSet3DLayer ) {
            layer.pointCloudStyle.visibilityExpression = this._visibilityExp
        }
        return LayerTreeVisitor.ReturnValue.CONTINUE;
    }

    visitLayerGroup( layerGroup: LayerGroup ): LayerTreeVisitor.ReturnValue {
        layerGroup.visitChildren( this, VisitOrder.TOP_DOWN );
        return LayerTreeVisitor.ReturnValue.CONTINUE;
    }
}
