import React, {KeyboardEvent, useState} from "react";
import {ColorMapEntry, ColorMapPicker, isValidColor, isValidValue} from "../../../util/color_map_picker/ColorMapPicker";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography} from "@mui/material";
import {Inline} from "@digitalreality/ui";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

interface UpwellingCampaignFormProps {
    fileToUpload: File,
    onCancel: () => void,
    onAccept: ( name: string, date: Date, file: File, colorMap: ColorMapEntry[] ) => void
}

export function UpwellingCampaignForm( {fileToUpload, onCancel, onAccept}: UpwellingCampaignFormProps ) {

    const [name, setName] = useState<string>( "" );
    const [date, setDate] = useState<dayjs.Dayjs | null>( dayjs( new Date().toISOString() ) );
    const [error, setError] = useState<string | undefined>( undefined );

    const [colorMapEntries, setColorMapEntries] = useState<ColorMapEntry[]>( [] );

    function consumeEvent( event: KeyboardEvent<HTMLInputElement> ) {
        event.stopPropagation();
    }

    function acceptIfValid() {
        if ( !date || !date.isValid() ) {
            setError( "La fecha es inválida" );
            return;
        }

        if ( name.length === 0 ) {
            setError( "El nombre no puede estar vacío" )
            return;
        }

        if ( colorMapEntries.length < 2 ) {
            setError( "La rampa de colores debe tener al menos dos entradas" )
            return;
        }

        for ( let i = 0; i < colorMapEntries.length; i++ ) {
            if ( !isValidValue( i, colorMapEntries ) ) {
                setError( "Los valores de la rampa de colores son incorrectos" )
                return;
            }
            if ( !isValidColor( i, colorMapEntries ) ) {
                setError( "No pueden repetirse dos colores consecutivamente" )
                return;
            }
        }

        const startDate = date.toDate();
        const startDateUTC = Date.UTC( startDate.getUTCFullYear(), startDate.getUTCMonth(),
                                       startDate.getUTCDate(), startDate.getUTCHours(),
                                       startDate.getUTCMinutes(), startDate.getUTCSeconds() );
        onAccept( name, new Date( startDateUTC ), fileToUpload, colorMapEntries );
    }

    return (
        <div onKeyUp={consumeEvent} onKeyDown={consumeEvent}>
            <Dialog open={true} onClose={onCancel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Subir campaña"}</DialogTitle>
                <DialogContent sx={{overflow: "hidden"}}>
                    <Inline spacing={"8px"}>
                        <Stack gap="12px" sx={{width: "450px", height: "425px", paddingTop: "8px"}}>
                            <TextField label="Nombre" value={name} onChange={event => setName( event.target.value )}
                                       required/>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es"}>
                                <DateTimePicker label="Fecha campaña*" value={date}
                                                onChange={newDate => setDate( newDate )}/>
                            </LocalizationProvider>
                            <TextField label="Archivo" value={fileToUpload.name} disabled/>
                        </Stack>
                        <ColorMapPicker title={"Rampa de colores"}
                                        colorMapEntries={colorMapEntries} setColorMapEntries={setColorMapEntries}/>
                    </Inline>
                    {error && <Typography color={"red"}>{error}</Typography>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancelar</Button>
                    <Button onClick={acceptIfValid}>Aceptar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}