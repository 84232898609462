import React, {useEffect, useRef} from "react";
import {Map} from "@luciad/ria/view/Map";
import {Simulation} from "../simulation/Simulation";
import {createSimulationLayer} from "./LayerFactory";
import {SimulationLayerTreeVisitor} from "../simulation/SimulationLayerTreeVisitor";
import {TimeDimensions} from "../simulation/model/TimeDimensions";
import {useSimulationContext} from "../simulation/SimulationContext";
import {ISimulationLayer} from "../simulation/view/ISimulationLayer";
import {Simulate} from "react-dom/test-utils";
import abort = Simulate.abort;

interface SimulationLayerProps {
    simulation: Simulation | undefined,
    map: Map,
    onLoad: () => void,
    onError: () => void
}

function findTimeDimensionLayer( map: Map ): ISimulationLayer | undefined {
    const visitor = new SimulationLayerTreeVisitor();
    map.layerTree.accept( visitor );
    return visitor.layer;
}

export const SimulationLayer = ( {simulation, map, onLoad, onError}: SimulationLayerProps ) => {
    const [_, setSimulationContext] = useSimulationContext();
    const disabledRef = useRef<boolean>( true );

    useEffect( () => {
        if ( !map || !simulation ) {
            disabledRef.current = true;
            return;
        }
        disabledRef.current = false;

        addSimulationLayer( simulation, map )
            .then( layer => {
                if ( layer === undefined ) {
                    return;
                }

                map.mapNavigator.fit( {bounds: layer.bounds, animate: true} )
                const modelDescriptor = layer.timeDimensions as TimeDimensions;
                setSimulationContext( {
                                          activeSimulation: simulation,
                                          startTimeUnixEpoch: modelDescriptor.startDate.getTime(),
                                          endTimeUnixEpoch: modelDescriptor.endDate.getTime(),
                                          intervalMilliSeconds: modelDescriptor.intervalMilliSeconds
                                      } )
            } )
            .catch( error => {
                console.warn( error );
                onError();
            } )
            .finally( () => onLoad() );
        return () => {
            const oldSimulation = findTimeDimensionLayer( map );
            if ( oldSimulation ) {
                map.layerTree.removeChild( oldSimulation );
            }
            setSimulationContext( undefined );
        }
    }, [simulation] );

    function addSimulationLayer( simulation: Simulation, map: Map ): Promise<ISimulationLayer | undefined> {
        return createSimulationLayer( simulation )
            .then( layer => {
                if ( disabledRef.current ) {
                    return undefined;
                }
                map.layerTree.addChild( layer );
                return layer;
            } );
    }

    return <span/>;

}