import React, {ComponentProps, useEffect, useState} from 'react';
import {
    Absolute,
    Card,
    Drawer,
    DrawerActions,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerTitle,
    IconButton,
    MenuItem,
    Select,
    SolidToggle,
    SolidToggleGroup,
    Stack,
    Text,
    useTheme,
} from '@digitalreality/ui';
import {MeasurementConfirmationModal} from './MeasurementConfirmationModal';
import {Measurement, MeasurementType,} from '../common/ruler3d/measurement/Measurement';
import {Handle} from '@luciad/ria/util/Evented';
import {Map} from '@luciad/ria/view/Map';
import {
    CURRENT_MEASUREMENT_TYPE_CHANGED_EVENT,
    MEASUREMENT_WRAPPERS_CHANGED_EVENT,
    MeasurementMode,
    MeasurementSupport,
    MeasurementWrapper,
    PENDING_MEASUREMENT_CHANGED_EVENT,
} from './MeasurementSupport';
import {create3DCameraAnimation} from '../common/controller/animation/Move3DCameraAnimation';
import {PerspectiveCamera} from '@luciad/ria/view/camera/PerspectiveCamera';
import {AnimationManager} from '@luciad/ria/view/animation/AnimationManager';
import {MeasurementCard} from './MeasurementCard';
import {OverflowMenuHorizontalIcon, RulerAltIcon, Select02Icon, Select03Icon,} from '@digitalreality/ui-icons';
import {useControllerContext} from "../navigation/ControllerContext";

interface Props extends ComponentProps<typeof Drawer> {
    map: Map;
    support: MeasurementSupport;
}

/**
 * Side panel that shows persisted measurements and allows users to create new measurements.
 */
export const MeasurementDrawer = ( {
                                       open,
                                       onClose,
                                       map,
                                       support,
                                       ...rest
                                   }: Props ) => {
    const [currentMeasurementType, setCurrentMeasurementType] = useState<MeasurementType | null>( null );
    const [selectedMode, setSelectedMode] = useState<string>( MeasurementMode.CLOSEST_SURFACE );
    const [measurementWrappers, setMeasurementWrappers] = useState<MeasurementWrapper[]>( [] );
    const [pendingMeasurement, setPendingMeasurement] = useState<Measurement | null>( null );
    const [optionsVisible, setOptionsVisible] = useState<boolean>( false );
    const theme = useTheme();

    let [controllerContext,] = useControllerContext();

    useEffect( () => {
        const handles = [] as Handle[];

        setMeasurementWrappers( support.measurementWrappers );
        handles.push(
            support.on(
                MEASUREMENT_WRAPPERS_CHANGED_EVENT,
                ( wrappers: MeasurementWrapper[] ) => setMeasurementWrappers( [...wrappers] )
            )
        );

        setCurrentMeasurementType( support.currentMeasurementType );
        handles.push( support.on( CURRENT_MEASUREMENT_TYPE_CHANGED_EVENT, setCurrentMeasurementType ) );

        setPendingMeasurement( support.pendingMeasurement );
        handles.push( support.on( PENDING_MEASUREMENT_CHANGED_EVENT, setPendingMeasurement ) );

        return () => {
            for ( const handle of handles ) {
                handle.remove();
            }
        };
    }, [support] );

    useEffect( () => {
        return () => {
            if ( open ) {
                support.stopMeasurement();
            }
        };
    }, [open] );

    useEffect( () => {
        setOptionsVisible( false );
        if ( currentMeasurementType ) {
            support.startMeasurement(
                currentMeasurementType,
                selectedMode as MeasurementMode
            );
        }
    }, [selectedMode] );

    function fitOn( {fitPosition, measurement}: MeasurementWrapper ) {
        if ( controllerContext.threeD ) {
            AnimationManager.putAnimation(
                map.cameraAnimationKey,
                create3DCameraAnimation(
                    map,
                    (map.camera as PerspectiveCamera).lookFrom( fitPosition ),
                    3000
                ),
                false
            );
        }
        else {
            map.mapNavigator.fit( {bounds: measurement.bounds, animate: true} )
        }
    }

    return (
        <>
            <Drawer variant="persistent" open={open} {...rest}>
                <DrawerContent pl={theme.sizes.navbar} width={380}>
                    <DrawerHeader>
                        <DrawerTitle icon={<RulerAltIcon/>}>Mediciones</DrawerTitle>
                        <DrawerActions>
                            <DrawerCloseButton
                                onClick={( e ) => onClose?.( e, 'backdropClick' )}
                            />
                        </DrawerActions>
                    </DrawerHeader>
                    <DrawerBody marginLeft="8px" marginTop="8px">
                        <Stack gap="8px" width="calc(100% - 20px)">
                            {measurementWrappers.map( ( wrapper ) => (
                                <MeasurementCard
                                    key={wrapper.id}
                                    measurementWrapper={wrapper}
                                    onFit={() => fitOn( wrapper )}
                                    onRemove={() => support.removeMeasurementWrapper( wrapper.id )}
                                    expanded={wrapper.expanded}
                                    onChangeExpanded={( expanded ) =>
                                        support.expand( wrapper.id, expanded )
                                    }
                                />
                            ) )}
                        </Stack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>

            {open && (
                <Absolute
                    bottom="37px"
                    left="50%"
                    padding="8px"
                    width="310px"
                    sx={{
                        transform: ' translate(-50%, 0)',
                        pointerEvents: 'auto',
                    }}
                >
                    <SolidToggleGroup
                        value={currentMeasurementType}
                        exclusive
                        onChange={( e, type ) =>
                            support.startMeasurement(
                                type as MeasurementType,
                                selectedMode as MeasurementMode
                            )
                        }
                    >
                        <SolidToggle value={MeasurementType.DISTANCE}>
                            <RulerAltIcon/>
                            Distancia
                        </SolidToggle>
                        <SolidToggle value={MeasurementType.ORTHOGONAL}>
                            <Select03Icon/>
                            Ortogonal
                        </SolidToggle>
                        <SolidToggle value={MeasurementType.AREA}>
                            <Select02Icon/>
                            Area
                        </SolidToggle>
                    </SolidToggleGroup>
                      <IconButton
                          size="small"
                          onClick={() => setOptionsVisible( !optionsVisible )}
                          sx={{position: 'absolute', top: '8px', right: '8px'}}
                      >
                        <OverflowMenuHorizontalIcon/>
                      </IconButton>
                      <Card
                          hidden={!optionsVisible}
                          sx={{
                              position: 'absolute',
                              bottom: '100px',
                              right: '8px',
                              padding: '8px',
                          }}
                      >
                        <Stack>
                          <Text>Modo</Text>
                          <Select
                              value={selectedMode}
                              onChange={( e ) => setSelectedMode( e.target.value )}
                          >
                              {Object.values( MeasurementMode ).map( ( value ) => (
                                  <MenuItem key={value} value={value}>
                                      {value}
                                  </MenuItem>
                              ) )}
                          </Select>
                        </Stack>
                      </Card>
                </Absolute>
            )}

            {pendingMeasurement && (
                <MeasurementConfirmationModal
                    onSave={( id, name ) => support.acceptPendingMeasurement( id, name )}
                    onCancel={() => support.rejectPendingMeasurement()}
                />
            )}
        </>
    );
};
