import React, {ReactElement, useEffect, useRef, useState} from "react";
import {Map} from "@luciad/ria/view/Map";
import {SimulationCard} from "./SimulationCard";
import {SimulationParser, SimulationToUpload} from "./SimulationParser";
import {AlertDialog} from "../util/AlertDialog";
import {SimulationForm} from "./SimulationForm";
import {Simulation} from "./Simulation";
import {CreateItemsDrawer} from "../util/create_item_drawer/CreateItemsDrawer";
import {SimulationLayer} from "../layers/SimulationLayer";
import {PlayOutlineIcon} from "@digitalreality/ui-icons";
import {ColorMapEntry} from "../util/color_map_picker/ColorMapPicker";
import {MESH_LAYER_ID} from "../layers/CartagenaLayers";
import {LayerGroup} from "@luciad/ria/view/LayerGroup";
import {
    createLoadLayerRequestEvent,
    LAYER_LOADED_EVENT_TYPE,
    LoadedLayerEventDetail
} from "../layers/LoadLayerEventFactory";
import {Inline, Text} from "@digitalreality/ui";
import {Loop} from "@mui/icons-material";
import {IconButton} from "@mui/material";

interface SimulationDrawerProps {
    open: boolean;
    onClose: () => void;
    map: Map;
    password: string;
}

const SERVER_ENDPOINT = "https://campo-cartagena-gemelo-digital-luciadfusion.es";

export function SimulationDrawer( {open, onClose, map, password}: SimulationDrawerProps ) {
    const simulationParser = new SimulationParser();

    const [simulations, setSimulations] = useState<Simulation[] | undefined>( undefined );
    const [selected, setSelected] = useState<Simulation | undefined>( undefined );

    const [parsingSimulation, setParsingSimulation] = useState<boolean>( false );
    const [simulationToUpload, setSimulationToUpload] = useState<SimulationToUpload | undefined>( undefined );
    const [uploadingSimulation, setUploadingSimulation] = useState<boolean>( false );

    const [error, setError] = useState<Error | undefined>( undefined );

    const [footerContent, setFooterContent] = useState<ReactElement | undefined>();
    const meshLayerVisibilityRef = useRef<boolean>( false );

    const [simulationBeingLoaded, setSimulationBeingLoaded] = useState<Simulation | undefined>( undefined );

    useEffect( () => refreshSimulations(), [] );

    function refreshSimulations() {
        const formData = new FormData()
        formData.append( "password", password );

        fetch( SERVER_ENDPOINT + "/simulation/all", {
            method: 'POST',
            body: formData,
        } ).then( response => response.json() )
            .then( responseJson => {
                const simulationsDTOs = responseJson as any[];
                setSimulations( simulationsDTOs.map( dto => ({
                    id: dto.id,
                    name: dto.name,
                    url: SERVER_ENDPOINT + dto.url,
                    threeD: dto.url.endsWith( ".json" )
                }) ) );
            } )
    }

    useEffect( () => {
        const meshLayerGroup = findMeshLayerGroup();
        if ( open ) {
            meshLayerVisibilityRef.current = meshLayerGroup.visible;
        }
        else {
            meshLayerGroup.visible = meshLayerVisibilityRef.current;
            setSelected( undefined );
        }
    }, [open] )

    useEffect( () => {
        setSimulationBeingLoaded( selected );

        if ( !selected ) {
            return;
        }
        const meshLayerGroup = findMeshLayerGroup();
        if ( selected.threeD ) {
            const isLoaded = meshLayerGroup.children.length !== 0;
            if ( !isLoaded ) {
                loadMeshLayer();
            }
            meshLayerGroup.visible = true;
        }
        else {
            meshLayerGroup.visible = meshLayerVisibilityRef.current;
        }
    }, [selected] )

    const findMeshLayerGroup = (): LayerGroup => {
        return map.layerTree.findLayerGroupById( MESH_LAYER_ID );
    }

    function loadMeshLayer() {
        dispatchEvent( createLoadLayerRequestEvent( MESH_LAYER_ID ) );
        const footer = <Inline>
            <Text variant={"body2"}>Cargando el Mesh 3D</Text>
            <IconButton className={"spin"} sx={{color: "#ffffff !important"}} size="small" disabled>
                <Loop fontSize="small"/>
            </IconButton>
        </Inline>;
        setFooterContent( footer );

        function listenToLoadedLayers( event: Event ) {
            const detail = (event as CustomEvent).detail as LoadedLayerEventDetail;
            if ( detail.layerId === MESH_LAYER_ID ) {
                setFooterContent( undefined );
                removeEventListener( LAYER_LOADED_EVENT_TYPE, listenToLoadedLayers );
            }
        }

        addEventListener( LAYER_LOADED_EVENT_TYPE, listenToLoadedLayers );
    }

    const handleDrop = ( e: React.DragEvent<HTMLDivElement> ) => {
        if ( simulationToUpload ) {
            return;
        }

        if ( uploadingSimulation ) {
            setError( new Error( "Ya se esta procesando otra simulación" ) )
            return;
        }

        const files = e.dataTransfer.files;
        setParsingSimulation( true );
        parseSimulation( files )
            .then( simulation => {
                setSimulationToUpload( simulation );
                setParsingSimulation( false );
            } );
    }

    function parseSimulation( files: FileList ): Promise<SimulationToUpload | undefined> {
        try {
            return simulationParser.parse( files )
                .catch( error => {
                    setError( error );
                    return undefined;
                } );
        } catch ( error ) {
            setError( error as Error );
            return new Promise( resolver => resolver( undefined ) );
        }
    }

    function createSimulationCard( s: Simulation ): ReactElement {
        return <SimulationCard key={s.id}
                               selected={selected !== undefined && selected.id === s.id}
                               loading={simulationBeingLoaded !== undefined && simulationBeingLoaded.id === s.id}
                               onClick={() => selected === s ? setSelected( undefined ) : setSelected( s )}
                               simulation={s}/>;
    }

    function errorDialog( error: Error ) {
        return <AlertDialog title={"Ha ocurrido un error"}
                            message={error.message}
                            onClose={() => setError( undefined )}/>;
    }

    function uploadForm( simulation: SimulationToUpload ) {
        return <SimulationForm simulation={simulation}
                               onCancel={() => setSimulationToUpload( undefined )}
                               onAccept={( zipFile, name, colorMap, startDate ) => uploadSimulation( zipFile, name, colorMap, startDate )}/>;
    }

    function uploadSimulation( zipFile: File, name: string, colorMap: ColorMapEntry[], startDate?: Date ) {
        if ( simulations!.filter( simulation => simulation.name === name ).length !== 0 ) {
            setError( new Error( "El nombre de la simulación debe ser único" ) )
            return;
        }

        if ( uploadingSimulation ) {
            setError( new Error( "Ya se esta procesando otra simulación" ) )
            return;
        }

        setSimulationToUpload( undefined );
        setUploadingSimulation( true );

        const formData = new FormData()
        formData.append( "zipFile", zipFile );
        formData.append( "name", name );
        formData.append( "colorMap", JSON.stringify( colorMap ) );
        formData.append( "password", password );
        if ( startDate ) {
            formData.append( "startDate", startDate.toISOString() );
        }

        fetch( SERVER_ENDPOINT + "/simulation/upload", {
            method: 'POST',
            body: formData,
            keepalive: true
        } ).then( response => {
            if ( response.ok ) {
                refreshSimulations();
            }
            else {
                response.json().then( responseJson => {
                    const message = responseJson.message as string;
                    setError( new Error( message.split( "reason phrase: " )[1] ) );
                } )
            }

            setUploadingSimulation( false );
        } ).catch( error => {
            setError( error )
            setUploadingSimulation( false );
        } )
    }

    return <>
        <CreateItemsDrawer title={"Simulaciones"}
                           headerIcon={<PlayOutlineIcon sx={{color: "#B5B5B5"}}/>}
                           open={open} onClose={onClose}
                           handleDrop={handleDrop}
                           error={error} errorDialogSupplier={errorDialog}
                           items={simulations} itemToCreate={simulationToUpload}
                           formToCreateSupplier={uploadForm} processingItem={parsingSimulation}
                           creatingItem={uploadingSimulation}
                           filter={( filterText, simulation ) => {
                               const name = simulation.name;
                               return name.toUpperCase().includes( filterText.toUpperCase() );
                           }}
                           itemCardSupplier={createSimulationCard}
                           footerContent={footerContent}/>
        <SimulationLayer simulation={selected} map={map}
                         onLoad={() => setSimulationBeingLoaded( undefined )}
                         onError={() => setError( new Error( "No ha sido posible cargar la simulación." ) )}/>
    </>
}