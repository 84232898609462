import {Map} from '@luciad/ria/view/Map';
import React, {useEffect, useRef, useState} from 'react';
import {Absolute, CircularProgress, Inline, Stack, Text, Tooltip,} from '@digitalreality/ui';
import {UndergroundCrossSectionController} from "../controller/UndergroundCrossSectionController";
import {useControllerContext} from "../../../navigation/ControllerContext";
import {MemoryStore} from "@luciad/ria/model/store/MemoryStore";
import {FeatureLayer} from "@luciad/ria/view/feature/FeatureLayer";
import {FeatureModel} from "@luciad/ria/model/feature/FeatureModel";
import {getReference} from "@luciad/ria/reference/ReferenceProvider";
import {Box, Divider, IconButton, List, ListItem, ListItemText} from '@mui/material';
import {LocateIcon, ResetIcon, RulerAltIcon} from '@digitalreality/ui-icons';
import {Feature} from "@luciad/ria/model/feature/Feature";
import {Polyline} from "@luciad/ria/shape/Polyline";
import {CARTESIAN_REFERENCE} from "../../../common/crosssection/util/CrossSectionMapUtil";
import {FeaturePainter} from "@luciad/ria/view/feature/FeaturePainter";
import {STYLE_GRID} from "../../../common/crosssection/util/CrossSectionDrawUtil";
import {WebGLMap} from "@luciad/ria/view/WebGLMap";
import {createPoint, createPolyline, createShapeList} from "@luciad/ria/shape/ShapeFactory";
import {createEllipsoidalGeodesy} from "@luciad/ria/geodesy/GeodesyFactory";
import {DrapeTarget} from "@luciad/ria/view/style/DrapeTarget";
import {GeoJsonCodec} from "@luciad/ria/model/codec/GeoJsonCodec";
import {ShapeList} from "@luciad/ria/shape/ShapeList";
import {DeleteOutlined, HelpOutline} from "@mui/icons-material";
import {
    CARTESIAN_MEASUREMENT_CHANGED_EVENT,
    CartesianMeasurement,
    CartesianRulerController,
    MeasureState
} from "../../../common/crosssection/CartesianRulerController";
import {Handle} from "@luciad/ria/util/Evented";
import {UndergroundPointController} from "../controller/UndergroundPointController";
import {CompositeController} from "@luciad/ria/view/controller/CompositeController";
import {UndergroundLinesPainter} from "../view/UndergroundLinesPainter";
import {ScaleIndicator} from "../../../navigation/ScaleIndicator";
import {PointLabelPosition} from "@luciad/ria/view/style/PointLabelPosition";

export const UNDERGROUND_MAIN_MAP_LINE_LAYER_ID = "UNDERGROUND_LINE_LAYER";
export const UNDERGROUND_MAIN_MAP_LINE_FEATURE_ID = "UNDERGROUND_LINE";

interface Props {
    mainMap: Map;
    campaignName: string;
}

export enum UndergroundLineId {
    "TERRAIN",
    "WATER",
    "GEOID"
}

const SERVER_END_POINT = "https://campo-cartagena-gemelo-digital-luciadfusion.es";

const CARTESIAN_GRID_LAYER_ID = "CARTESIAN_GRID";
const CARTESIAN_LINES_LAYER_ID = "CARTESIAN_LINES";

const GRID_LINES_FEATURE_ID = "GRID_FEATURE_ID";

export const UndergroundCrossSectionOverlay = ( {mainMap, campaignName}: Props ) => {

    const [_, setControllerContext] = useControllerContext();

    const cartesianMapNodeRef = useRef<HTMLDivElement>( null );
    const cartesianMapRef = useRef<Map>();
    const cartesianRulerControllerRef = useRef<CartesianRulerController | undefined>( undefined );
    const [mapScale, setMapScale] = useState<number>( 0 );

    const [crossSectionLine, setCrossSectionLine] = useState<Polyline | undefined>( undefined )
    const [hasMeasurement, setHasMeasurement] = useState<boolean>( false );
    const [showLegend, setShowLegend] = useState<boolean>( false );
    const [isBusy, setBusy] = useState<boolean>( false );

    const geodesy = createEllipsoidalGeodesy( mainMap.reference );
    const geoJsonCodec = new GeoJsonCodec();

    useEffect( () => {
        if ( !cartesianMapNodeRef.current ) {
            throw new Error( 'Unexpected error: Could not create the underground cross section view.' );
        }

        const cartesianMap = new WebGLMap( cartesianMapNodeRef.current, {
            reference: CARTESIAN_REFERENCE,
        } );
        cartesianMap.layerTree.addChild( createCartesianGridLayer() );
        cartesianMap.layerTree.addChild( createCartesianLinesLayer() );
        cartesianMap.on( 'MapChange', () => setMapScale( cartesianMap.mapScale[0] ) )
        cartesianMapRef.current = cartesianMap;

        const layer = createMainMapLayer();
        mainMap.layerTree.addChild( layer );

        setUndergroundController();

        return () => {
            mainMap.layerTree.removeChild( layer );
            setControllerContext( ( prev ) => ({...prev, interactionController: undefined}) );
            cartesianMap.controller = null;
            cartesianMap.destroy();
        }
    }, [] );

    function createCartesianGridLayer() {
        const cartesianGridModel = new FeatureModel( new MemoryStore(), {reference: CARTESIAN_REFERENCE} );
        const cartesianGridPainter = new FeaturePainter();
        cartesianGridPainter.paintBody = ( geoCanvas, _feature, shape ) => {
            geoCanvas.drawShape( shape, STYLE_GRID );
        }
        cartesianGridPainter.paintLabel = ( labelCanvas, _feature, shape, layer, map, paintState ) => {
            const gridLines = shape as ShapeList;
            for ( let i = 0; i < gridLines.shapeCount; i++ ) {
                const gridLine = gridLines.getShape( i ) as Polyline;
                const isVertical = gridLine.getPoint( 0 ).x === gridLine.getPoint( 1 ).x;
                const firstPoint = gridLine.getPoint( 0 );
                labelCanvas.drawLabel( (isVertical ? firstPoint.x : firstPoint.y) + "m", firstPoint, {
                    positions: isVertical ? PointLabelPosition.SOUTH : PointLabelPosition.WEST
                } );
            }
        }
        return new FeatureLayer( cartesianGridModel, {
            id: CARTESIAN_GRID_LAYER_ID,
            painter: cartesianGridPainter
        } );
    }

    function createCartesianLinesLayer() {
        const cartesianLinesModel = new FeatureModel( new MemoryStore(), {reference: CARTESIAN_REFERENCE} );
        return new FeatureLayer( cartesianLinesModel, {
            id: CARTESIAN_LINES_LAYER_ID,
            painter: new UndergroundLinesPainter()
        } );
    }

    function createMainMapLayer() {
        const store = new MemoryStore();
        const model = new FeatureModel( store, {reference: getReference( "CRS:84" )} );
        const painter = new FeaturePainter();
        painter.paintBody = ( geoCanvas, feature, shape, _layer, _map ) => {
            geoCanvas.drawShape( feature.shape!, {
                stroke: {color: "#00A2E8", width: 2},
                drapeTarget: DrapeTarget.TERRAIN
            } );
        }
        return new FeatureLayer( model, {id: UNDERGROUND_MAIN_MAP_LINE_LAYER_ID, painter} );
    }

    function setUndergroundController() {
        const controller = new UndergroundCrossSectionController();
        const handle = controller.on( "Deactivated", () => {
            handle.remove();

            const layer = mainMap.layerTree.findLayerById( UNDERGROUND_MAIN_MAP_LINE_LAYER_ID );
            if ( !layer ) {
                return;
            }

            const model = layer.model as FeatureModel;
            const feature = model.get( UNDERGROUND_MAIN_MAP_LINE_FEATURE_ID );
            if ( feature ) {
                setCrossSectionLine( (feature as Feature).shape as Polyline );
                setBusy( true );
            }
            else {
                setCrossSectionLine( undefined );
            }
        } );

        setControllerContext( ( prev ) => ({...prev, interactionController: controller}) );
    }

    useEffect( () => {
        const cartesianMap = cartesianMapRef.current;
        if ( !crossSectionLine || !cartesianMap ) {
            return;
        }

        const startPoint = crossSectionLine.getPoint( 0 );
        const endPoint = crossSectionLine.getPoint( 1 );
        const path = "/underground/crosssection" +
                     "/campaign/" + campaignName.replaceAll( " ", "_" ) +
                     "/start/" + startPoint.x + "/" + startPoint.y +
                     "/end/" + endPoint.x + "/" + endPoint.y;
        fetch( SERVER_END_POINT + path )
            .then( result => result.json() )
            .then( json => {

                addPolylineToCartesianMap( cartesianMap, json.terrain, UndergroundLineId.TERRAIN );
                addPolylineToCartesianMap( cartesianMap, json.undergroundWater, UndergroundLineId.WATER );
                addPolylineToCartesianMap( cartesianMap, json.geoid, UndergroundLineId.GEOID );

                const distanceM = geodesy.distance( startPoint, endPoint );
                const shapeList = calculateGridLines( distanceM, calculateMaxHeightM( JSON.parse( json.terrain ) ) );
                const layer = cartesianMap.layerTree.findLayerById( CARTESIAN_GRID_LAYER_ID );
                (layer.model as FeatureModel).put( new Feature( shapeList, {}, GRID_LINES_FEATURE_ID ) );

                cartesianMap.mapNavigator.constraints.limitBounds = null;
                cartesianMap.mapNavigator.fit( {bounds: shapeList.bounds!} )
                    .then( () => cartesianMap.mapNavigator.constraints.limitBounds = cartesianMap.mapBounds )
                    .then( () => cartesianMap.controller = new UndergroundPointController( startPoint, endPoint, mainMap ) )
                    .then( () => setBusy( false ) );
            } )
    }, [crossSectionLine] )

    function addPolylineToCartesianMap( cartesianMap: Map, geoJson: string, id: UndergroundLineId ) {
        const cartesianLinesLayer = cartesianMap.layerTree.findLayerById( CARTESIAN_LINES_LAYER_ID );

        const undergroundWaterPolyline = geoJsonCodec.decodeGeometry( geoJson, CARTESIAN_REFERENCE );
        const feature = new Feature( undergroundWaterPolyline, {}, id );
        (cartesianLinesLayer.model as FeatureModel).put( feature );
    }

    function calculateGridLines( distanceM: number, maxHeightM: number ): ShapeList {
        const gridLines: Polyline[] = [];
        const width = distanceM;
        const height = maxHeightM + 50;

        const startPoint = createPoint( CARTESIAN_REFERENCE, [0, 0] );
        const gridSpacingX = 100;
        const gridSpacingY = 100;

        for ( let x = startPoint.x % gridSpacingX; x < startPoint.x + width; x += gridSpacingX ) {
            gridLines.push( createPolyline( CARTESIAN_REFERENCE, [[x, startPoint.y], [x, startPoint.y + height]] ) );
        }
        for ( let y = startPoint.y % gridSpacingY; y < startPoint.y + height; y += gridSpacingY ) {
            gridLines.push( createPolyline( CARTESIAN_REFERENCE, [[startPoint.x, y], [startPoint.x + width, y]] ) );
        }

        return createShapeList( CARTESIAN_REFERENCE, gridLines );
    }

    function calculateMaxHeightM( terrainGeoJson: { type: string, coordinates: number[][] } ): number {
        let max = -1;
        for ( let i = 0; i < terrainGeoJson.coordinates.length; i++ ) {
            const height = terrainGeoJson.coordinates[i][1];
            if ( max < height ) {
                max = height;
            }
        }
        return max;
    }

    function replaceLine() {
        setCrossSectionLine( undefined );
        setUndergroundController();
        const layer = mainMap.layerTree.findLayerById( UNDERGROUND_MAIN_MAP_LINE_LAYER_ID );
        (layer.model as FeatureModel).remove( UNDERGROUND_MAIN_MAP_LINE_FEATURE_ID );

        const cartesianMap = cartesianMapRef.current;
        if ( cartesianMap ) {
            const gridLayer = cartesianMap.layerTree.findLayerById( CARTESIAN_GRID_LAYER_ID );
            (gridLayer.model as FeatureModel).remove( GRID_LINES_FEATURE_ID );

            const model = cartesianMap.layerTree.findLayerById( CARTESIAN_LINES_LAYER_ID ).model as FeatureModel;
            model.remove( UndergroundLineId.WATER )
            model.remove( UndergroundLineId.TERRAIN )
            model.remove( UndergroundLineId.GEOID )
            cartesianMap.controller = null;
        }
    }

    function fitOnLine() {
        const layer = mainMap.layerTree.findLayerById( UNDERGROUND_MAIN_MAP_LINE_LAYER_ID );
        const feature = (layer.model as FeatureModel).get( UNDERGROUND_MAIN_MAP_LINE_FEATURE_ID ) as Feature;
        mainMap.mapNavigator.fit( {bounds: feature.shape!.bounds!, animate: true} );
    }

    function setCartesianRulerController() {
        const cartesianMap = cartesianMapRef.current;
        if ( !cartesianMap ) {
            return;
        }
        const rulerController = new CartesianRulerController();
        const handles = [] as Handle[];
        handles.push( rulerController.on( CARTESIAN_MEASUREMENT_CHANGED_EVENT, ( measurement: CartesianMeasurement ) => {
            if ( measurement.state === MeasureState.FULLY_PLACED ) {
                setHasMeasurement( true );
            }
        } ) );
        handles.push( rulerController.on( "Deactivated", () => {
            handles.forEach( handle => handle.remove() );
        } ) )

        const compositeController = new CompositeController();
        const startPoint = crossSectionLine!.getPoint( 0 );
        const endPoint = crossSectionLine!.getPoint( 1 );
        compositeController.appendController( new UndergroundPointController( startPoint, endPoint, mainMap ) );
        compositeController.appendController( rulerController );
        cartesianMap.controller = compositeController;
        cartesianRulerControllerRef.current = rulerController
    }

    function deleteCartesianMeasurement() {
        const cartesianRulerController = cartesianRulerControllerRef.current;
        if ( !cartesianRulerController ) {
            return;
        }
        // CartesianRulerController#reset seems to set the controller again on the map, fishy
        cartesianRulerController.reset();
        setHasMeasurement( false );
        const startPoint = crossSectionLine!.getPoint( 0 );
        const endPoint = crossSectionLine!.getPoint( 1 );
        cartesianMapRef.current!.controller = new UndergroundPointController( startPoint, endPoint, mainMap );
        cartesianRulerControllerRef.current = undefined;
    }

    return (
        <Absolute width="50%" height="35%" bottom="37px" left="50%"
                  sx={{transform: 'translate(-50%, 0)', pointerEvents: 'auto',}}>
            <Absolute bgcolor="grey.800" width="100%" height="100%" sx={{opacity: 0.8,}}/>
            {isBusy && (<CircularProgress size={128} sx={{
                position: 'absolute',
                left: 'calc(50% - 64px)',
                top: 'calc(50% - 64px)'
            }}/>)}
            <Inline left="50%" top="5px" alignItems="center"
                    sx={{position: 'absolute', transform: 'translate(-50%,0)',}}>
                <Tooltip title="Reemplazar el corte" placement="bottom">
                    <Box>
                        <IconButton size="small" onClick={replaceLine} disabled={!crossSectionLine || isBusy}>
                            <ResetIcon/>
                        </IconButton>
                    </Box>
                </Tooltip>
                <Tooltip title="Centrar en el corte" placement="bottom">
                    <Box>
                        <IconButton size="small" onClick={fitOnLine} disabled={!crossSectionLine}>
                            <LocateIcon/>
                        </IconButton>
                    </Box>
                </Tooltip>
                <Divider orientation="vertical" variant="middle" flexItem/>
                <Tooltip title="Medir" placement="bottom">
                    <Box>
                        <IconButton size="small" disabled={!crossSectionLine} onClick={setCartesianRulerController}>
                            <RulerAltIcon/>
                        </IconButton>
                    </Box>
                </Tooltip>
                <Tooltip title="Borrar la medición" placement="bottom">
                    <Box>
                        <IconButton size="small" onClick={deleteCartesianMeasurement} disabled={!hasMeasurement}>
                            <DeleteOutlined/>
                        </IconButton>
                    </Box>
                </Tooltip>
                <Tooltip title={showLegend ? "Esconder la leyenda" : "Mostrar la leyenda"} placement="bottom">
                    <Box>
                        <IconButton disabled={!crossSectionLine} onClick={() => setShowLegend( !showLegend )}
                                    sx={{color: showLegend ? "#5098A1" : "white"}} size="small">
                            <HelpOutline/>
                        </IconButton>
                    </Box>
                </Tooltip>
            </Inline>
            {showLegend && <Absolute right="16px" top="0px" zIndex="15">
              <List dense sx={{width: '270px', bgcolor: 'transparent'}}>
                <ListItem>
                  <ListItemText primary="Eje X" secondary="Distancia desde el inicio del corte (m)"/>
                </ListItem>
                <ListItem>
                  <ListItemText primary="Eje Y" secondary="Altura sobre el ellipsoide ETRS89 (m)"/>
                </ListItem>
              </List>
            </Absolute>}

            {!crossSectionLine &&
             <Stack bgcolor="grey.800" left="8px" top="40px" width="calc(100% - 16px)" height="calc(100% - 48px)"
                    justifyContent="center" alignItems="center" zIndex="20" sx={{position: 'absolute',}}>
               <Text sx={{textAlign: "center", userSelect: 'none'}}>
                 Haga click en cualquier parte del mapa para iniciar la creación del corte y
                 haga click de nuevo para definir la extensión
               </Text>
             </Stack>
            }
            <Absolute id={"cartesianMap"} ref={cartesianMapNodeRef} left="8px" top="40px" width="calc(100% - 16px)"
                      height="calc(100% - 48px)" zIndex="10"/>
            <Absolute right="16px" bottom="16px" width="20%" height="40px" zIndex="15">
                <ScaleIndicator mapScale={mapScale}/>
            </Absolute>
        </Absolute>
    );
};
