import {createContext, Dispatch, SetStateAction, useContext, useState} from "react";

import {Simulation} from "./Simulation";


export interface ISimulationContext {
    activeSimulation?: Simulation,
    startTimeUnixEpoch: number,
    endTimeUnixEpoch: number,
    intervalMilliSeconds: number
}

export type SimulationUpdate = Dispatch<SetStateAction<ISimulationContext | undefined>>;

export const SimulationContext = createContext<[ISimulationContext | undefined, SimulationUpdate]>( undefined! );

export const useSimulationContext = () => {
    return useContext( SimulationContext )
}

export const useSimulationState = (): [ISimulationContext | undefined, SimulationUpdate] => {
    const [controllerContext, setControllerContext] = useState<ISimulationContext | undefined>( undefined );
    return [controllerContext, setControllerContext]
}
