import {BasicFeaturePainter} from "@luciad/ria/view/feature/BasicFeaturePainter";
import {GeoCanvas} from "@luciad/ria/view/style/GeoCanvas";
import {Feature} from "@luciad/ria/model/feature/Feature";
import {Shape} from "@luciad/ria/shape/Shape";
import {Layer} from "@luciad/ria/view/Layer";
import {Map} from "@luciad/ria/view/Map";
import {PaintState} from "@luciad/ria/view/feature/FeaturePainter";
import {Point} from "@luciad/ria/shape/Point";
import {get3DIconStyle} from "../model/ThreeDObjectTypes";

export default class ThreeDObjectPainter extends BasicFeaturePainter {

    paintBody( aGeoCanvas: GeoCanvas,
               aFeature: Feature,
               aShape: Shape,
               aLayer: Layer,
               aMap: Map,
               aPaintState: PaintState ) {
        aGeoCanvas.drawIcon3D( aFeature.shape as Point, get3DIconStyle( aFeature, aPaintState.selected ) );
    }
}
