import {
    Drawer,
    DrawerActions,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerTitle,
    Stack,
    useTheme
} from "@digitalreality/ui";
import React, {useEffect, useRef} from "react";
import {Box, Divider} from "@mui/material";
import {ObliqueMap, Orientation} from "../map/ObliqueMap";
import {WebGLMap} from "@luciad/ria/view/WebGLMap";
import {FeatureModel} from "@luciad/ria/model/feature/FeatureModel";
import {MemoryStore} from "@luciad/ria/model/store/MemoryStore";
import {createArcBand, createCircleByCenterPoint, createPoint} from "@luciad/ria/shape/ShapeFactory";
import {Feature} from "@luciad/ria/model/feature/Feature";
import {FeatureLayer} from "@luciad/ria/view/feature/FeatureLayer";
import {Handle} from "@luciad/ria/util/Evented";
import {createTransformation} from "@luciad/ria/transformation/TransformationFactory";
import {getReference} from "@luciad/ria/reference/ReferenceProvider";
import {Point} from "@luciad/ria/shape/Point";
import {ObservableNumber} from "../util/ObservableNumber";
import {FeaturePainter, PaintState} from "@luciad/ria/view/feature/FeaturePainter";
import {GeoCanvas} from "@luciad/ria/view/style/GeoCanvas";
import {Shape} from "@luciad/ria/shape/Shape";
import {Layer} from "@luciad/ria/view/Layer";
import {Map} from "@luciad/ria/view/Map";
import {getUnitOfMeasure} from "@luciad/ria/uom/UnitOfMeasureRegistry";
import {useControllerContext} from "../navigation/ControllerContext";
import ObliqueController from "./ObliqueController";

interface ObliqueDrawerProps {
    open: boolean;
    onClose: () => void;
    map: WebGLMap;
}

const POINT_LAYER_ID = "POINT_LAYER";
const POINT_FEATURE_ID = "PointFeature";

export function ObliqueDrawer( props: ObliqueDrawerProps ) {

    const [, setControllerContext] = useControllerContext();
    const theme = useTheme();
    const handleRef = useRef<Handle | undefined>( undefined );
    const targetScale = new ObservableNumber( 0.24 );

    useEffect( () => {
        if ( props.open ) {
            addPointLayer( props.map );
            handleRef.current = addMainMapListener( props.map );
            setControllerContext( ( prev ) => ({
                ...prev,
                interactionController: new ObliqueController()
            }) )
        }
        else {
            const layerTree = props.map.layerTree;
            const layer = layerTree.findLayerById( POINT_LAYER_ID );
            if ( layer ) {
                layerTree.removeChild( layer );
            }

            if ( handleRef.current ) {
                handleRef.current.remove();
            }

            setControllerContext( ( prev ) => ({
                ...prev,
                interactionController: undefined
            }) )
        }
    }, [props.open] )

    function addPointLayer( map: WebGLMap ) {
        const reference = getReference( "CRS:84" );
        const pointModel = new FeatureModel( new MemoryStore( {reference: reference} ), {reference: reference} );
        const point = createPoint( reference, [] );
        const feature = new Feature( point, {}, POINT_FEATURE_ID );
        pointModel.put( feature )

        const pointLayer = new FeatureLayer( pointModel, {id: POINT_LAYER_ID, painter: new MapPointPainter( false )} );
        map.layerTree.addChild( pointLayer, "top" )
    }

    function addMainMapListener( mainMap: WebGLMap ): Handle {
        const toWgs = createTransformation( mainMap.reference, getReference( "CRS:84" ) );
        return mainMap.on( "idle", () => {
            const pointLayer = mainMap.layerTree.findLayerById( POINT_LAYER_ID ) as FeatureLayer;
            const pointModel = pointLayer.model;
            const feature = pointModel.get( POINT_FEATURE_ID ) as Feature;
            const point = feature.shape as Point;

            const center = mainMap.viewToMapTransformation.transform( createPoint( null, [mainMap.viewSize[0] / 2, mainMap.viewSize[1] / 2] ) );
            const wgsCenter = toWgs.transform( center )
            point.move3D( wgsCenter.x, wgsCenter.y );
            pointModel.put( feature );
        } )
    }

    const border = "1mm solid rgb(135,135,135)";
    return <Drawer variant="persistent" open={props.open} onClose={props.onClose}>
        <DrawerContent pl={theme.sizes.navbar} width={380}>
            <DrawerHeader>
                <DrawerTitle icon={<img src={"resources_ria/icons/oblique.png"} alt={"Imágenes oblicuas"} width={32}
                                        style={{filter: "brightness(0) saturate(100%) invert(83%) sepia(2%) saturate(0%) hue-rotate(71deg) brightness(90%) contrast(81%)"}}/>}>Imágenes
                    oblicuas</DrawerTitle>
                <DrawerActions>
                    <DrawerCloseButton tooltipLabel={"Cerrar panel"} onClick={props.onClose}/>
                </DrawerActions>
            </DrawerHeader>
            <DrawerBody sx={{overflow: "hidden !important"}} marginLeft="8px" marginTop="8px" marginRight="8px">
                <Stack width="100%">
                    <Box sx={{position: "relative", width: "100%", height: "23.5vh", border: border}}>
                        <ObliqueMap orientation={Orientation.NORTH}
                                    mainMap={props.map}
                                    active={props.open}
                                    targetScale={targetScale}/>
                    </Box>
                    <Divider/>
                    <Box sx={{position: "relative", width: "100%", height: "23.5vh", border: border}}>
                        <ObliqueMap orientation={Orientation.WEST}
                                    mainMap={props.map}
                                    active={props.open}
                                    targetScale={targetScale}/>
                    </Box>
                    <Divider/>
                    <Box sx={{position: "relative", width: "100%", height: "23.5vh", border: border}}>
                        <ObliqueMap orientation={Orientation.EAST}
                                    mainMap={props.map}
                                    active={props.open}
                                    targetScale={targetScale}/>
                    </Box>
                    <Divider/>
                    <Box sx={{position: "relative", width: "100%", height: "23.5vh", border: border}}>
                        <ObliqueMap orientation={Orientation.SOUTH}
                                    mainMap={props.map}
                                    active={props.open}
                                    targetScale={targetScale}/>
                    </Box>
                </Stack>
            </DrawerBody>
        </DrawerContent>
    </Drawer>
}

export class MapPointPainter extends FeaturePainter {
    private readonly _minRadius: number;
    private readonly _maxRadius: number;
    private readonly _borderWidth: number;

    constructor( obliqueMap: boolean ) {
        super();
        // Meters mean nothing on the oblique map
        this._minRadius = obliqueMap ? 350 : 20;
        this._maxRadius = obliqueMap ? 400 : 23;
        this._borderWidth = obliqueMap ? 10 : 0.5;
    }

    paintBody( geoCanvas: GeoCanvas, feature: Feature, shape: Shape, layer: Layer, map: Map, paintState: PaintState ) {
        const arc = createArcBand( shape.reference!, shape.focusPoint!, this._minRadius, this._maxRadius, 0, 360 );
        const internal = createCircleByCenterPoint( shape.reference!, shape.focusPoint!, this._minRadius );
        const external = createCircleByCenterPoint( shape.reference!, shape.focusPoint!, this._maxRadius );

        geoCanvas.drawShape( arc, {
            fill: {
                color: "rgba(255,0,0,0.5)",
            }
        } );
        geoCanvas.drawShape( internal, {
            stroke: {
                color: "rgb(0,0,0)",
                width: this._borderWidth,
                uom: getUnitOfMeasure( "Meter" )
            },
        } );
        geoCanvas.drawShape( external, {
            stroke: {
                color: "rgb(0,0,0)",
                width: this._borderWidth,
                uom: getUnitOfMeasure( "Meter" )
            }
        } )
    }
}