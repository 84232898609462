import {createCartesianReference} from "@luciad/ria/reference/ReferenceProvider";
import {createBounds} from "@luciad/ria/shape/ShapeFactory";
import {getUnitOfMeasure} from "@luciad/ria/uom/UnitOfMeasureRegistry";
import {color} from "@luciad/ria/util/expression/ExpressionFactory";
import {AxisConfiguration} from "@luciad/ria/view/axis/AxisConfiguration";
import {Layer} from "@luciad/ria/view/Layer";
import {LayerGroup} from "@luciad/ria/view/LayerGroup";
import {LayerTreeNode} from "@luciad/ria/view/LayerTreeNode";
import {LayerTreeVisitor} from "@luciad/ria/view/LayerTreeVisitor";
import {Map} from "@luciad/ria/view/Map";
import {TileLoadingStrategy, TileSet3DLayer} from "@luciad/ria/view/tileset/TileSet3DLayer";
import {WebGLMap} from "@luciad/ria/view/WebGLMap";

export const DEFAULT_SLICE_COLOR = '#0c9e98';
export const CARTESIAN_REFERENCE = createCartesianReference( {
                                                                 xUnitOfMeasure: getUnitOfMeasure( 'Meter' ),
                                                                 yUnitOfMeasure: getUnitOfMeasure( 'Meter' ),
                                                             } );

export const MIN_CARTESIAN_MAP_SCALE = 0.0008;
export const MAX_CARTESIAN_MAP_SCALE = 0.0512; //it's best if the max is a equal to the min times a power of 2

export const SLICE_MAP_FOV_Y = 2;

const AXES: AxisConfiguration = {
    axisLineStyle: {color: 'rgba(0,0,0,0)'},
};

/**
 * Create a new slice map, used to show a slice of the given mainMap's mesh layers.
 * Assuming that the depth of this slice is relatively thin, this slice can be seen as a cross-section.
 */
export async function createSliceMap( mainMap: Map, sliceMapNode: HTMLElement ): Promise<WebGLMap> {
    const sliceMap = new WebGLMap( sliceMapNode, {reference: mainMap.reference} );

    sliceMap.effects.antiAliasing = false;
    sliceMap.effects.atmosphere = false;
    sliceMap.effects.starfield = false;
    sliceMap.effects.light = null;
    sliceMap.globeColor = 'rgba(0, 0, 0, 0.0)';
    sliceMap.adjustDepthRange = false;
    sliceMap.camera = sliceMap.camera.copyAndSet( {fovY: SLICE_MAP_FOV_Y} );

    await sliceMap.layerTree.whenReady();

    loadMeshLayers( mainMap, sliceMap );

    return sliceMap;
}

/**
 * Copies all TileSet3DLayer layers from the mainMap to the sliceMap, with a solid color
 */
function loadMeshLayers( mainMap: Map, sliceMap: WebGLMap ) {
    const layerTreeVisitor: LayerTreeVisitor = {
        visitLayer: ( layer: Layer ): LayerTreeVisitor.ReturnValue => {
            if ( layer instanceof TileSet3DLayer && layer.visible ) {
                sliceMap.layerTree.addChild( createMeshLayerCopy( layer ), 'top' )
            }
            return LayerTreeVisitor.ReturnValue.CONTINUE;
        },
        visitLayerGroup( layerGroup: LayerGroup ): LayerTreeVisitor.ReturnValue {
            if ( layerGroup.visible ) {
                layerGroup.visitChildren( layerTreeVisitor, LayerTreeNode.VisitOrder.TOP_DOWN );
            }
            return LayerTreeVisitor.ReturnValue.CONTINUE;
        }
    };

    mainMap.layerTree.visitChildren( layerTreeVisitor, LayerTreeNode.VisitOrder.TOP_DOWN );
}

function createMeshLayerCopy( layer: TileSet3DLayer ): TileSet3DLayer {
    return new TileSet3DLayer( layer.model, {
        selectable: false,
        qualityFactor: layer.qualityFactor,
        fadingTime: 0,
        loadingStrategy: TileLoadingStrategy.DETAIL_FIRST,
        transformation: layer.transformation ?? undefined,
        meshStyle: {colorExpression: color( DEFAULT_SLICE_COLOR )},
        pointCloudStyle: layer.pointCloudStyle
    } )
}

/**
 * Creates a 2D cartesian map with axes defined in meter, which can be used to make measurements on.
 */
export async function createCartesianMap( containerNode: HTMLElement ): Promise<Map> {
    const cartesianMap = new WebGLMap( containerNode, {
        reference: CARTESIAN_REFERENCE,
        axes: { // defining axis prevents rotations on the map
            xAxis: AXES,
            yAxis: AXES,
        },
    } );
    // restrict map navigations
    cartesianMap.mapNavigator.constraints = {
        scale: {
            minScale: MIN_CARTESIAN_MAP_SCALE,
            maxScale: MAX_CARTESIAN_MAP_SCALE,
        },
    };
    cartesianMap.effects.antiAliasing = false;

    await cartesianMap.mapNavigator.fit( {
                                             bounds: createBounds( CARTESIAN_REFERENCE, [-40, 80, -20, 40] ),
                                             animate: false,
                                         } ).catch();

    return cartesianMap;
}
