import React, {useEffect, useState} from "react";
import {Layer} from "@luciad/ria/view/Layer";
import {createWMSTileSetLayer} from "./LayerFactory";
import {OwnedLayerOptions} from "./CartagenaLayers";
import {Endpoint} from "./Endpoint";

interface WMSEndpoint extends Endpoint {
    style?: string
}

export interface WMSEndpointSingleLayer extends WMSEndpoint {
    layer: string
    layers?: never
}

export interface WMSEndpointMultipleLayers extends WMSEndpoint {
    layer?: never
    layers: string []
}

export const WMSLayer = ( props: (WMSEndpointSingleLayer | WMSEndpointMultipleLayers) & OwnedLayerOptions ) => {
    const [layer, setLayer] = useState<Layer>()

    useEffect( () => {
        const id = props.layer ? props.url + props.layer : props.url;
        const layers = props.layer ? [props.layer] : props.layers!;
        createWMSTileSetLayer( {id, layers, ...props} )
            .then( newLayer => {
                props.parent?.addChild( newLayer );
                setLayer( newLayer );
                props.onLoad?.( props.url );
            } ).catch( () => {
            const errorMessage = props.layer ?
                                 "Could not load layer at " + props.url + " with layer being " + props.layer :
                                 "Could not load layers at " + props.url;
            console.error( errorMessage );
            props.onFailedLoad?.( props.url );
        } );

        return () => layer && props.parent?.removeChild( layer )
    }, [] )

    return <></>
}