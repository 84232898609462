import React, {useEffect, useRef, useState} from "react";
import {WebGLMap} from "@luciad/ria/view/WebGLMap";
import {getReference} from "@luciad/ria/reference/ReferenceProvider";
import {RiaMap} from "./RiaMap";
import {WMSTileSetLayer} from "@luciad/ria/view/tileset/WMSTileSetLayer";
import {WMSTileSetModel} from "@luciad/ria/model/tileset/WMSTileSetModel";
import {createBounds, createPoint} from "@luciad/ria/shape/ShapeFactory";
import {createTransformation} from "@luciad/ria/transformation/TransformationFactory";
import {FeatureLayer} from "@luciad/ria/view/feature/FeatureLayer";
import {FeatureModel} from "@luciad/ria/model/feature/FeatureModel";
import {MemoryStore} from "@luciad/ria/model/store/MemoryStore";
import {Feature} from "@luciad/ria/model/feature/Feature";
import {Point} from "@luciad/ria/shape/Point";
import {Handle} from "@luciad/ria/util/Evented";
import {ZoomController} from "@luciad/ria/view/controller/ZoomController";
import {CompositeController} from "@luciad/ria/view/controller/CompositeController";
import {NoopController} from "@luciad/ria/view/controller/NoopController";
import {OrthographicCamera} from "@luciad/ria/view/camera/OrthographicCamera";
import {ObservableNumber} from "../util/ObservableNumber";
import {MapPointPainter} from "../oblique/ObliqueDrawer";
import {Typography} from "@mui/material";
import {PerspectiveCamera} from "@luciad/ria/view/camera/PerspectiveCamera";
import compassIcon from "../common/components/overlay/compass.svg";

export enum Orientation {
    NORTH = "NORTH",
    EAST = "EAST",
    WEST = "WEST",
    SOUTH = "SOUTH"
}

enum CameraSide {
    Front = "FORWARD",
    Back = "BACKWARD",
    Left = "LEFT",
    Right = "RIGHT"
}

const SERVER_ENDPOINT = "https://campo-cartagena-gemelo-digital-luciadfusion.es";

const WEB_MERCATOR = getReference( "EPSG:3857" );
// We are using a tiled model, which makes that the tiles do not perfectly match the actual image.
const IMAGE_BOUNDS = createBounds( WEB_MERCATOR, [0, 14144, -10560, 10560] );

const OBLIQUE_LAYER_ID = "OBLIQUE_LAYER";
const POINT_LAYER_ID = "POINT_LAYER";
const POINT_FEATURE_ID = "PointFeature";

const IMAGE_NOT_AVAILABLE_MSG = "No hay imagen disponible para esta posición y orientación";
const MAIN_CAMERA_TOO_HIGH_MSG = "La cámara del mapa principal está demasiado lejos del suelo";
export const ObliqueMap = ( props: {
    orientation: Orientation,
    mainMap: WebGLMap,
    onMapReady?: ( map: WebGLMap ) => void,
    active: boolean,
    targetScale: ObservableNumber
} ) => {
    const nonGeoReferencedMapRef = useRef<WebGLMap | undefined>( undefined );
    const obliqueModelRef = useRef<WMSTileSetModel | undefined>( undefined );
    const handleRef = useRef<Handle | undefined>( undefined );
    const [infoMessage, setInfoMessage] = useState<string | undefined>( undefined );
    const [imageName, setImageName] = useState<string | undefined>( undefined );

    useEffect( () => {
        const targetScale = props.targetScale;
        const handle = targetScale.on( "Invalidated", () => {
            const nonGeoReferencedMap = nonGeoReferencedMapRef.current;
            if ( !nonGeoReferencedMap ) {
                return;
            }
            setTargetScale( nonGeoReferencedMap, targetScale.value );
        } );
        return () => {
            handle.remove();
        }
    }, [] )

    function setTargetScale( nonGeoReferencedMap: WebGLMap, scale: number ) {
        const camera = nonGeoReferencedMap.camera as OrthographicCamera;
        const look2D = camera.asLook2D();
        nonGeoReferencedMap.camera = camera.look2D( {
                                                        ...look2D,
                                                        scaleX: scale,
                                                        scaleY: scale,
                                                    } );
    }

    useEffect( () => {
        if ( props.active ) {
            handleRef.current = addMainMapListener( props.orientation, props.mainMap );
        }
        else if ( handleRef.current ) {
            handleRef.current.remove();
        }
    }, [props.active] );

    function addMainMapListener( side: Orientation, mainMap: WebGLMap ): Handle {
        const toWgs = createTransformation( mainMap.reference, getReference( "CRS:84" ) );
        const cameraMapPoint = createPoint( mainMap.reference, [0, 0] );
        const cameraModelPoint = createPoint( getReference( "CRS:84" ), [0, 0] );

        return mainMap.on( "idle", () => {
            const center = mainMap.viewToMapTransformation.transform( createPoint( null, [mainMap.viewSize[0] / 2, mainMap.viewSize[1] / 2] ) );
            const wgsCenter = toWgs.transform( center )
            console.debug( "Fetching oblique info for ", wgsCenter )

            const eye = (mainMap.camera as PerspectiveCamera).asLookFrom().eye;
            cameraMapPoint.move3D( eye.x, eye.y, eye.z );
            toWgs.transform( cameraMapPoint, cameraModelPoint )
            if ( cameraModelPoint.z > 2_000 ) {
                if ( nonGeoReferencedMapRef.current ) {
                    hidePointAndObliqueLayers( nonGeoReferencedMapRef.current );
                }
                setInfoMessage( MAIN_CAMERA_TOO_HIGH_MSG );
                return;
            }

            fetch( `${SERVER_ENDPOINT}/oblique/${side}/${wgsCenter.x}/${wgsCenter.y}` ).then( response => {
                return response.text();
            } ).then( responseText => {
                try {
                    return JSON.parse( responseText );
                } catch ( e ) {
                    return undefined;
                }
            } ).then( responseJson => {
                const nonGeoReferencedMap = nonGeoReferencedMapRef.current;
                if ( !nonGeoReferencedMap ) {
                    return;
                }

                if ( !responseJson ) {
                    hidePointAndObliqueLayers( nonGeoReferencedMap );
                    setInfoMessage( IMAGE_NOT_AVAILABLE_MSG );
                    return;
                }
                else {
                    const layerTree = nonGeoReferencedMap.layerTree;
                    const obliqueLayer = layerTree.findLayerById( OBLIQUE_LAYER_ID );
                    if ( obliqueLayer ) {
                        obliqueLayer.visible = true;
                    }
                    nonGeoReferencedMap.layerTree.findLayerById( POINT_LAYER_ID ).visible = true
                    setInfoMessage( undefined );
                }


                console.debug( "Oblique result ", responseJson )
                movePoint( nonGeoReferencedMap, responseJson );

                const imageName = responseJson.imageName as string;
                const serviceName = responseJson.serviceName;

                const switchingService = obliqueModelRef.current && !obliqueModelRef.current!.baseURL.includes( serviceName );
                if ( switchingService ) {
                    // Modifying the base URL at run time does not seem to work.
                    const layerTree = nonGeoReferencedMap.layerTree;
                    layerTree.removeChild( layerTree.findLayerById( OBLIQUE_LAYER_ID ) );
                    obliqueModelRef.current = undefined;
                }

                updateObliqueModel( imageName, serviceName );

                if ( !nonGeoReferencedMap.layerTree.findLayerById( OBLIQUE_LAYER_ID ) ) {
                    const layer = new WMSTileSetLayer( obliqueModelRef.current!, {id: OBLIQUE_LAYER_ID} );
                    nonGeoReferencedMap.layerTree.addChild( layer, "bottom" );
                }

                rotateIfNeeded( responseJson.side as CameraSide, nonGeoReferencedMap );
                setImageName( imageName.replaceAll( "_", " " ) );
            } )
        } )
    }

    function hidePointAndObliqueLayers( nonGeoReferencedMap: WebGLMap ) {
        const layerTree = nonGeoReferencedMap.layerTree;
        const obliqueLayer = layerTree.findLayerById( OBLIQUE_LAYER_ID );
        if ( obliqueLayer ) {
            obliqueLayer.visible = false;
        }
        nonGeoReferencedMap.layerTree.findLayerById( POINT_LAYER_ID ).visible = false
    }

    function movePoint( nonGeoReferencedMap: WebGLMap, response: any ) {
        const pointLayer = nonGeoReferencedMap.layerTree.findLayerById( POINT_LAYER_ID ) as FeatureLayer;
        const pointModel = pointLayer.model;
        const feature = pointModel.get( POINT_FEATURE_ID ) as Feature;
        const point = feature.shape as Point;

        const bounds = IMAGE_BOUNDS;
        const width = bounds.width;
        const height = bounds.height;
        point.move2D( (response.centerU) * width, (1 - response.centerV) * -height );

        pointModel.put( feature );
        adjustCameraLocation( nonGeoReferencedMap, point );
    }

    function adjustCameraLocation( nonGeoReferencedMap: WebGLMap, point: Point ) {
        const camera = nonGeoReferencedMap.camera as OrthographicCamera;
        const look2D = camera.asLook2D();
        nonGeoReferencedMap.camera = camera.look2D( {
                                                        ...look2D,
                                                        worldOrigin: {x: point.x, y: point.y, z: 0}
                                                    } );

    }

    function updateObliqueModel( imageName: string, serviceName: undefined ) {
        if ( obliqueModelRef.current ) {
            const obliqueModel = obliqueModelRef.current;
            obliqueModel.baseURL = `${SERVER_ENDPOINT}/ogc/wms/${serviceName}`;
            obliqueModel.layers = [imageName];
        }
        else {
            obliqueModelRef.current = new WMSTileSetModel( {
                                                               getMapRoot: `${SERVER_ENDPOINT}/ogc/wms/${serviceName}`,
                                                               layers: [imageName],
                                                               bounds: IMAGE_BOUNDS,
                                                               reference: WEB_MERCATOR,
                                                               transparent: true
                                                           } )
        }
    }

    function rotateIfNeeded( cameraSide: CameraSide, nonGeoReferencedMap: WebGLMap ) {
        const rotation = getMapRotation( cameraSide );

        const camera = nonGeoReferencedMap.camera as OrthographicCamera;
        const look2D = camera.asLook2D();

        if ( rotation === look2D.rotation ) {
            return;
        }
        nonGeoReferencedMap.camera = camera.look2D( {
                                                        ...look2D,
                                                        rotation: rotation
                                                    } );
    }

    function getMapRotation( camera: CameraSide ): number {
        switch ( camera ) {
            case CameraSide.Front:
                return 180;
            case CameraSide.Left:
                return 90;
            case CameraSide.Right:
                return 270;
            case CameraSide.Back:
                return 0;
        }
    }

    function configureObliqueMap( nonGeoReferencedMap: WebGLMap ): void {
        let pointLayer = createPointLayer();
        nonGeoReferencedMap.layerTree.addChild( pointLayer, "top" )

        nonGeoReferencedMap.controller = createZoomOnlyController( pointLayer );
        nonGeoReferencedMap.mapNavigator.constraints.scale = {minScale: 1 / 250000, maxScale: 1 / 5000};
        setTargetScale( nonGeoReferencedMap, props.targetScale.value );

        nonGeoReferencedMap.on( "MapChange", () => {
            const camera = nonGeoReferencedMap.camera as OrthographicCamera;
            const look2D = camera.asLook2D();
            props.targetScale.value = look2D.scaleX;
        } );
        nonGeoReferencedMapRef.current = nonGeoReferencedMap;

        if ( props.onMapReady ) {
            props.onMapReady( nonGeoReferencedMap );
        }
    }

    function createPointLayer() {
        const pointModel = new FeatureModel( new MemoryStore( {reference: WEB_MERCATOR} ), {reference: WEB_MERCATOR} );
        const point = createPoint( WEB_MERCATOR, [] );
        const feature = new Feature( point, {}, POINT_FEATURE_ID );
        pointModel.put( feature )

        return new FeatureLayer( pointModel, {
            id: POINT_LAYER_ID,
            painter: new MapPointPainter( true ),
            visible: false
        } );
    }

    function createZoomOnlyController( pointLayer: FeatureLayer ) {
        const controller = new CompositeController();
        let zoomController = new ZoomController();
        zoomController.getZoomTarget = () => {
            const feature = pointLayer.model.get( POINT_FEATURE_ID ) as Feature;
            return feature.shape as Point;
        }

        controller.appendController( zoomController );
        controller.appendController( new NoopController() );
        return controller;
    }

    function getIconRotation(): number {
        switch ( props.orientation ) {
            case Orientation.NORTH:
                return 0;
            case Orientation.EAST:
                return 270;
            case Orientation.WEST:
                return 90;
            case Orientation.SOUTH:
                return 180;
        }
    }

    return <RiaMap mapReference={"EPSG:3857"} onMapReady={configureObliqueMap}>
        {infoMessage &&
         <Typography sx={{position: "absolute", top: "10px", left: "10px"}} width={"300px"}>{infoMessage}</Typography>}
        <img style={{position: "absolute", bottom: "10px", right: "10px", transform: `rotate(${getIconRotation()}deg)`}}
             className="compass-icon" src={compassIcon}
             alt="compass"/>
        <Typography width={"300px"} sx={{position: "absolute", bottom: "10px", left: "10px",
            textShadow: "3px  3px  3px black, -3px -3px  3px black, 3px -3px  3px black, -3px  3px  3px black"}}>
            {imageName}
        </Typography>
    </RiaMap>
}

