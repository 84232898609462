import {Controller} from "@luciad/ria/view/controller/Controller";
import {Map} from "@luciad/ria/view/Map";
import {Point} from "@luciad/ria/shape/Point";
import {GestureEvent} from "@luciad/ria/view/input/GestureEvent";
import {HandleEventResult} from "@luciad/ria/view/controller/HandleEventResult";
import {MemoryStore} from "@luciad/ria/model/store/MemoryStore";
import {getReference} from "@luciad/ria/reference/ReferenceProvider";
import {FeatureModel} from "@luciad/ria/model/feature/FeatureModel";
import {FeatureLayer} from "@luciad/ria/view/feature/FeatureLayer";
import {FeaturePainter} from "@luciad/ria/view/feature/FeaturePainter";
import {GeoCanvas} from "@luciad/ria/view/style/GeoCanvas";
import {Feature} from "@luciad/ria/model/feature/Feature";
import {Shape} from "@luciad/ria/shape/Shape";
import {createPoint} from "@luciad/ria/shape/ShapeFactory";
import {createEllipsoidalGeodesy} from "@luciad/ria/geodesy/GeodesyFactory";
import {Geodesy} from "@luciad/ria/geodesy/Geodesy";
import {GestureEventType} from "@luciad/ria/view/input/GestureEventType";
import {DrapeTarget} from "@luciad/ria/view/style/DrapeTarget";
import {IconFactory} from "../../../common/IconFactory";

const WGS84_REF = getReference( "CRS:84" );

const THREE_D_MAP_POINT_LAYER_ID = "THREE_D_MAP_POINT_LAYER_ID";

const THREE_D_MAP_POINT_FEATURE_ID = "THREE_D_MAP_POINT_FEATURE_ID";

/**
 * When the mouse moves over an underground cross-section {@link UndergroundCrossSectionOverlay} paint the point
 * on the main map {@link ThreeDMap}.
 * */
export class UndergroundPointController extends Controller {
    private readonly _startLocation: Point;
    private readonly _endLocation: Point;
    private readonly _threeDMap: Map;

    private readonly _geodesy: Geodesy;
    private readonly _forwardAzimuth: number;
    private readonly maxDistance: number;

    private _cartesianPoint: Point | undefined;

    constructor( startLocation: Point, endLocation: Point, threeDMap: Map ) {
        super();
        this._startLocation = startLocation;
        this._endLocation = endLocation;
        this._threeDMap = threeDMap;

        this._geodesy = createEllipsoidalGeodesy( WGS84_REF );
        this._forwardAzimuth = this._geodesy.forwardAzimuth( this._startLocation, this._endLocation )
        this.maxDistance = this._geodesy.distance( startLocation, endLocation );
    }

    onActivate( map: Map ) {
        super.onActivate( map );
        const pointLayer = this._threeDMap.layerTree.findLayerById( THREE_D_MAP_POINT_LAYER_ID );
        if ( !pointLayer ) {
            this._threeDMap.layerTree.addChild( this.createMainMapPointLayer() )
        }
        this._cartesianPoint = createPoint( map.reference, [0, 0] );
    }

    private createMainMapPointLayer() {
        const store = new MemoryStore();
        const model = new FeatureModel( store, {reference: WGS84_REF} );
        const painter = new FeaturePainter();
        painter.paintBody = ( geoCanvas: GeoCanvas, feature: Feature, shape: Shape ) => {
            geoCanvas.drawIcon( shape.focusPoint!, {
                image: IconFactory.circle( {width: 16, height: 16, fill: "#FF0000"} ),
                drapeTarget: DrapeTarget.TERRAIN
            } );
        }
        return new FeatureLayer( model, {painter, id: THREE_D_MAP_POINT_LAYER_ID} );
    }

    onDeactivate( map: Map ): any {
        const pointLayer = this._threeDMap.layerTree.findLayerById( THREE_D_MAP_POINT_LAYER_ID );
        if ( pointLayer ) {
            this._threeDMap.layerTree.removeChild( pointLayer )
        }
        return super.onDeactivate( map );
    }

    onGestureEvent( gestureEvent: GestureEvent ): HandleEventResult {
        if ( gestureEvent.type === GestureEventType.MOVE ) {
            this.map!.viewToMapTransformation.transform( gestureEvent.viewPoint, this._cartesianPoint )
            if ( this._cartesianPoint!.x < 0 || this.maxDistance < this._cartesianPoint!.x ) {
                return HandleEventResult.EVENT_IGNORED;
            }

            const point = this._geodesy.interpolate( this._startLocation, this._cartesianPoint!.x, this._forwardAzimuth );
            const pointLayer = this._threeDMap.layerTree.findLayerById( THREE_D_MAP_POINT_LAYER_ID );
            if ( pointLayer ) {
                const model = pointLayer.model as FeatureModel;
                const feature = model.get( THREE_D_MAP_POINT_FEATURE_ID );
                if ( feature ) {
                    const shape = (feature as Feature).shape as Point;
                    shape.move2DToPoint( point );
                    model.put( feature as Feature );
                }
                else {
                    model.add( new Feature( point, {}, THREE_D_MAP_POINT_FEATURE_ID ) );
                }
            }
            // Pass to navigation
            return HandleEventResult.EVENT_IGNORED;
        }
        return super.onGestureEvent( gestureEvent );
    }
}