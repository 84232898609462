import {Feature} from "@luciad/ria/model/feature/Feature";
import {HandleEventResult} from "@luciad/ria/view/controller/HandleEventResult";
import {Transformation} from "@luciad/ria/transformation/Transformation";
import {LocationMode} from "@luciad/ria/transformation/LocationMode";
import {createTransformation} from "@luciad/ria/transformation/TransformationFactory";
import {GestureEvent} from "@luciad/ria/view/input/GestureEvent";
import {GestureEventType} from "@luciad/ria/view/input/GestureEventType";
import {Point} from "@luciad/ria/shape/Point";
import {Map} from "@luciad/ria/view/Map";
import {ScrollEvent} from "@luciad/ria/view/input/ScrollEvent";
import {FeatureLayer} from "@luciad/ria/view/feature/FeatureLayer";
import {FeatureModel} from "@luciad/ria/model/feature/FeatureModel";
import {ModifierType} from "@luciad/ria/view/input/ModifierType";
import {GeoCanvas} from "@luciad/ria/view/style/GeoCanvas";
import {Controller} from "@luciad/ria/view/controller/Controller";
import {THREE_D_OBJECTS_LAYER_ID} from "../../layers/ThreeDObjects";
import {createPoint} from "@luciad/ria/shape/ShapeFactory";
import {getReference} from "@luciad/ria/reference/ReferenceProvider";
import {get3DIconStyle} from "../model/ThreeDObjectTypes";
import {OutOfBoundsError} from "@luciad/ria/error/OutOfBoundsError";

const WGS84_REF = getReference( "CRS:84" );
export const ADD_ACTION = "add";
export const UPDATE_ACTION = "update";

export default class ThreeDObjectController extends Controller {

    public static createController = ( name: string ): ThreeDObjectController => {
        return new ThreeDObjectController( name );
    }

    public static editController = ( feature: Feature ): ThreeDObjectController => {
        return new ThreeDObjectController( undefined, feature );
    }

    private readonly _feature: Feature;
    private readonly _action: string;
    private _active: boolean = true;
    private _commit: boolean = false;
    private _layer?: FeatureLayer;
    private _viewToWorld?: Transformation;
    private _worldToModel?: Transformation;

    private constructor( name?: string, feature?: Feature ) {
        super();
        this._feature = name ? new Feature( createPoint( WGS84_REF, [0, 0] ), {name: name, heading: 0} ) : feature!;
        this._action = name ? ADD_ACTION : UPDATE_ACTION;
    }

    get action(): string {
        return this._action;
    }

    onActivate( map: Map ) {
        super.onActivate( map );
        this._layer = map.layerTree.findLayerById( THREE_D_OBJECTS_LAYER_ID ) as FeatureLayer;
        this._viewToWorld = map.getViewToMapTransformation( LocationMode.TERRAIN );
        this._worldToModel = createTransformation( map.reference, this._feature.shape?.reference! );
        this.cursor = this._action === ADD_ACTION ? "crosshair" : "move";
    }

    onGestureEvent( gestureEvent: GestureEvent ): HandleEventResult {
        if ( !this._active ) {
            if ( gestureEvent.type === GestureEventType.SINGLE_CLICK_CONFIRMED ) {
                // Prevent selecting the element and deactivate this controller
                return HandleEventResult.REQUEST_FINISH | HandleEventResult.EVENT_HANDLED;
            }
            return HandleEventResult.EVENT_IGNORED;
        }

        if ( gestureEvent.type === GestureEventType.SINGLE_CLICK_UP ) {
            this._active = false;
            this._commit = true;
            return HandleEventResult.EVENT_HANDLED; // don't pass on to navigation
        }

        // CTRL-scrollwheel to rotate object
        if ( gestureEvent.type === GestureEventType.SCROLL && gestureEvent.modifier === ModifierType.CTRL ) {
            this._feature.properties.heading += (gestureEvent as ScrollEvent).amount * 10;
            this._repaintFeature();
            return HandleEventResult.EVENT_HANDLED; // don't pass on to navigation
        }

        // Move on any move event (incl. those handled later by navigation)
        if ( gestureEvent.type === GestureEventType.MOVE ) {
            const modelPoint = this.viewToModelPoint( gestureEvent );
            if ( modelPoint ) {
                (this._feature.shape as Point).move2DToPoint( modelPoint );
                this._repaintFeature();
            }
            return HandleEventResult.EVENT_IGNORED; // do pass to navigation
        }

        return HandleEventResult.EVENT_IGNORED;
    }

    private viewToModelPoint( gestureEvent: GestureEvent ): Point | undefined {
        try {
            const worldPoint = this._viewToWorld!.transform( gestureEvent.viewPoint );
            return this._worldToModel!.transform( worldPoint );
        } catch ( e ) {
            if ( e instanceof OutOfBoundsError ) {
                return undefined;
            }
            throw e;
        }
    }

    _repaintFeature() {
        if ( this._action === ADD_ACTION ) {
            this.invalidate();
        }
        else if ( this._action === UPDATE_ACTION ) {
            this._layer!.model.put( this._feature );
        }
    }

    onDraw( geoCanvas: GeoCanvas ) {
        if ( this._action !== ADD_ACTION ) {
            return;
        }

        geoCanvas.drawIcon3D( this._feature.shape as Point, get3DIconStyle( this._feature ) );
    }

    onDeactivate( map: Map ): any {
        map.domNode.style.cursor = "auto";
        if ( this._commit ) {
            switch ( this._action ) {
                case ADD_ACTION:
                    (this._layer!.model as FeatureModel).add( this._feature );
                    break;
                case UPDATE_ACTION:
                    (this._layer!.model as FeatureModel).put( this._feature );
            }
        }

        this.map?.clearSelection();
        return super.onDeactivate( map );
    }
}
