import React, {FC, useState} from 'react';
import './ActionIcon.css';

interface ActionIconProps {
  Icon: any;
  size?: number;
  action: () => any;
  disabled?: boolean;
  hoverColor?: string;
  backgroundColor?: string;
  fillColor?: string;
}

export const ActionIcon: FC<ActionIconProps> = ({
  Icon,
  action,
  size = 30,
  disabled = false,
  hoverColor = '#383838',
  backgroundColor = 'rgba(0,0,0,0)',
}) => {
  const [hovering, setHovering] = useState(false);
  const style: React.CSSProperties = {};

  if (!disabled) {
    style.backgroundColor = hovering ? hoverColor : backgroundColor;
  }

  return (
    <button
      style={style}
      onClick={action}
      disabled={disabled}
      className={'actionIcon'}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <img
          src={Icon}
          width={size}
          height={size}
          alt={'action'}
          style={{ padding: '0px' }}
      />
    </button>
  );
};
