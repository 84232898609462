import React, {useEffect, useRef, useState} from 'react';
import {useScaleIndicator} from '../common/hooks/useScaleIndicator';
import {Absolute, Box, Relative, Text} from '@digitalreality/ui';

interface Props {
  mapScale: number;
}

/**
 * Component that displays the given map scale as a labeled, black and white bar of correct dimensions.
 */
export const ScaleIndicator = ({mapScale}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [maxWidth, setMaxWidth] = useState(100);
  const {sizes, text} = useScaleIndicator(mapScale, maxWidth);

  useEffect(() => {
    if (ref.current && ref.current.getBoundingClientRect().width !== maxWidth) {
      setMaxWidth(ref.current.getBoundingClientRect().width);
    }
  });

  return (
    <Box ref={ref} width="100%" height="100%">
      <Text align="center">{text}</Text>
      <Relative
        border="1px solid black"
        height="6px"
        width={`${sizes.width}px`}
        left={`${sizes.left}px`}
        bgcolor="white"
      >
        <Absolute left={0} width="25%" height="100%" bgcolor="black" />
        <Absolute left="50%" width="25%" height="100%" bgcolor="black" />
      </Relative>
    </Box>
  );
};
