import {
    Drawer,
    DrawerActions,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
    Stack,
    Text,
    useTheme
} from "@digitalreality/ui";
import React, {useEffect} from "react";
import {Map} from "@luciad/ria/view/Map";
import {ThreeDObjectCard} from "./ThreeDObjectCard";
import {ThreeDObjectTypes} from "../model/ThreeDObjectTypes";
import ThreeDObjectController, {ADD_ACTION} from "../controller/ThreeDObjectController";
import {useControllerContext} from "../../navigation/ControllerContext";
import {AddLocationAltOutlined} from "@mui/icons-material";

interface ThreeDObjectsDrawerProps {
    open: boolean;
    onClose: () => void;
    map: Map;
}

export function ThreeDObjectsDrawer( props: ThreeDObjectsDrawerProps ) {

    const [controllerContext, setControllerContext] = useControllerContext();
    const theme = useTheme();

    useEffect( () => {
        if ( !props.open && controllerContext.interactionController instanceof ThreeDObjectController &&
             controllerContext.interactionController.action === ADD_ACTION) {
            setControllerContext( ( prev ) => ({
                ...prev,
                interactionController: undefined,
                selection: undefined
            }) )
        }
    }, [props.open] );

    return <Drawer variant="persistent" open={props.open} onClose={props.onClose}>
        <DrawerContent pl={theme.sizes.navbar} width={380}>
            <DrawerHeader>
                <DrawerTitle icon={<AddLocationAltOutlined/>}>Añadir objetos 3D</DrawerTitle>
                <DrawerActions>
                    <DrawerCloseButton tooltipLabel={"Cerrar panel"} onClick={props.onClose}/>
                </DrawerActions>
            </DrawerHeader>
            <DrawerBody marginLeft="8px" marginTop="8px" marginRight="8px">
                <Stack gap="8px" width="calc(100% - 20px)">
                    {ThreeDObjectTypes.map( ( threeDObjectProps ) => (
                        <ThreeDObjectCard key={threeDObjectProps.name} name={threeDObjectProps.name}/>
                    ) )}
                </Stack>
            </DrawerBody>
            <DrawerFooter>
                <Text variant={"body2"}>
                    Mantén pulsado CTRL y utiliza la rueda del ratón para girar el objeto.
                </Text>
            </DrawerFooter>
        </DrawerContent>
    </Drawer>
}
