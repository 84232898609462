import {Controller} from "@luciad/ria/view/controller/Controller";
import {Map} from "@luciad/ria/view/Map";
import {getReference} from "@luciad/ria/reference/ReferenceProvider";
import {FeatureLayer} from "@luciad/ria/view/feature/FeatureLayer";
import {LocationMode} from "@luciad/ria/transformation/LocationMode";
import {createTransformation} from "@luciad/ria/transformation/TransformationFactory";
import {Transformation} from "@luciad/ria/transformation/Transformation";
import {GestureEvent} from "@luciad/ria/view/input/GestureEvent";
import {HandleEventResult} from "@luciad/ria/view/controller/HandleEventResult";
import {GestureEventType} from "@luciad/ria/view/input/GestureEventType";
import {Point} from "@luciad/ria/shape/Point";
import {OutOfBoundsError} from "@luciad/ria/error/OutOfBoundsError";
import {GeoCanvas} from "@luciad/ria/view/style/GeoCanvas";
import {createPolyline} from "@luciad/ria/shape/ShapeFactory";
import {
    UNDERGROUND_MAIN_MAP_LINE_FEATURE_ID,
    UNDERGROUND_MAIN_MAP_LINE_LAYER_ID
} from "../ui/UndergroundCrossSectionOverlay";
import {FeatureModel} from "@luciad/ria/model/feature/FeatureModel";
import {Feature} from "@luciad/ria/model/feature/Feature";
import {DrapeTarget} from "@luciad/ria/view/style/DrapeTarget";

const WGS84_REF = getReference( "CRS:84" );

export class UndergroundCrossSectionController extends Controller {

    private _active: boolean = true;
    private _commit: boolean = false;

    private _firstPoint?: Point;
    private _secondPoint?: Point

    private _layer?: FeatureLayer;
    private _viewToWorld?: Transformation;
    private _worldToModel?: Transformation;

    constructor() {
        super();
    }

    onActivate( map: Map ) {
        super.onActivate( map );
        this._layer = map.layerTree.findLayerById( UNDERGROUND_MAIN_MAP_LINE_LAYER_ID ) as FeatureLayer;
        this._viewToWorld = map.getViewToMapTransformation( LocationMode.TERRAIN );
        this._worldToModel = createTransformation( map.reference, WGS84_REF );
        this.cursor = "crosshair";
    }

    onGestureEvent( gestureEvent: GestureEvent ): HandleEventResult {
        if ( !this._active ) {
            if ( gestureEvent.type === GestureEventType.SINGLE_CLICK_CONFIRMED ) {
                // Prevent selecting the element and deactivate this controller
                return HandleEventResult.REQUEST_FINISH | HandleEventResult.EVENT_HANDLED;
            }
            return HandleEventResult.EVENT_IGNORED;
        }

        if ( gestureEvent.type === GestureEventType.SINGLE_CLICK_UP ) {
            if ( this._secondPoint ) {
                this._active = false;
                this._commit = true;
                return HandleEventResult.REQUEST_DEACTIVATION;
            }

            this._firstPoint = this.viewToModelPoint( gestureEvent );
            return HandleEventResult.EVENT_HANDLED; // don't pass on to navigation
        }

        if ( gestureEvent.type === GestureEventType.MOVE ) {
            if ( this._firstPoint ) {
                const modelPoint = this.viewToModelPoint( gestureEvent );
                if ( !modelPoint ) {
                    return HandleEventResult.EVENT_IGNORED;
                }

                this._secondPoint = modelPoint;
                this.invalidate();
            }
            return HandleEventResult.EVENT_IGNORED; // do pass to navigation
        }

        return HandleEventResult.EVENT_IGNORED;
    }

    private viewToModelPoint( gestureEvent: GestureEvent ): Point | undefined {
        try {
            const worldPoint = this._viewToWorld!.transform( gestureEvent.viewPoint );
            return this._worldToModel!.transform( worldPoint );
        } catch ( e ) {
            if ( e instanceof OutOfBoundsError ) {
                return undefined;
            }
            throw e;
        }
    }

    onDraw( geoCanvas: GeoCanvas ) {
        if ( this._firstPoint && this._secondPoint ) {
            const polyline = createPolyline( WGS84_REF, [this._firstPoint, this._secondPoint] );
            geoCanvas.drawShape( polyline, {stroke: {color: "#00A2E8", width: 2}, drapeTarget: DrapeTarget.TERRAIN} );
        }
    }

    onDeactivate( map: Map ): any {
        map.domNode.style.cursor = "auto";
        if ( this._commit && this._firstPoint && this._secondPoint ) {
            const polyline = createPolyline( WGS84_REF, [this._firstPoint, this._secondPoint] );
            const feature = new Feature( polyline, {}, UNDERGROUND_MAIN_MAP_LINE_FEATURE_ID );
            (this._layer!.model as FeatureModel).put( feature );
        }

        this.map?.clearSelection();
        return super.onDeactivate( map );
    }

}