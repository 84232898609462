import {LayerConstructorOptions, LayerTreeNode} from "@luciad/ria/view/LayerTreeNode";
import {Layer} from "@luciad/ria/view/Layer";
import {WMSTileSetModel} from "@luciad/ria/model/tileset/WMSTileSetModel";
import {RasterLayerGroup} from "./RasterLayerGroup";

export enum FourBandsStyle {
    NATURAL_COLOR = "natural_color",
    INFRARED = "infrared"
}

export class FourBandsWMSLayerGroup extends RasterLayerGroup {

    private _bandStyle: FourBandsStyle;

    constructor( options: LayerConstructorOptions ) {
        super( options );
        this._bandStyle = FourBandsStyle.NATURAL_COLOR;
    }

    get bandStyle(): FourBandsStyle {
        return this._bandStyle;
    }

    set bandStyle( value: FourBandsStyle ) {
        this._bandStyle = value;
        this.children.map( node => node as Layer )
            .map( layer => layer.model as WMSTileSetModel )
            .forEach( model => this.setStyle( model ) )
    }

    canAddChild( layerTreeNode: LayerTreeNode, position?: "top" | "bottom" | "above" | "below", positionReference?: LayerTreeNode ): boolean {
        if ( layerTreeNode instanceof Layer && layerTreeNode.model instanceof WMSTileSetModel ) {
            this.setStyle( layerTreeNode.model );
            return false;
        }

        return super.canAddChild( layerTreeNode, position, positionReference );
    }

    //TODO use named styles CDT-92
    private setStyle( model: WMSTileSetModel ) {
        model.layers = [this._bandStyle];
    }
}
