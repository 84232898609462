import {Map} from '@luciad/ria/view/Map';
import {LayerTreeNode} from '@luciad/ria/view/LayerTreeNode';
import {useLayerVisible} from '../common/hooks/useLayerVisible';
import {useLayerLabel} from '../common/hooks/useLayerLabel';
import {getFitBounds} from '../common/util/FitBoundsUtil';
import React, {useEffect, useState} from 'react';
import {Box, IconButton, Inline, Popover, Stack, Text} from '@digitalreality/ui';
import {LocateIcon, ViewFilledIcon, ViewOffFilledIcon,} from '@digitalreality/ui-icons';
import {AddCircleOutline, Loop, MoreVert} from "@mui/icons-material";

import {hasFit, hasVisibilityToggle, isHidden, isLazy, isLoaded} from "./LayerOptions";
import {LayerSettings, RasterStyleable} from "./LayerSettings";
import {RasterLayerGroup} from "./RasterLayerGroup";
import {WMSTileSetLayer} from "@luciad/ria/view/tileset/WMSTileSetLayer";
import {createLoadLayerRequestEvent, LAYER_LOADED_EVENT_TYPE, LoadedLayerEventDetail} from "./LoadLayerEventFactory";
import {Tooltip} from "@mui/material";
import "./LayerTreeNodeControl.css";
import {AlertDialog} from "../util/AlertDialog";

interface Props {
    map: Map;
    node: LayerTreeNode;
}

/**
 * Component that shows the given node (and its children) and allows users
 * to toggle the node's visibility and to move it and its children.
 */
export const LayerTreeNodeControl = ( {map, node}: Props ) => {
    const [loaded, setLoaded] = useState<boolean>( !isLazy( node ) || isLoaded( node ) );
    const [errorWhileLoading, setErrorWhileLoading] = useState<boolean>( false );

    const label = useLayerLabel( node );

    useEffect( () => {
        function listenToLoadedLayers( event: Event ) {
            const detail = (event as CustomEvent).detail as LoadedLayerEventDetail;
            if ( detail.layerId === node.id ) {
                setLoaded( true );
                setErrorWhileLoading( !detail.loadedSuccessful );
                node.visible = true;

                removeEventListener( LAYER_LOADED_EVENT_TYPE, listenToLoadedLayers );
            }
        }

        addEventListener( LAYER_LOADED_EVENT_TYPE, listenToLoadedLayers );
    }, [] )

    function errorDialog() {
        return <AlertDialog title={"Ha ocurrido un error"}
                            message={"No se ha podido cargar todas los datos del grupo " + node.label + "." +
                                     "Para mas información consulte el log."}
                            onClose={() => setErrorWhileLoading( false )}/>;
    }

    return (
        <>
            {errorWhileLoading && errorDialog()}
            <Box style={{width: '100%'}} id={node.id}>
                <Stack width="calc(100% - 20px)" position="relative">
                    <Inline position="relative" width="100%" height="20px" alignItems="center">
                        <Text noWrap width="calc(100% - 75px)" paddingTop="4px" paddingBottom="4px" size={0}>
                            {label}
                        </Text>

                        <Inline position="absolute" right="0" top="50%" sx={{transform: 'translate(0, -50%)'}}>
                            {
                                loaded ?
                                <LoadedLayerActions map={map} node={node}/> :
                                <UnloadedLayerActions node={node}/>
                            }
                        </Inline>
                    </Inline>
                    <Box marginLeft="20px" width="100%">
                        {node.children.reverse()
                            .filter( child => !(isHidden( child )) )
                            .map( ( child ) => (
                                <LayerTreeNodeControl map={map} key={child.id} node={child}/>
                            ) )}
                    </Box>
                </Stack>
            </Box>
        </>
    );
};

const LoadedLayerActions = ( {map, node}: Props ) => {

    const visible = useLayerVisible( node );
    const showFitButton = hasFit( node );
    const showVisibilityButton = hasVisibilityToggle( node );

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>( null );
    const more = ( event: React.MouseEvent<HTMLButtonElement> ) => {
        setAnchorEl( event.currentTarget );
    };

    const closeSettings = () => {
        setAnchorEl( null );
    };

    const open = !!anchorEl;

    function rasterLayerSettings() {
        return <>
            <IconButton size="small" onClick={more}>
                <MoreVert fontSize="small"/>
            </IconButton>
            <Popover disableRestoreFocus={true} open={open} anchorEl={anchorEl} onClose={closeSettings}
                     transformOrigin={{vertical: 'top', horizontal: 'center',}}
                     anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}
                     sx={{padding: "8px"}}>
                <LayerSettings node={node as RasterStyleable}/>
            </Popover>
        </>;
    }

    async function fit() {
        const bounds = await getFitBounds( node );
        if ( bounds ) {
            await map.mapNavigator.fit( {bounds, animate: true} );
        }
    }

    return <>
        {(node instanceof RasterLayerGroup || node instanceof WMSTileSetLayer) && rasterLayerSettings()}
        {showFitButton && <IconButton size="small" onClick={fit}>
          <LocateIcon fontSize="small"/>
        </IconButton>}

        {showVisibilityButton &&
         <IconButton size="small" onClick={() => (node.visible = !visible)}>
             {visible ? (<ViewFilledIcon fontSize="small"/>) : (
                 <ViewOffFilledIcon fontSize="small"/>)}
         </IconButton>}
    </>
}

const UnloadedLayerActions = ( {node}: {
    node: LayerTreeNode
} ) => {

    const [loading, setLoading] = useState<boolean>( false );

    return <>
        {loading ?
         <IconButton className={"spin"} sx={{color: "#ffffff !important"}} size="small"
                     disabled>
             <Loop fontSize="small"/>
         </IconButton> :
         <Tooltip title={"Conectar con el servicio"}>
             <IconButton size="small" onClick={() => {
                 dispatchEvent( createLoadLayerRequestEvent( node.id ) );
                 setLoading( true );
             }}>
                 <AddCircleOutline fontSize="small"/>
             </IconButton>
         </Tooltip>
        }
    </>
}
