import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography} from "@mui/material";
import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import React, {KeyboardEvent, useState} from "react";
import {SimulationToUpload} from "./SimulationParser";
import 'dayjs/locale/es';
import dayjs from "dayjs";
import {Inline} from "@digitalreality/ui";
import {ColorMapEntry, ColorMapPicker, isValidColor, isValidValue} from "../util/color_map_picker/ColorMapPicker";

interface SimulationFormProps {
    simulation: SimulationToUpload,
    onCancel: () => void,
    onAccept: ( zipFile: File, name: string, colorMap: ColorMapEntry[], startDate?: Date ) => void,
}

export function SimulationForm( {simulation, onCancel, onAccept}: SimulationFormProps ) {
    const isHecras = simulation.startDate !== undefined;
    const [name, setName] = useState<string>( simulation.name ?? "" );
    const [date, setDate] = useState<dayjs.Dayjs | null>( dayjs( isHecras ? simulation.startDate!.toISOString() : new Date().toISOString() ) );
    const [error, setError] = useState<string | undefined>( undefined );

    const [colorMapEntries, setColorMapEntries] = useState<ColorMapEntry[]>( setDefaultColorMap( simulation.type ) );

    function setDefaultColorMap( type: "Velocidad" | "Calado" | "WSE" ): ColorMapEntry[] {
        switch ( type ) {
            case "Velocidad":
                return [
                    {
                        value: 0,
                        color: "#00008b"
                    },
                    {
                        value: 2,
                        color: "#0000ff"
                    },
                    {
                        value: 4,
                        color: "#90ee90"
                    },
                    {
                        value: 6,
                        color: "#ffff00"
                    },
                    {
                        value: 8,
                        color: "#ffa500"
                    },
                    {
                        value: 10,
                        color: "#ff4500"
                    },
                    {
                        value: 15,
                        color: "#8b0000"
                    },
                ]
            case "Calado":
                return [
                    {
                        value: 0,
                        color: "#00ffff"
                    },
                    {
                        value: 15,
                        color: "#008bff"
                    }
                ];
            case "WSE":
                return [
                    {
                        value: 0.25,
                        color: "#7fff00"
                    },
                    {
                        value: 3,
                        color: "#008000"
                    },
                    {
                        value: 5.74,
                        color: "#ffff00"
                    },
                    {
                        value: 8.49,
                        color: "#ffa500"
                    },
                    {
                        value: 11.23,
                        color: "#ff0000"
                    },
                    {
                        value: 13.98,
                        color: "#ffc0cb"
                    },
                    {
                        value: 16.75,
                        color: "#ff00ff"
                    }];
        }
    }

    function consumeEvent( event: KeyboardEvent<HTMLInputElement> ) {
        event.stopPropagation();
    }

    function acceptIfValid() {
        if ( !date || !date.isValid() ) {
            setError( "La fecha es inválida" );
            return;
        }

        if ( name.length === 0 ) {
            setError( "El nombre no puede estar vacío" )
            return;
        }

        if ( colorMapEntries.length < 2 ) {
            setError( "La rampa de colores debe tener al menos dos entradas" )
            return;
        }

        for ( let i = 0; i < colorMapEntries.length; i++ ) {
            if ( !isValidValue( i, colorMapEntries ) ) {
                setError( "Los valores de la rampa de colores son incorrectos" )
                return;
            }
            if ( !isValidColor( i, colorMapEntries ) ) {
                setError( "No pueden repetirse dos colores consecutivamente" )
                return;
            }
        }

        if ( !isHecras ) {
            const startDate = date.toDate();
            const startDateUTC = Date.UTC( startDate.getUTCFullYear(), startDate.getUTCMonth(),
                                           startDate.getUTCDate(), startDate.getUTCHours(),
                                           startDate.getUTCMinutes(), startDate.getUTCSeconds() );
            onAccept( simulation.file, name, colorMapEntries, new Date( startDateUTC ) );
        }
        else {
            onAccept( simulation.file, name, colorMapEntries );
        }
    }

    return (
        <div onKeyUp={consumeEvent} onKeyDown={consumeEvent}>
            <Dialog open={true} onClose={onCancel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Subir simulación"}</DialogTitle>
                <DialogContent sx={{overflow: "hidden"}}>
                    <Inline spacing={"8px"}>
                        <Stack gap="12px" sx={{width: "450px", height: "425px", paddingTop: "8px"}}>
                            <TextField label="Nombre" value={name} onChange={event => setName( event.target.value )}
                                       required/>
                            <TextField label="Tipo" value={simulation.type} disabled/>
                            <TextField label="Fuente" value={isHecras ? "HECRAS" : "IBER"} disabled/>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es"}>
                                <DateTimePicker label="Fecha inicio*" value={date} disabled={isHecras}
                                                onChange={newDate => setDate( newDate )}/>
                            </LocalizationProvider>
                            <TextField label="Intervalo" value={simulation.intervalMinutes + " min"} disabled/>
                            <TextField label="Numero de archivos" value={simulation.numberOfFiles} disabled/>
                        </Stack>
                        <ColorMapPicker title={"Rampa de colores"}
                                        colorMapEntries={colorMapEntries} setColorMapEntries={setColorMapEntries}/>
                    </Inline>
                    {error && <Typography color={"red"}>{error}</Typography>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancelar</Button>
                    <Button onClick={acceptIfValid}>Aceptar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
