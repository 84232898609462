import {WebGLMap} from "@luciad/ria/view/WebGLMap";
import React, {useState} from "react";
import {RiaMap} from "./RiaMap";
import {Absolute, IconButton, Stack, Tooltip} from "@digitalreality/ui";
import {ThreeDButton} from "../navigation/ThreeDButton";
import {CompassButton} from "../common/components/overlay/CompassButton";
import {ScaleIndicator} from "../common/components/overlay/ScaleIndicator";
import {getUnitOfMeasure} from "@luciad/ria/uom/UnitOfMeasureRegistry";
import {MouseCoordinateReadout} from "../common/components/overlay/MouseCoordinateReadout";
import {CartagenaLayers} from "../layers/CartagenaLayers";
import {GeneralLayers} from "../layers/GeneralLayers";
import {ThreeDObjects} from "../layers/ThreeDObjects";
import {OverviewMap} from "../components/overviewmap/OverviewMap";
import {PerspectiveCamera} from "@luciad/ria/view/camera/PerspectiveCamera";
import {createBounds} from "@luciad/ria/shape/ShapeFactory";
import {getReference} from "@luciad/ria/reference/ReferenceProvider";
import {CenterFocusStrong} from "@mui/icons-material";
import {CoordinateFormat, useSettings} from "../settings/Settings";
import {MGRSFormat} from "@luciad/ria-milsym/text/mgrs/MGRSFormat";
import {MGRSFormatType} from "@luciad/ria-milsym/text/mgrs/MGRSFormatType";
import {MGRSFormatPrecision} from "@luciad/ria-milsym/text/mgrs/MGRSFormatPrecision";

export const CARTAGENA_BOUNDS = createBounds( getReference( 'EPSG:4326' ), [-1.15, 0.3, 37.5, 0.25] );

interface Props {
    onMapReady: ( map: WebGLMap ) => void
}

export const ThreeDMap = ( {onMapReady}: Props ) => {
    const [map, setMap] = useState<WebGLMap | null>( null )
    const [settings, _] = useSettings();

    function getFormatter( format: CoordinateFormat ) {
        switch ( format ) {
            case CoordinateFormat.LON_LAT:
                return undefined;
            case CoordinateFormat.UTM:
                return new MGRSFormat( {
                                           formatType: MGRSFormatType.UTM_UPS,
                                           precision: MGRSFormatPrecision.PRECISION_1M,
                                           zoneSeparator: " ",
                                           coordinateSeparator: " "
                                       } );
        }
    }


    function recenter( map: WebGLMap, animate: boolean ) {
        map.mapNavigator.fit( {bounds: CARTAGENA_BOUNDS, animate: animate} );
    }

    function configureThreeDMap( map: WebGLMap ) {
        map.mapNavigator.constraints = {
            above: {
                terrain: true,
                mesh: false,
                minAltitude: 50
            }
        }
        recenter( map, false );

        map.effects.antiAliasing = true;
        map.camera = (map.camera as PerspectiveCamera).lookFrom(
            {
                ...((map.camera as PerspectiveCamera).asLookFrom()),
                pitch: -45
            }
        );

        onMapReady( map );
        setMap( map );
    }

    return <RiaMap onMapReady={configureThreeDMap}>
        <Absolute right={"s"} top={"s"} sx={{pointerEvents: 'none'}}>
            <Stack gap={2} direction={"row"}>
                <img src={"../resources_ria/customer_logo.png"} alt={"Government logo"}
                     style={{height: "56px"}}/>
            </Stack>
        </Absolute>

        <Absolute bottom={"50%"} right={"s"}>
            <Stack alignItems={"center"} id={"map-overlay"}>
                <ThreeDButton/>
                <CompassButton/>

                <Tooltip title={"Volver a centrar el mapa"} placement={"left"}>
                    <IconButton className={"compass-button"} onClick={() => recenter( map!, true )}>
                        <CenterFocusStrong fontSize={"large"}/>
                    </IconButton>
                </Tooltip>
            </Stack>
        </Absolute>

        <ScaleIndicator uom={getUnitOfMeasure( "Meter" )}/>
        <MouseCoordinateReadout formatter={getFormatter( settings.coordinateFormat )}/>

        {map && <CartagenaLayers/>}

        <GeneralLayers/>
        <ThreeDObjects/>
        <OverviewMap initiallyCollapsed={true}/>
    </RiaMap>
}
