import {LayerGroup} from "@luciad/ria/view/LayerGroup";
import {Bounded} from "@luciad/ria/shape/Bounded";
import {Layer} from "@luciad/ria/view/Layer";
import {createTransformation} from "@luciad/ria/transformation/TransformationFactory";
import {Bounds} from "@luciad/ria/shape/Bounds";
import {CoordinateReference} from "@luciad/ria/reference/CoordinateReference";

export function fitToLayerGroup( layerGroup: LayerGroup ) {
    const map = layerGroup.map;
    if ( map === null ) {
        return;
    }

    const childrenLayers = getChildrenLayers( layerGroup );
    const bounds = childrenLayers.map( layer => layer.model )
        .filter( model => model !== null )
        .filter( model => isBounded( model ) )
        .map( model => (model as unknown as Bounded).bounds )
        .map( bounds => bounds?.copy() )
        .reduce( ( boundsA, boundsB ) => {
            if ( boundsA && boundsB ) {
                const boundsBWithTargetReference = copyWithReference( boundsB, boundsA.reference! );
                if ( boundsBWithTargetReference ) {
                    boundsA.setTo3DUnion( boundsBWithTargetReference );
                }
                return boundsA;
            }
            else {
                return undefined;
            }
        } );

    if ( bounds ) {
        map.mapNavigator.fit( {bounds, animate: true} )
    }
}

function getChildrenLayers( group: LayerGroup ): Layer[] {
    const children = [] as Layer [];
    for ( let child of group.children ) {
        if ( child instanceof Layer ) {
            children.push( child );
        }
        else if ( child instanceof LayerGroup ) {
            children.push( ...getChildrenLayers( child ) )
        }
        else {
            throw new Error( "Unexpected children: " + child )
        }
    }
    return children;
}

function isBounded( candidate: any ): candidate is Bounded {
    return candidate && candidate.bounds && typeof (candidate.bounds) === "object";
}

function copyWithReference( boundsToCopy: Bounds, targetReference: CoordinateReference ) {
    try {
        if ( targetReference !== boundsToCopy.reference ) {
            const transformation = createTransformation( boundsToCopy.reference!, targetReference );
            boundsToCopy = transformation.transformBounds( boundsToCopy );
        }
        return boundsToCopy;
    } catch ( e ) {
        console.warn( "Could not copy the bounds(" + boundsToCopy + ") with the target reference " + targetReference );
        return undefined;
    }
}
